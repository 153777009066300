import React, {Component} from 'react';
import Record from './Record';
import Statuses from './Statuses';
import Members from './Members';
import Block from './Block';
import Permissions from './Permissions';
import Filter from './Filter';
import AdminRooms from './AdminRooms';
import Shortcuts from './Shortcuts';
import Subscriptions from './Subscriptions';
import AdminMessages from './AdminMessages';
import SiteAdministration from "./SiteAdministration";
import Labels from '../../labels';
import Constants from '../../constants';
import {userPermissions} from "../../functions";
import '../../assets/sass/AdminPanel.scss';

class AdminPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            task: null
        }
    }

    handleClick = (task) => {
        this.setState({
            task
        });
    };

    render() {
        const {task} = this.state;
        const {user, socket} = this.props;

        return (
            <div className="admin-panel">
                <div className="content">
                    {
                        task === Constants.record && (
                            <Record
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.statuses && (
                            <Statuses
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.members && (
                            <Members
                                user={user}
                                socket={socket}
                            />
                        )
                    }
                    {
                        task === Constants.block && (
                            <Block
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.permissions_1 && (
                            <Permissions
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.filter && (
                            <Filter
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.admin_rooms && (
                            <AdminRooms
                                user={user}
                                socket={socket}
                            />
                        )
                    }
                    {
                        task === Constants.shortcuts && (
                            <Shortcuts
                                user={user}
                                socket={socket}
                            />
                        )
                    }
                    {
                        task === Constants.subscriptions && (
                            <Subscriptions
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.admin_messages && (
                            <AdminMessages
                                user={user}
                            />
                        )
                    }
                    {
                        task === Constants.site_admin && (
                            <SiteAdministration
                                user={user}
                                socket={socket}
                            />
                        )
                    }
                </div>
                <div className="admin-sidebar">
                    <ul>
                        <li onClick={() => {this.handleClick(Constants.record)}}
                            className={task === Constants.record ? 'active' : ''}
                        >
                            {Labels.record}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.statuses)}}
                            className={task === 'statuses' ? 'active' : ''}
                        >
                            {Labels.statuses}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.members)}}
                            className={task === 'members' ? 'active' : ''}
                        >
                            {Labels.members}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.block)}}
                            className={task === 'block' ? 'active' : ''}
                        >
                            {Labels.block}
                        </li>
                        {
                            userPermissions(Constants.permissions.manage_permission, user.id) && (
                                <li onClick={() => {this.handleClick(Constants.permissions_1)}}
                                    className={task === 'permissions' ? 'active' : ''}
                                >
                                    {Labels.permissions}
                                </li>
                            )
                        }
                        <li onClick={() => {this.handleClick(Constants.filter)}}
                            className={task === 'filter' ? 'active' : ''}
                        >
                            {Labels.filter}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.admin_rooms)}}
                            className={task === 'adminRooms' ? 'active' : ''}
                        >
                            {Labels.rooms_1}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.shortcuts)}}
                            className={task === 'shortcuts' ? 'active' : ''}
                        >
                            {Labels.shortcuts}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.subscriptions)}}
                            className={task === 'subscriptions' ? 'active' : ''}
                        >
                            {Labels.subscriptions}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.admin_messages)}}
                            className={task === 'adminMessages' ? 'active' : ''}
                        >
                            {Labels.messages}
                        </li>
                        <li onClick={() => {this.handleClick(Constants.site_admin)}}
                            className={task === 'siteAdmin' ? 'active' : ''}
                        >
                            {Labels.site_admin}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default AdminPanel;
