module.exports = {
    message_types: {
        daily: 'DAILY_MESSAGE',
        adv: 'ADV_MESSAGE',
        logout: 'LOGOUT_MESSAGE',
        leave: 'LEAVE_MESSAGE',
        entrance: 'ENTRANCE',
        user_leave_room: 'USER_LEAVE_ROOM'
    },
    user_activity: {
        active: 'ACTIVE',
        active_disable_private_chat: 'ACTIVE_DISABLE_PRIVATE_CHAT',
        inactive: 'INACTIVE',
        inactive_and_band: 'INACTIVE_AND_BAND',
        hidden_login: 'HIDDEN_LOGIN'
    },
    chat_user: 'chatuser',
    logout: 'LOGOUT',
    enable_private_chat: 'enable_private_chat',
    chat_user_settings: 'chat_user_settings',
    const_room: 'SYSTEM',
    guest: 'GUST',
    media: {
        image: 'MEDIA_IMAGE',
        video: 'MEDIA_VIDEO',
        record: 'MEDIA_RECORD',
        youtube: 'YOUTUBE_LINK'
    },
    expulsion_from_room: 'EXPULSION_FROM_ROOM',
    permissions: {
        hide: 'hide',
    },
    tasks: {
        add_user: 'ADD_USER',
        remove_user: 'REMOVE_USER',
        update_chat_user_settings: 'UPDATE_CHAT_USER_SETTINGS',
        set_user_country_ip: 'SET_USER_COUNTRY_IP',
        gift: 'gift',
        update_user_group: 'UPDATE_USER_GROUP',
        hiddenLogin: 'hiddenLogin',
        check_user_activity: 'CHECK_USER_ACTIVITY',
        hideStar: "hideStar"
    },
};
