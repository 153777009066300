import {axiosInstance, setAuthorizationToken} from '../axios';
import {addRecord} from "./admin/record";
import Labels from "../labels";
import Constants from "../constants";

export const register = ({username, password, registerType}) => {
    let payload = {
        user_name: username,
        user_type: registerType
    };

    if (registerType === Constants.member) {
        payload.password = password;
    }

    return axiosInstance
        .post('signup', payload)
        .then((response) => {
            const {data} = response || {};
            const {token, user} = data;

            setAuthorizationToken(token, user.id);

            let action = Labels.guest;

            if (registerType === Constants.member) {
                action = Labels.registration
            }

            addRecord(user, action)
                .then((response) => {
                }, (error) => {
                });

            return Promise.resolve(user);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};

export const login = ({username, password}) => {
    const payload = {
        username,
        password
    };

    return axiosInstance
        .post('login', payload)
        .then((response) => {
            const {data} = response || {};
            const {token, user} = data;

            setAuthorizationToken(token, user.id);

            addRecord(user, Labels.member)
                .then((response) => {
                }, (error) => {
                });

            return Promise.resolve(user);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};

export const logoutAPI = () => {
    return axiosInstance
        .post('logout')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const changePassword = (userId, password) => {
    const payload = {
        new_password: password,
        second_new_password: password
    };

    return axiosInstance
        .post('admin/users/' + userId + '/change_password', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const changePasswordInPublic = (password) => {
    const payload = {
        new_password: password,
        second_new_password: password
    };

    return axiosInstance
        .post('users/change_password', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getUserInfo = (token) => {
    return axiosInstance
        .get('info?token=' + token)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};