import {axiosInstance} from '../../axios';

export const getRecords = (limit, offset) => {
    return axiosInstance
        .get('admin/users/records/audits?limit=' + limit + '&offset=' + offset)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addRecord = async (user, action) => {
    const chatUser = user.chatuser;
    const nickname = chatUser.nick_name;
    const device = window.fc;
    const country = localStorage.getItem('_country');
    const ip = localStorage.getItem('_ip');

    const payload = {
        chat_user: chatUser.id,
        status: action,
        nick_name: nickname,
        ip_address: ip,
        country: country,
        device: device
    };

    return axiosInstance
        .post('admin/users/records/audits', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const searchRecords = (username) => {
    return axiosInstance
        .get('admin/users/records/audits?nick_name=' + username)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};