const countries = {
    AD: 'أندورا',
    AE: 'الإمارات العربية المتحدة',
    AF: 'أفغانستان',
    AG: 'أنتيغوا وبربودا إيه جي',
    AI: 'أنغيلا',
    AL: 'ألبانيا ',
    AM: 'أرمينيا ',
    AO: 'أنغولا ',
    AQ: 'القارة القطبية الجنوبية',
    AR: 'الأرجنتين ',
    AS: 'ساموا الأمريكية',
    AT: 'النمسا ',
    AU: 'أستراليا ',
    AW: 'أروبا ',
    AX: '?land',
    AZ: 'أذربيجان من الالف إلى الياء',
    BA: 'البوسنة والهرسك',
    BB: 'بربادوس ',
    BD: 'بنغلاديش ',
    BE: 'بلجيكا ',
    BF: 'بوركينا فاسو',
    BG: 'بلغاريا ',
    BH: 'البحرين ',
    BI: 'بوروندي ',
    BJ: 'بنين ',
    BL: 'سانت بارتيليمي',
    BM: 'برمودا ',
    BN: 'بروناي ',
    BO: 'بوليفيا ',
    BQ: 'بونير ',
    BR: 'البرازيل ',
    BS: 'الباهاما ',
    BT: 'بوتان ',
    BV: 'جزيرة بوفيت',
    BW: 'بوتسوانا ',
    BY: 'روسيا البيضاء',
    BZ: 'بليز ',
    CA: 'كندا ',
    CC: 'جزر كوسكيلينغ',
    CD: 'جمهورية الكونغو الديمقراطية',
    CF: 'جمهورية إفريقيا الوسطى',
    CI: 'ساحل العاج',
    CK: 'جزر كوك',
    CL: 'شيلي ',
    CM: 'الكاميرون ',
    CN: 'الصين ',
    CO: 'كولومبيا ',
    CR: 'كوستاريكا ',
    CU: 'كوبا ',
    CV: 'السيرة الذاتية للرأس الأخضر',
    CW: 'كوراكاو ',
    CX: 'جزيرة عيد الميلاد',
    CY: 'قبرص ',
    CZ: 'التشيك ',
    DE: 'ألمانيا ',
    DJ: 'جيبوتي ',
    DK: 'الدنمارك ',
    DM: 'دومينيكا ',
    DO: 'جمهورية الدومينيكان',
    DZ: 'الجزائر ',
    EC: 'الإكوادور ',
    EE: 'استونيا ',
    EG: 'مصر ',
    EH: 'الصحراء الغربية',
    ER: 'إريتريا ',
    ES: 'اسبانيا ',
    ET: 'إثيوبيا ',
    FI: 'فنلندا ',
    FJ: 'فيجي ',
    FK: 'جزر فوكلاند ',
    FM: 'ميكرونيزيا ',
    FO: 'جزر فارو',
    FR: 'فرنسا ',
    GA: 'الغابون ',
    GB: 'المملكة المتحدة',
    GD: 'غرينادا ',
    GE: 'جورجيا ',
    GF: 'غيانا الفرنسية',
    GG: 'غيرنسي',
    GH: 'غانا ',
    GI: 'جبل طارق',
    GL: 'غرينلاند',
    GM: 'غامبيا ',
    GN: 'غينيا ',
    GP: 'جوادلوب ',
    GQ: 'غينيا الاستوائية',
    GR: 'اليونان ',
    GS: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    GT: 'غواتيمالا ',
    GU: 'غوام ',
    GW: 'غينيا بيساو',
    GY: 'غيانا ',
    HK: 'هونج كونج هونج كونج',
    HM: 'جزيرة هيرد وجزر ماكدونالد',
    HN: 'هندوراس ',
    HR: 'كرواتيا ',
    HT: 'هايتي ',
    HU: 'المجر ',
    ID: 'اندونيسيا ',
    IE: 'أيرلندا ',
    IL: 'إسرائيل ',
    IM: 'جزيرة مان',
    IN: 'الهند ',
    IO: 'المحيط الهندي البريطاني',
    IQ: 'العراق ',
    IR: 'إيران ',
    IS: 'أيسلندا',
    IT: 'إيطاليا ',
    JE: 'جيرسي ',
    JM: 'جامايكا ',
    JO: 'الأردن ',
    JP: 'اليابان ',
    KE: 'كينيا ',
    KG: 'قيرغيزستان ',
    KH: 'كمبوديا ',
    KI: 'كيريباس ',
    KM: 'جزر القمر',
    KN: 'سانت كيتس ونيفيس',
    KP: 'كوريا الشمالية',
    KR: 'كوريا الجنوبية',
    KW: 'الكويت ',
    KY: 'جزر كايمان',
    KZ: 'كازاخستان',
    LA: 'لاوس ',
    LB: 'لبنان ',
    LC: 'سانت لوسيا ',
    LI: 'ليختنشتاين ',
    LK: 'سري لانكا',
    LR: 'ليبيريا ',
    LS: 'ليسوتو ',
    LT: 'ليتوانيا ',
    LU: 'لوكسمبورغ ',
    LV: 'لاتفيا ',
    LY: 'ليبيا ',
    MA: 'المغرب ',
    MC: 'موناكو ',
    MD: 'مولدافيا ',
    ME: 'الجبل الأسود',
    MF: 'سانت مارتن',
    MG: 'مدغشقر ',
    MH: 'جزر مارشال',
    MK: 'مقدونيا',
    ML: 'مالي ',
    MM: 'ميانمار',
    MN: 'منغوليا ',
    MO: 'ماكاو ',
    MP: 'جزر ماريانا الشمالية',
    MQ: 'مارتينيك',
    MR: 'موريتانيا ',
    MS: 'مونتسيرات ',
    MT: 'مالطا ',
    MU: 'موريشيوس ',
    MV: 'جزر المالديف',
    MW: 'ملاوي ',
    MX: 'المكسيك ',
    MY: 'ماليزيا ',
    MZ: 'موزمبيق ',
    NA: 'ناميبيا ',
    NC: 'كاليدونيا الجديدة',
    NE: 'النيجر ',
    NF: 'جزيرة نورفولك',
    NG: 'نيجيريا ',
    NI: 'نيكاراغوا ',
    NL: 'هولندا ',
    NO: 'النرويج ',
    NP: 'نيبال ',
    NR: 'ناورو ',
    NU: 'نيوي ',
    NZ: ' نيوزيلاندا',
    OM: 'عمان ',
    PA: 'بنما ',
    PE: 'بيرو ',
    PF: 'بولينيزيا الفرنسية',
    PG: 'بابوا غينيا الجديدة',
    PH: 'الفلبين ',
    PK: 'باكستان ',
    PL: 'بولندا ',
    PM: 'سانت بيير وميكلون',
    PN: 'جزر بيتكيرن',
    PR: 'بورتوريكو للعلاقات العامة',
    PS: 'فلسطين ',
    PT: 'البرتغال ',
    PW: 'بالاو ',
    PY: 'باراغواي ',
    QA: 'قطر',
    RE: 'ريونيون ',
    RO: 'رومانيا ',
    RS: 'صربيا ',
    RU: 'روسيا ',
    RW: 'رواندا ',
    SA: 'المملكة العربية السعودية',
    SB: 'جزر سليمان',
    SC: 'سيشيل ',
    SD: 'السودان ',
    SE: 'السويد ',
    SG: 'سنغافورة ',
    SH: 'سانت هيلانة',
    SI: 'سلوفينيا ',
    SJ: 'سفالبارد وجان ماين',
    SK: 'سلوفاكيا ',
    SL: 'Sierra Leone',
    SM: 'سان مارينو',
    SN: 'السنغال ',
    SO: 'الصومال ',
    SR: 'سورينام ',
    SS: 'جنوب السودان',
    ST: 'S?o Tomé and Pr?ncipe',
    SV: 'السلفادور ',
    SX: 'سينت مارتن',
    SY: 'سوريا ',
    SZ: 'سوازيلاند ',
    TC: 'جزر تركس وكايكوس',
    TD: 'تشاد ',
    TF: 'الأراضي الفرنسية الجنوبية',
    TG: 'توغو ',
    TH: 'تايلند ',
    TJ: 'طاجيكستان ',
    TK: 'توكيلاو ',
    TL: 'تيمور الشرقية ',
    TM: 'تركمانستان ',
    TN: 'تونس ',
    TO: 'تونغا ',
    TR: 'تركيا ',
    TT: 'ترينيداد وتوباغو',
    TV: 'توفالو',
    TW: 'تايوان ',
    TZ: 'تنزانيا ',
    UA: 'أوكرانيا ',
    UG: 'أوغندا ',
    UM: 'جزر الولايات المتحدة البعيدة الصغيرة',
    US: ' الولايات المتحدة',
    UY: 'أوروغواي ',
    UZ: 'أوزبكستان ',
    VA: 'مدينة الفاتيكان ',
    VC: 'سانت فنسنت وجزر غرينادين ',
    VE: 'فنزويلا ',
    VG: 'جزر فيرجن البريطانية ',
    VI: 'جزر فيرجن الأمريكية السادسة',
    VN: 'فيتنام ',
    VU: 'فانواتو ',
    WF: 'واليس وفوتونا ',
    WS: 'ساموا ',
    XK: 'كوسوفو',
    YE: 'اليمن ',
    YT: 'مايوت ',
    ZA: 'جنوب إفريقيا',
    ZM: 'زامبيا ',
    ZW: 'زيمبابوي',
};

export default countries;
