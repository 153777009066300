const Events = {
    VERIFY_USER: "VERIFY_USER",
    USER_CONNECTED: "USER_CONNECTED",
    PRIVATE_MESSAGE: "PRIVATE_MESSAGE",
    MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
    MESSAGE_SENT: "MESSAGE_SENT",
    LOGOUT: "LOGOUT",
    REMOVE_USER: "REMOVE_USER",
    USER_REMOVED_FROM_ROOM: "USER_REMOVED_FROM_ROOM",
    UPDATE_ROOMS: "UPDATE_ROOMS",
    ADD_ROOM: "ADD_ROOM",
    ADD_MESSAGE_TO_WALL: "ADD_MESSAGE_TO_WALL",
    WALL_UPDATED: "WALL_UPDATED",
    WALL_MESSAGES_NUM: "WALL_MESSAGES_NUM",
    IS_USER_CONNECTED: "IS_USER_CONNECTED",
    PRIVATE_MESSAGE_SENDER: "PRIVATE_MESSAGE_SENDER",
    PRIVATE_MESSAGE_RECEIVER: "PRIVATE_MESSAGE_RECEIVER",
    GET_CONNECTED_USERS: "GET_CONNECTED_USERS",
    UPDATE_CHAT_USER_SETTINGS: "UPDATE_CHAT_USER_SETTINGS",
    CHAT_USER_SETTINGS_UPDATED: "CHAT_USER_SETTINGS_UPDATED",
    REMOVE_PRIVATE_CONVERSATION: "REMOVE_PRIVATE_CONVERSATION",
    USER_LEAVE_ROOM: "USER_LEAVE_ROOM",
    CONNECTED_USERS_UPDATED: "CONNECTED_USERS_UPDATED",
    USER_LEAVED_ROOM: "USER_LEAVED_ROOM",
    SET_DAILY_MESSAGES_PERIOD: "SET_DAILY_MESSAGES_PERIOD",
    DAILY_MESSAGES: "DAILY_MESSAGES",
    UPDATE_ROOM: "UPDATE_ROOM",
    DELETE_ROOM: "DELETE_ROOM",
    SET_USER_COUNTRY_IP: "SET_USER_COUNTRY_IP",
    EXPULSION: "EXPULSION",
    USER_EXPULSION: "USER_EXPULSION",
    UPDATE_USER: 'UPDATE_USER',
    SET_NOTIFICATION_MESSAGE: 'SET_NOTIFICATION_MESSAGE',
    VIEW_NICKS: 'VIEW_NICKS',
    DELETE_WALL_MESSAGE: 'DELETE_WALL_MESSAGE',
    REMOVE_WALL: 'REMOVE_WALL',
    SEND_ADV: 'SEND_ADV',
    UPDATE_ROOMS_WITH_NEW_ACTIVE_ROOM: 'UPDATE_ROOMS_WITH_NEW_ACTIVE_ROOM',
    ROOM_DELETED: 'ROOM_DELETED',
    REMOVE_PRIVATE_CONVERSATIONS: 'REMOVE_PRIVATE_CONVERSATIONS',
    GET_USER_ACTIVE_ROOM: 'GET_USER_ACTIVE_ROOM',
    UPDATE_USER_GROUP: 'UPDATE_USER_GROUP',
    SEND_NOTIFICATION: 'SEND_NOTIFICATION',
    CLEAN_DISCONNECTED_GUEST_USERS: 'CLEAN_DISCONNECTED_GUEST_USERS',
    CLEAN_GUEST_USERS_ARRAY: 'CLEAN_GUEST_USERS_ARRAY',
    CHANGE_HIDDEN_LOGIN_STATUS: 'CHANGE_HIDDEN_LOGIN_STATUS',
    JOIN_ROOM: 'JOIN_ROOM',
    USER_JOINED_ROOM: 'USER_JOINED_ROOM',
    USER_JOINED_PRIVATE_CONVERSATION: 'USER_JOINED_PRIVATE_CONVERSATION',
    SEND_MESSAGE: 'SEND_MESSAGE',
    JOIN_PRIVATE_CONVERSATION: 'JOIN_PRIVATE_CONVERSATION',
    UPDATE_ROOMS_USERS: 'UPDATE_ROOMS_USERS',
    CHANGE_ACTIVE_ROOM: 'CHANGE_ACTIVE_ROOM',
    WALL_MESSAGE_SENT: 'WALL_MESSAGE_SENT',
    UPDATE_THE_USER: 'UPDATE_THE_USER',
    adv_message_sent: 'ADV_MESSAGE_SENT',
    default_wall_messages: 'DEFAULT_WALL_MESSAGES',
    remove_non_const_empty_room: 'REMOVE_NON_CONST_EMPTY_ROOM',
    expulsion_from_room: 'EXPULSION_FROM_ROOM',
    changing_hidden_login_status: 'CHANGING_HIDDEN_LOGIN_STATUS',
    set_room_the_user_expelled_from: 'SET_ROOM_THE_USER_EXPELLED_FROM',
    DISCONNECTING: 'disconnecting',
    is_user_already_connected: 'IS_USER_ALREADY_CONNECTED',
    SITE_SETTINGS_UPDATED: 'SITE_SETTINGS_UPDATED',
    UPDATE_SHORTCUTS: 'UPDATE_SHORTCUTS',
    WALL_MESSAGE_LIKE: 'WALL_MESSAGE_LIKE',
    SEND_WALL_MESSAGE_COMMENT: 'SEND_WALL_MESSAGE_COMMENT',
    DELETE_PUBLIC_MESSAGE: 'DELETE_PUBLIC_MESSAGE',
    LEAVE_ACTIVE_ROOM: 'LEAVE_ACTIVE_ROOM',
};

export default Events;
