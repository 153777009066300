import React, {Component} from 'react';
import {convertDecimalToHex, createMarkup, isHiddenLogin} from '../functions';
import Config from "../config";
import Constants from "../constants";
import '../assets/sass/ListingUsers.scss';

class ListingUsers extends Component {
    handleUserClick = (user) => {
        const {handleConnectedUsersClick} = this.props;

        if (handleConnectedUsersClick) {
            handleConnectedUsersClick(user)
        }
    };

    render() {
        let {
            users,
            task,
            loggedInUser,
            showUserActivityFlag
        } = this.props;

        return (
            <div className="users-list">
                {
                    users && users.length > 0 && (
                        users.sort((a, b) => {
                            const aGroups = a.groups || [];
                            const bGroups = b.groups || [];
                            const aGroup = aGroups[0] || {}
                            const bGroup = bGroups[0] || {}
                            const aGroupInfo = aGroup.group_info || {};
                            const bGroupInfo = bGroup.group_info || {};
                            const aOrder = aGroupInfo.order || -999;
                            const bOrder = bGroupInfo.order || -999;

                            return bOrder - aOrder;
                        }).map((user) => {
                            if (isHiddenLogin(user, loggedInUser)) {
                                return;
                            }

                            const chatUser = user.chatuser || {};
                            const chatUserSettings = chatUser.chat_user_settings || {};
                            const userLoginInfo = chatUser.userlogininfo || {};
                            const userGroups = user.groups;
                            const userGroup = userGroups[0] || {};
                            const userGroupInfo = userGroup.group_info || {};
                            const clickable = task === 'CONNECTED' && loggedInUser && user.username !== loggedInUser.username;

                            let userActivityClass;

                            switch (user.activity) {
                                case Constants.user_activity.active:
                                    userActivityClass = 'green';
                                    break;
                                case Constants.user_activity.active_disable_private_chat:
                                    userActivityClass = 'red';
                                    break;
                                case Constants.user_activity.inactive:
                                    userActivityClass = 'yellow';
                                    break;
                                case Constants.user_activity.inactive_and_band:
                                    userActivityClass = 'gray';
                                    break;
                                case Constants.user_activity.hidden_login:
                                    userActivityClass = 'blue';
                                    break;
                                default:
                                    userActivityClass = 'green';
                            }

                            let imageContentWrapperClass = 'image-content-wrapper';

                            if (showUserActivityFlag) {
                                imageContentWrapperClass += ' user-activity';
                            }

                            let imageUrl = window.defaultUserImage;

                            if (chatUserSettings.picture_name) {
                                imageUrl = Config.image_path + Constants.avatars + chatUserSettings.picture_name;
                            }

                            return (
                                <div className={'user' + (clickable ? ' clickable' : '')}
                                     key={user.id}
                                     onClick={() => {
                                         this.handleUserClick(user)
                                     }}
                                >
                                    <div className={imageContentWrapperClass}>
                                        {
                                            showUserActivityFlag && (
                                                <div className={`user-activity-flag ${userActivityClass}`}>
                                                </div>
                                            )
                                        }
                                        {
                                            <img src={imageUrl}
                                                 className="img-responsive"
                                                 alt={chatUser.nick_name || user.username}
                                            />
                                        }
                                    </div>
                                    <div className="content">
                                        {
                                            userLoginInfo.country && (
                                                <i className={`flag flag-${userLoginInfo.country.toLowerCase()}`}/>
                                            )
                                        }
                                        <div className="username-gift username-style">
                                            {
                                                !user.hideStar && userGroupInfo && userGroupInfo.icon && (
                                                    <span className="user-icon">
                                                        <img src={Config.image_path + Constants.super_images + userGroupInfo.icon}
                                                             className="img-responsive"
                                                             alt=""/>
                                                    </span>
                                                )
                                            }
                                            {
                                                user.gift && (
                                                    <div className="gift">
                                                        <img src={Config.image_path + Constants.gifts_images + user.gift}
                                                             alt="message.user.gift"
                                                             className="img-responsive"
                                                        />
                                                    </div>
                                                )
                                            }
                                            <span
                                                className="username-text"
                                                style={{
                                                    color: convertDecimalToHex(chatUserSettings.display_name_color),
                                                    background: convertDecimalToHex(chatUserSettings.background_color)
                                                }}
                                            >
                                                {chatUser.nick_name || user.username}
                                            </span>
                                        </div>
                                        {
                                            chatUser.bio && (
                                                <div className="bio"
                                                     dangerouslySetInnerHTML={createMarkup(chatUser.bio, true)}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })
                    )
                }
            </div>
        );
    }
}

export default ListingUsers;
