import React, {Component} from 'react';
import Events from '../events';
import Labels from '../labels';
import {register, login} from '../apis/auth';
import Spinner from "./functional/Spinner";
import Constants from "../constants";
import '../assets/sass/Login.scss';
import {mapArabicNumbersToEnglish} from "../functions";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: '',
            hiddenLogin: false,
            loading: false
        };
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setError('');

        const {socket, task, checkIfDeviceBlocked} = this.props;
        const {username, password} = this.state;

        if (!username) {
            let errorMessage = Labels.username_required;

            if (task === Constants.login_types.guests_login) {
                errorMessage = Labels.username_guest_required;
            }

            this.setError(errorMessage);

            return;
        }

        if (task !== Constants.login_types.guests_login && !password) {
            this.setError(Labels.password_required);

            return;
        }

        if (checkIfDeviceBlocked() > -1) {
            this.setError(Labels['IP Address is blocked']);

            return;
        }

        socket.emit(Events.is_user_already_connected, username, (isUserConnected) => {
            if (isUserConnected) {
                this.setError(Labels.user_already_connected);

                return;
            }

            this.setState({
                loading: true
            });

            const mappedPassword = password.split("").map((letter) => {
                return mapArabicNumbersToEnglish(letter);
            }).join("");

            if (task === Constants.login_types.members_login) {
                const data = {
                    username,
                    password: mappedPassword
                };

                login(data)
                    .then((user) => {
                        const {hiddenLogin} = this.state;

                        socket.emit(Events.VERIFY_USER, user, hiddenLogin, window.fc, window.ua, this.setUser);

                        this.setState({
                            loading: false
                        });
                    }, (error) => {
                        error = error || {};

                        const {data} = error;

                        if (data && typeof data === 'object') {
                            data.non_field_errors = data.non_field_errors || {};

                            const message = data.data || data.non_field_errors[0];

                            this.setError(Labels[message]);
                        } else {
                            this.setError(Labels.something_went_wrong);
                        }

                        this.setState({
                            loading: false
                        });
                    });
            } else {
                let registerType = Constants.member;

                if (task === Constants.login_types.guests_login) {
                    registerType = Constants.guest;
                }

                const data = {
                    username,
                    password: mappedPassword,
                    registerType
                };

                register(data)
                    .then((user) => {
                        const {hiddenLogin} = this.state;

                        socket.emit(Events.VERIFY_USER, user, hiddenLogin, window.fc, window.ua, this.setUser);

                        this.setState({
                            loading: false
                        });
                    }, (error) => {
                        error = error || {};

                        const {data} = error;

                        if (data && typeof data === 'object') {
                            if (data.detail) {
                                this.setError(Labels[data.detail]);
                            } else {
                                data.non_field_errors = data.non_field_errors || {};

                                const message = data.data || data.non_field_errors[0];

                                this.setError(Labels[message]);
                            }
                        } else {
                            this.setError(Labels.something_went_wrong);
                        }

                        this.setState({
                            loading: false
                        });
                    });
            }
        });
    };

    setUser = (user) => {
        this.setError('');
        this.props.setUser(user);
    };

    setError = error => {
        this.setState({
            error
        });
    };

    render() {
        const {username, password, error, hiddenLogin, loading} = this.state;
        const {task, buttonsColor} = this.props;

        let errorAlert = null;

        if (error) {
            errorAlert = <div className="alert alert-danger">{error}</div>
        }

        return (
            <div className="login-form-wrapper" id="loginFormWrapper">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <form className="login-form" id="loginForm" onSubmit={this.handleSubmit}>
                    <input type="hidden" name="task" value={task}/>
                    {errorAlert}
                    {
                        [Constants.login_types.register, Constants.login_types.members_login].indexOf(task) > -1 && (
                            <div className="form-fields">
                                <div className="form-group mb-5">
                                    <button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            background: buttonsColor,
                                            borderColor: buttonsColor
                                        }}
                                    >
                                        {Labels.entrance}
                                    </button>
                                </div>
                                <div className="form-group mb-5">
                                    <input type="text"
                                           className="form-control"
                                           id="username"
                                           name="username"
                                           value={username}
                                           placeholder={Labels.username}
                                           onChange={this.handleChange}
                                           disabled={loading}
                                    />
                                </div>
                                {
                                    task === Constants.login_types.members_login && (
                                        <div className="form-group">
                                            <input type="checkbox"
                                                   name="hiddenLogin"
                                                   id="hiddenLogin"
                                                   checked={hiddenLogin}
                                                   onChange={this.handleChange}
                                                   disabled={loading}
                                            />
                                            <label htmlFor="hiddenLogin">
                                                {Labels.hidden_login}
                                            </label>
                                        </div>
                                    )
                                }
                                {
                                    task === Constants.login_types.register && (
                                        <div className="form-group">
                                            &nbsp;
                                        </div>
                                    )
                                }
                                <div className="form-group">
                                    <input type="password"
                                           className="form-control"
                                           id="password"
                                           name="password"
                                           value={password}
                                           placeholder={Labels.password}
                                           onChange={this.handleChange}
                                           disabled={loading}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        task === Constants.login_types.guests_login && (
                            <div className="form-fields">
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            background: buttonsColor,
                                            borderColor: buttonsColor
                                        }}
                                    >
                                        {Labels.entrance}
                                    </button>
                                </div>
                                <div className="form-group">
                                    <input type="text"
                                           className="form-control"
                                           id="username"
                                           name="username"
                                           value={username}
                                           placeholder={Labels.guest_name}
                                           onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        )
                    }
                </form>
            </div>
        );
    }
}

export default Login;
