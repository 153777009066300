import React, {Component} from 'react';
import {FaUser, FaUsers, FaComment, FaCommentDots} from 'react-icons/fa';
import {IoIosSettings} from 'react-icons/io';
import Labels from '../../labels';
import {getConnectedUsersCount} from "../../functions";
import Constants from "../../constants";
import '../../assets/sass/ChatActions.scss';

class ChatActions extends Component {

    handleClickSettings = () => {
        const {setSidebarTask} = this.props;

        setSidebarTask(Constants.sidebar_tasks.settings);
    };

    handleClickWall = () => {
        const {setSidebarTask, resetWallCounter} = this.props;

        setSidebarTask(Constants.sidebar_tasks.wall);
        resetWallCounter();
    };

    handleClickRooms = () => {
        const {setSidebarTask} = this.props;

        setSidebarTask(Constants.sidebar_tasks.rooms);
    };

    handleClickPrivateConversations = () => {
        const {setSidebarTask} = this.props;

        setSidebarTask(Constants.sidebar_tasks.private_conversations);
    };

    handleClickConnected = () => {
        const {setSidebarTask} = this.props;

        setSidebarTask(Constants.sidebar_tasks.connected);
    };

    render() {
        const {
            connectedUsers, roomsCount, user,
            unreadWallMessages, privateConversationsLength,
            buttonsColor, templateColor
        } = this.props;

        return (
            <div
                className="chat-actions"
                style={{
                    background: templateColor
                }}
            >
                <button
                    type="button"
                    className="action btn btn-dark"
                    title={Labels.settings}
                    onClick={this.handleClickSettings}
                    style={{
                        background: buttonsColor,
                        borderColor: buttonsColor
                    }}
                >
                    <span className="hide-on-mobile inline">{Labels.settings}</span>
                    <IoIosSettings/>
                </button>
                <button
                    type="button"
                    className={'action btn btn-dark' + (unreadWallMessages > 0 ? ' active' : '')}
                    title={Labels.wall}
                    onClick={this.handleClickWall}
                    style={{
                        background: buttonsColor,
                        borderColor: buttonsColor
                    }}
                >
                    <span>{Labels.wall}</span>
                    <span className="jewel-count right">{unreadWallMessages}</span>
                    <FaCommentDots/>
                </button>
                <button
                    type="button"
                    className="action btn btn-dark"
                    title={Labels.rooms}
                    onClick={this.handleClickRooms}
                    style={{
                        background: buttonsColor,
                        borderColor: buttonsColor
                    }}
                >
                    <span>{Labels.rooms_1}</span>
                    <span className="jewel-count right">{roomsCount}</span>
                    <FaUsers/>
                </button>
                <button
                    type="button"
                    className={'action btn btn-dark' + (privateConversationsLength > 0 ? ' active' : '')}
                    title={Labels.private_conversations}
                    onClick={this.handleClickPrivateConversations}
                    style={{
                        background: buttonsColor,
                        borderColor: buttonsColor
                    }}
                >
                    <span className="jewel-count">{privateConversationsLength}</span>
                    <FaComment/>
                </button>
                <button
                    type="button"
                    className="action btn btn-dark"
                    title={Labels.connected}
                    onClick={this.handleClickConnected}
                    style={{
                        background: buttonsColor,
                        borderColor: buttonsColor
                    }}
                >
                    <span className="jewel-count">{getConnectedUsersCount(connectedUsers, user)}</span>
                    <FaUser/>
                </button>
            </div>
        );
    }
}

export default ChatActions;
