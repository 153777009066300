import React, {Component} from 'react';
import Message from "./Message";
import '../../assets/sass/Messages.scss';

class Messages extends Component {
    scrollDown = () => {
        const messagesContainer = this.refs['messages-container'];
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
    };

    componentDidMount() {
        this.scrollDown();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollDown();
    }

    render() {
        const {
            messages,
            user,
            changeActiveRoom,
            contentColor,
            buttonsColor,
            callback,
            deleteMessageCallback
        } = this.props;

        return (
            <div
                className="messages-container"
                ref={'messages-container'}
                style={{
                    background: contentColor
                }}
            >
                <div className="messages">
                    <Message messages={messages}
                             user={user}
                             changeActiveRoom={changeActiveRoom}
                             buttonsColor={buttonsColor}
                             callback={callback}
                             deleteMessageCallback={deleteMessageCallback}
                    />
                </div>
            </div>
        );
    }
}

export default Messages;
