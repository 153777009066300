import React, {Component} from 'react';
import axios from 'axios';
import Events from '../events';
import Labels from '../labels';
import Constants from "../constants";
import countries from '../countries';
import likeImage from '../assets/images/like.png';
import {blockDevice, blockIp} from '../apis/admin/block';
import {
    like,
    getLikes,
    getLoggedInUserLikes,
    decreasePermissionCount,
    setChatSettings,
    checkRoomPassword
} from '../apis/chat';
import {userPermissions, getPermissionIdByCode, filterPermissionsGroups} from "../functions";
import {
    FaBan,
    FaComment,
    FaEnvelopeOpen,
    FaHeart,
    FaRegAddressCard,
    FaRegGem,
    FaSearch,
    FaTimes,
    FaUserTimes,
    FaInfo
} from "react-icons/fa";
import Spinner from "./functional/Spinner";
import Config from "../config";
import {addGroupToUser, getPermissionsGroups, updateGroupDays} from "../apis/admin/permissions";
import _ from "lodash";
import {addStatus} from "../apis/admin/statuses";
import '../assets/sass/flags.scss';
import '../assets/sass/UserProfileModal.scss';
import {updateUserLikes} from "../apis/admin/members";

class UserProfileModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            giftsModalOpened: false,
            viewNicksModalOpened: false,
            users: [], //all users connected on the same ip
            likes: 0,
            loggedInUserLikes: 0,
            loading: false,
            permissionsGroups: [],
            selectedGroup: {},
            groupDays: '0',
            nickname: '',
            showPrivateRoomPasswordField: false,
            transferUserRoom: {},
            transferUserRoomPassword: '',
            transferUserRoomPasswordRequired: false,
            transferUserRoomPasswordError: '',
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({
            loading: true
        });

        const {user, toUser} = this.props;
        const chatUser = user.chatuser || {};
        const toChatUser = toUser.chatuser || {};

        axios.all([
            getLoggedInUserLikes(chatUser.id),
            getLikes(chatUser.id, toChatUser.id),
            getPermissionsGroups()
        ]).then(axios.spread((getLoggedInUserLikesResponse, getLikesResponse, permissionsGroupsResponse) => {
            const data = getLoggedInUserLikesResponse.data;
            const loggedInUserLikes = data.likes_count;
            const permissionsGroupsData = permissionsGroupsResponse.data || {};
            const originalGroups = permissionsGroupsData.results || [];
            const permissionsGroups = filterPermissionsGroups(originalGroups, user);

            if (this._isMounted) {
                const selectedGroup = toUser['groups'][0] || {};

                this.setState({
                    loggedInUserLikes,
                    likes: getLikesResponse.data.likes_count,
                    loading: false,
                    permissionsGroups,
                    selectedGroup,
                    nickname: toChatUser.nick_name
                });
            }
        }, (errors) => {
            console.error(errors);

            this.setState({
                loading: false
            });
        }));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClick = (task) => {
        const {
            closeProfileModalCallback, socket, user,
            toUser, siteSettings, checkFilter,
            setNotificationMessage
        } = this.props;
        const {loggedInUserLikes} = this.state;
        const toChatUser = toUser.chatuser || {};
        const chatUser = user.chatuser || {};
        const toUserLoginInfo = toChatUser.userlogininfo || {};
        const toChatUserSettings = toChatUser.chat_user_settings || {};
        const {userActiveRoom} = this.props;

        switch (task) {
            case Constants.permissions.gifts:
                this.setState({
                    giftsModalOpened: true
                });

                break;
            case Constants.expulsion_from_room:
                this.setState({
                    loading: true
                });

                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.expulsion_from_room,
                    room: userActiveRoom ? userActiveRoom.id : null
                }).then(() => {
                    socket.emit(Events.expulsion_from_room, userActiveRoom, toUser);
                    closeProfileModalCallback();

                    this.setState({
                        loading: false
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });

                break;
            case Constants.permissions.block_user:
                this.setState({
                    loading: true
                });

                const permissionId = getPermissionIdByCode(Constants.permissions.block_user, user.id);

                decreasePermissionCount(permissionId, user.id)
                    .then(() => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: toChatUser.id,
                            action: Labels.expulsion,
                            room: userActiveRoom ? userActiveRoom.id : null
                        }).then(() => {
                            socket.emit(Events.EXPULSION, toUser);

                            this.setState({
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        setNotificationMessage(Labels.you_exceed_number_of_tries_for_block_user);

                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });

                closeProfileModalCallback();

                break;
            case Constants.permissions.bands:
                this.setState({
                    loading: true
                });

                axios.all([
                    blockIp(toUserLoginInfo.ip, toChatUser.id),
                    blockDevice(toUser.fingerCode, toUser.userAgent, toUser.username)
                ]).then(axios.spread(() => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: toChatUser.id,
                        action: Labels.band_1,
                        room: userActiveRoom ? userActiveRoom.id : null
                    }).then(() => {
                        socket.emit(Events.EXPULSION, toUser);

                        this.setState({
                            loading: false
                        });

                        closeProfileModalCallback();
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }), (errors) => {
                    console.error(errors);

                    this.setState({
                        loading: false
                    });
                });

                break;
            case Constants.remove_image:
                this.setState({
                    loading: true
                });

                const data = {
                    picture_name: null
                };

                setChatSettings(toChatUser.id, data, true)
                    .then((response) => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: toChatUser.id,
                            action: Labels.remove_image_member_to_member,
                            room: userActiveRoom ? userActiveRoom.id : null
                        }).then(() => {
                            closeProfileModalCallback();

                            socket.emit(
                                Events.UPDATE_THE_USER,
                                toUser.username,
                                Constants.tasks.update_chat_user_settings,
                                response.data
                            );

                            this.setState({
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                break;
            case Constants.warning:
                if (siteSettings.notifications_max_likes_allowed <= loggedInUserLikes) {
                    const warning = prompt(Labels.enter_warning_message);

                    if (warning && toChatUserSettings.enable_notification) {
                        this.setState({
                            loading: true
                        });

                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: toChatUser.id,
                            action: Labels.send_notification_member_to_member,
                            room: userActiveRoom ? userActiveRoom.id : null
                        }).then(() => {
                            socket.emit(
                                Events.SEND_NOTIFICATION,
                                user.username,
                                toUser.username,
                                warning
                            );

                            checkFilter({
                                message: warning,
                                user
                            });

                            this.setState({
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }
                } else {
                    setNotificationMessage(Labels.you_dont_have_enough_likes)
                }

                closeProfileModalCallback();
                break;
            case Constants.permissions.open_nicknames:
                this.setState({
                    loading: true
                });

                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.open_nicknames_member_to_member,
                    room: userActiveRoom ? userActiveRoom.id : null
                }).then(() => {
                    socket.emit(Events.VIEW_NICKS, toUserLoginInfo.ip, (users) => {
                        this.setState({
                            users,
                            viewNicksModalOpened: true,
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });

                break;
            case Constants.like:
                const {canDoLikeCallback, canDoLike} = this.props;

                if (!canDoLike) {
                    socket.emit(
                        Events.SEND_NOTIFICATION,
                        user.username,
                        user.username,
                        Labels.you_can_make_an_only_like_within_one_minute
                    );

                    return;
                }

                this.setState({
                    loading: true
                });

                like(chatUser.id, toChatUser.id)
                    .then(() => {
                        getLikes(chatUser.id, toChatUser.id)
                            .then((response) => {
                                this.setState({
                                    likes: response.data.likes_count,
                                    loading: false
                                });

                                socket.emit(
                                    Events.SEND_NOTIFICATION,
                                    user.username,
                                    toUser.username,
                                    Labels.you_got_like,
                                    Constants.like_notification,
                                    likeImage
                                );

                                canDoLikeCallback(false);

                                setTimeout(() => {
                                    canDoLikeCallback(true);
                                }, 1000 * 60 * 2);
                            }, (error) => {
                                console.error(error);

                                this.setState({
                                    loading: false
                                });
                            });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                break;
            case Constants.private_conversation:
                if (siteSettings.dm_max_likes_allowed <= loggedInUserLikes) {
                    const {createAndOpenPrivateConversationModal} = this.props;

                    createAndOpenPrivateConversationModal();
                } else {
                    setNotificationMessage(Labels.you_dont_have_enough_likes);
                }

                closeProfileModalCallback();
                break;
            default:
                break;
        }
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            transferUserRoomPasswordError: '',
            transferUserRoomPasswordRequired: false
        });
    };

    giftIconClicked = (gift) => {
        const {socket, toUser, user, userActiveRoom} = this.props;
        const toChatUser = toUser.chatuser || {};
        const chatUser = user.chatuser || {};
        const permissionId = getPermissionIdByCode(Constants.permissions.gifts, user.id);

        this.setState({
            loading: true
        });

        decreasePermissionCount(permissionId, user.id)
            .then(() => {
                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.send_gift,
                    room: userActiveRoom ? userActiveRoom.id : null
                }).then(() => {
                    socket.emit(
                        Events.UPDATE_THE_USER,
                        toUser.username,
                        Constants.tasks.gift,
                        gift.word
                    );

                    socket.emit(
                        Events.SEND_NOTIFICATION,
                        user.username,
                        toUser.username,
                        Labels.gift_message,
                        Constants.gift_notification,
                        gift.word
                    );

                    this.setState({
                        loading: false
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                const {setNotificationMessage} = this.props;

                setNotificationMessage(Labels.you_exceed_number_of_tries_for_send_gifts);

                console.error(error);

                this.setState({
                    loading: false
                });
            });

        this.closeGiftModal();
    };

    removeGift = () => {
        const {socket, toUser} = this.props;

        socket.emit(
            Events.UPDATE_THE_USER,
            toUser.username,
            Constants.tasks.gift,
            null
        );

        socket.emit(
            Events.SEND_NOTIFICATION,
            null,
            toUser.username,
            Labels.remove_gift_message
        );

        this.closeGiftModal();
    };

    closeViewNicksModal = () => {
        this.setState({
            viewNicksModalOpened: false
        });
    };

    closeGiftModal = () => {
        this.setState({
            giftsModalOpened: false
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const {selectedGroup, groupDays} = this.state;

        if (selectedGroup.name === Constants.defaultGroup || (!groupDays && groupDays !== '0')) {
            alert(Labels.check_selected_group_or_group_days_value);

            return;
        }

        this.setState({
            loading: true
        });

        const {socket, toUser, user, userActiveRoom} = this.props;
        const chatUser = user.chatuser || {};
        const toChatUser = toUser.chatuser || {};

        addGroupToUser(toChatUser.id, selectedGroup.id)
            .then(() => {
                updateGroupDays(toChatUser.id, selectedGroup.id, groupDays)
                    .then(() => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: toChatUser.id,
                            action: Labels.change_permission_member_to_member,
                            room: userActiveRoom ? userActiveRoom.id : null
                        }).then(() => {
                            socket.emit(
                                Events.UPDATE_THE_USER,
                                toUser.username,
                                Constants.tasks.update_user_group,
                                selectedGroup
                            );

                            socket.emit(
                                Events.SEND_NOTIFICATION,
                                null,
                                toUser.username,
                                Labels.changing_group_message
                            );

                            this.setState({
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    handleChangeNickName = () => {
        this.setState({
            loading: true
        });

        const {nickname} = this.state;
        const {toUser, socket, userActiveRoom, user} = this.props;
        const chatUser = user.chatuser || {};
        const toChatUser = toUser.chatuser || {};
        const data = {
            nick_name: nickname
        };

        setChatSettings(toChatUser.id, data, true)
            .then((response) => {
                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.change_nickname,
                    room: userActiveRoom ? userActiveRoom.id : null
                }).then(() => {
                    this.setState({
                        loading: false
                    });

                    socket.emit(
                        Events.UPDATE_THE_USER,
                        toUser.username,
                        Constants.tasks.update_chat_user_settings,
                        response.data
                    );

                    socket.emit(
                        Events.SEND_NOTIFICATION,
                        null,
                        toUser.username,
                        Labels.nickname_changed_message
                    );

                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    handlePermissionsGroupChange = (e) => {
        const value = e.target.value;
        const {permissionsGroups} = this.state;
        const selectedGroup = _.find(permissionsGroups, (group) => {
            return group.id === parseInt(value);
        });

        this.setState({
            selectedGroup
        });
    };

    handleTransferUserToRoom = (e) => {
        e.preventDefault();

        const {transferUserRoom} = this.state;

        if (!transferUserRoom.id) {
            return;
        }

        const {changeActiveRoom, toUser, closeProfileModalCallback, socket, user} = this.props;

        const doTransfer = () => {
            const chatUser = user.chatuser || {};
            const toChatUser = toUser.chatuser || {};

            changeActiveRoom(transferUserRoom.id, toUser);
            closeProfileModalCallback();

            addStatus({
                chatUserId: chatUser.id,
                toChatUserId: toChatUser.id,
                action: Labels.user_transfer_room + transferUserRoom.nick_name,
                room: transferUserRoom ? transferUserRoom.id : null
            }).then((response => {
                socket.emit(
                    Events.SEND_NOTIFICATION,
                    user.username,
                    toUser.username,
                    Labels.user_transfer_room + transferUserRoom.nick_name
                );
            }), (error) => {
                console.error(error);
            })
        };

        if (transferUserRoom.room_type === Constants.room_types.private_room) {
            const {transferUserRoomPassword} = this.state;

            if (!transferUserRoomPassword) {
                this.setState({
                    transferUserRoomPasswordRequired: true
                });

                return;
            }

            checkRoomPassword(transferUserRoom.id, transferUserRoomPassword)
                .then(() => {
                    doTransfer();
                }, (error) => {
                    const {data} = error;

                    this.setState({
                        transferUserRoomPasswordError: Labels[data.detail]
                    });
                });

            return;
        }

        doTransfer();
    };

    handleChangingRoom = (e) => {
        const value = e.target.value;
        const {rooms} = this.props;
        const selectedRoom = _.find(rooms, (room) => {
            return room.id === parseInt(value);
        });

        this.setState({
            transferUserRoom: selectedRoom,
            showPrivateRoomPasswordField: selectedRoom.room_type === Constants.room_types.private_room
        })
    };

    updateUserLikesFunc = (toUser) => {
        this.setState({
            loading: true
        });

        const {likes} = this.state;
        const {user} = this.props;
        const chatUser = user.chatuser || {};
        const toChatUser = toUser.chatuser || {};

        updateUserLikes(toChatUser.id, likes)
            .then(() => {
                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.change_likes,
                    room: null
                }).then(() => {
                    this.setState({
                        loading: false
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            })
    };

    render() {
        const {toUser, user, gifts, buttonsColor, userActiveRoom, changeActiveRoom, rooms} = this.props;
        const toChatUser = toUser.chatuser || {};
        const toUserLoginInfo = toChatUser.userlogininfo || {};
        const toChatUserSettings = toChatUser.chat_user_settings || {};
        const {
            giftsModalOpened,
            viewNicksModalOpened,
            users,
            likes,
            loading,
            permissionsGroups,
            groupDays,
            nickname,
            showPrivateRoomPasswordField,
            transferUserRoomPasswordRequired,
            transferUserRoomPasswordError,
        } = this.state;

        let imageUrl = window.defaultUserImage;
        let roomImageUrl = window.defaultRoomImage;

        if (toUser && toChatUserSettings.picture_name) {
            imageUrl = Config.image_path + Constants.avatars + toChatUserSettings.picture_name;
        }

        if (userActiveRoom && userActiveRoom.image) {
            roomImageUrl = Config.image_path + Constants.rooms_images + userActiveRoom.image;
        }

        const userGroups = user.groups;
        const userGroup = userGroups[0] || {};
        const userGroupInfo = userGroup.group_info || {};
        const toUserGroups = toUser.groups;
        const toUserGroup = toUserGroups[0] || {};
        const toUserGroupInfo = toUserGroup.group_info || {};

        return (
            <div className={'user-profile-modal'}>
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="info">
                    <div className="image">
                        {
                            <img src={imageUrl}
                                 className="img-responsive"
                                 alt={toChatUser.nick_name || toUser.username}
                            />
                        }
                    </div>
                    <div className="bio">
                        {toChatUser.bio}
                    </div>
                </div>
                <div className="country-room">
                    {
                        toUserLoginInfo.country && (
                            <div className="country">
                                <i className={`flag flag-${toUserLoginInfo.country.toLowerCase()}`}/>
                                <div className="country-name">{countries[toUserLoginInfo.country]}</div>
                            </div>
                        )
                    }
                    {
                        userActiveRoom && (
                            <div className="room">
                                <button
                                    className="btn"
                                    style={{
                                        background: buttonsColor,
                                        borderColor: buttonsColor
                                    }}
                                    onClick={() => {
                                        if (changeActiveRoom) {
                                            changeActiveRoom(userActiveRoom.id);
                                        }
                                    }}
                                >
                                    <div className="room-image">
                                        {
                                            <img src={roomImageUrl}
                                                 alt={userActiveRoom.nick_name}
                                                 className="img-responsive"
                                            />
                                        }
                                    </div>
                                    {userActiveRoom.nick_name}
                                </button>
                            </div>
                        )
                    }
                </div>
                <div className="actions">
                    <button
                        onClick={() => {
                            this.handleClick(Constants.private_conversation);
                        }}
                        className={'btn btn-sm btn-white'}
                        disabled={!toChatUserSettings.enable_private_chat && !userPermissions(Constants.permissions.open_private, user.id)}
                    >
                        <FaComment/>
                        <span>{Labels.private_conversation}</span>
                    </button>
                    <button
                        onClick={() => {
                            this.handleClick(Constants.warning);
                        }}
                        className={'btn btn-sm btn-white'}
                    >
                        <FaEnvelopeOpen/>
                        <span>{Labels.warning}</span>
                    </button>
                    <button
                        onClick={() => {
                            this.handleClick(Constants.like)
                        }}
                        className={'btn btn-sm btn-white red-color'}
                    >
                        <FaHeart/>
                        <span>{likes}</span>
                    </button>
                    {
                        userPermissions(Constants.permissions.gifts, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.permissions.gifts)
                                }}
                                className={'btn btn-sm btn-white'}
                                disabled={toUserGroup.name !== Constants.defaultGroup}
                            >
                                <FaRegGem/>
                                <span>{Labels.send_gift}</span>
                            </button>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.open_nicknames, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.permissions.open_nicknames)
                                }}
                                className={'btn btn-sm btn-white'}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaSearch/>
                                <span>{Labels.view_nicks}</span>
                            </button>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.block_user, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.remove_image)
                                }}
                                className={'btn btn-sm btn-white'}
                                disabled={!toChatUserSettings.picture_name || userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaBan/>
                                <span>{Labels.remove_image}</span>
                            </button>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.manage_rooms, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.expulsion_from_room)
                                }}
                                className={'btn btn-sm btn-danger'}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaUserTimes/>
                                <span>{Labels.expulsion_from_room}</span>
                            </button>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.block_user, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.permissions.block_user)
                                }}
                                className={'btn btn-sm btn-danger'}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaBan/>
                                <span>{Labels.expulsion}</span>
                            </button>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.bands, user.id) && (
                            <button
                                onClick={() => {
                                    this.handleClick(Constants.permissions.bands)
                                }}
                                className={'btn btn-sm btn-danger'}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaBan/>
                                <span>{Labels.band_1}</span>
                            </button>
                        )
                    }
                    <button
                        onClick={() => {
                            this.handleClick(Constants.ignore)
                        }}
                        className={'btn btn-sm btn-danger'}
                        disabled={userGroupInfo.order < toUserGroupInfo.order}
                    >
                        <FaBan/>
                        <span>{Labels.ignore}</span>
                    </button>
                </div>
                {
                    userPermissions(Constants.permissions.manage_nicknames, user.id) && (
                        <div className="change-nickname">
                            <label
                                htmlFor="nickname"
                                className="btn btn-sm"
                                style={{
                                    background: buttonsColor,
                                    borderColor: buttonsColor
                                }}
                            >
                                {Labels.nick_name}
                            </label>
                            <input
                                type="text"
                                name="nickname"
                                id="nickname"
                                className="form-control"
                                onChange={this.handleChange}
                                value={nickname}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            />
                            <button
                                className="btn btn-sm"
                                style={{
                                    background: buttonsColor,
                                    borderColor: buttonsColor
                                }}
                                onClick={this.handleChangeNickName}
                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                            >
                                <FaRegAddressCard/>
                                <span>{Labels.change}</span>
                            </button>
                        </div>
                    )
                }
                <div className="other-settings">
                    {
                        (userPermissions(Constants.permissions.manage_permission, user.id) || userPermissions(Constants.permissions.grant_permission, user.id)) && (
                            <form onSubmit={this.handleSubmit}>
                                <div className="flex-row flex-align-end">
                                    <div className="flex">
                                        <div className="form-group">
                                            <label htmlFor="permissionsGroups">
                                                {Labels.permission}
                                            </label>
                                            <br/>
                                            <select
                                                name="permissionsGroups"
                                                id="permissionsGroups"
                                                className="form-control"
                                                onChange={this.handlePermissionsGroupChange}
                                                defaultValue={toUserGroup.id || ''}
                                                disabled={userGroupInfo.order < toUserGroupInfo.order}
                                            >
                                                <option value="">{Labels.select_permissions_group}</option>
                                                {
                                                    permissionsGroups.map((permissionsGroup) => {
                                                        const permissionsGroupInfo = permissionsGroup.group_info;

                                                        return (
                                                            <option
                                                                key={permissionsGroup.id}
                                                                value={permissionsGroup.id}
                                                            >
                                                                {permissionsGroup.name} - {permissionsGroupInfo.order}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="groupDays">{Labels.period_in_days}</label>
                                            <input type="number"
                                                   className="form-control"
                                                   name="groupDays"
                                                   placeholder={Labels.group_days}
                                                   value={groupDays}
                                                   disabled={userGroupInfo.order < toUserGroupInfo.order}
                                                   min={0}
                                                   id="groupDays"
                                                   onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-sm btn-white ml-10 width-40">
                                        <span>{Labels.save}</span>
                                    </button>
                                </div>
                                <div className="separator"/>
                            </form>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.change_guest_room, user.id) && (
                            <form onSubmit={this.handleTransferUserToRoom}>
                                <div className="flex-row flex-align-end">
                                    <div className="flex">
                                        <div className={`${showPrivateRoomPasswordField && "form-group"}`}>
                                            <label htmlFor="transferUserRoom">
                                                {Labels.the_room}
                                            </label>
                                            <br/>
                                            <select
                                                name="transferUserRoom"
                                                id="transferUserRoom"
                                                className="form-control"
                                                defaultValue={toUserGroup.id || ''}
                                                onChange={this.handleChangingRoom}
                                            >
                                                <option value="">{Labels.choose_the_room}</option>
                                                {
                                                    rooms.sort((a, b) => {
                                                        return b.users.length - a.users.length;
                                                    }).map((room) => {
                                                        if (userActiveRoom && room.id === userActiveRoom.id) {
                                                            return;
                                                        }

                                                        return (
                                                            <option
                                                                key={room.id}
                                                                value={room.id}
                                                            >
                                                                {room.nick_name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {
                                            showPrivateRoomPasswordField && (
                                                <div className="form-group">
                                                    <label htmlFor="transferUserRoomPassword">{Labels.password}</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           name="transferUserRoomPassword"
                                                           placeholder={Labels.password}
                                                           id="transferUserRoomPassword"
                                                           onChange={this.handleChange}
                                                    />
                                                    {
                                                        transferUserRoomPasswordRequired && (
                                                            <div className="alert alert-danger">{Labels.password_required}</div>
                                                        )
                                                    }
                                                    {
                                                        transferUserRoomPasswordError && (
                                                            <div className="alert alert-danger">{transferUserRoomPasswordError}</div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <button type="submit" className="btn btn-sm btn-white ml-10 width-40">
                                        <span>{Labels.transfer}</span>
                                    </button>
                                </div>
                                <div className="separator"/>
                            </form>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.change_likes, user.id) && (
                            <div className="flex-row flex-align-end">
                                <div className="flex">
                                    <label htmlFor="likes">
                                        {Labels.likes}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="likes"
                                        placeholder={Labels.likes}
                                        id="likes"
                                        value={likes}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <button className="btn btn-sm btn-white ml-10 width-40" onClick={() => {
                                    this.updateUserLikesFunc(toUser)
                                }}>
                                    {Labels.save}
                                </button>
                            </div>
                        )}
                </div>
                <div className={`send-gift-modal${giftsModalOpened ? ' active' : ''}`}>
                    <div className="title">
                        {Labels.send_gift}
                        <div
                            className="close-gift-modal"
                            onClick={this.closeGiftModal}
                        >
                            <FaTimes/>
                        </div>
                    </div>
                    <div className="content">
                        {
                            gifts.map((gift) => {
                                return (
                                    <div
                                        key={gift.id}
                                        className="gift"
                                        onClick={() => {
                                            this.giftIconClicked(gift)
                                        }}
                                    >
                                        <img
                                            src={Config.image_path + Constants.gifts_images + gift.word}
                                            alt={gift.replacement}
                                            className="img-responsive"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={this.removeGift}
                            disabled={!toUser.gift}
                        >
                            {Labels.remove_gift}
                            <FaTimes/>
                        </button>
                    </div>
                </div>
                <div className={`view-nicks-modal${viewNicksModalOpened ? ' active' : ''}`}>
                    <div className="head">
                        <div
                            className="close-modal"
                            onClick={this.closeViewNicksModal}
                        >
                            <FaTimes/>
                        </div>
                        <div className="title">
                            {Labels.view_nicks}
                            <FaInfo/>
                        </div>
                    </div>
                    <div className="content">
                        {
                            users.map((user) => {
                                const chatUser = user.chatuser || {};
                                const userLoginInfo = chatUser.userlogininfo || {};

                                return (
                                    <div key={user.id} className="user">
                                        <div>
                                            <label htmlFor="#">{Labels.the_member}</label>
                                            <div>{user.username}</div>
                                        </div>
                                        <div>
                                            <label htmlFor="#">{Labels.nick_name}</label>
                                            <div>{chatUser.nick_name}</div>
                                        </div>
                                        <div>
                                            <label htmlFor="#">{Labels.ip}</label>
                                            <div>{userLoginInfo.ip}</div>
                                        </div>
                                        <div>
                                            <label htmlFor="#">{Labels.device}</label>
                                            <div>{user.fc}</div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UserProfileModal;
