import {axiosInstance} from '../../axios';

export const getPermissionsGroups = () => {
    return axiosInstance
        .get('admin/groups?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const createPermissionsGroup = (groupName, groupSort, groupIcon) => {
    const payload = {
        name: groupName,
        group_info: {
            order: groupSort
        }
    };

    if (groupIcon) {
        payload['group_info']['icon'] = groupIcon;
    }

    return axiosInstance
        .post('admin/groups', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const updatePermissionsGroup = (groupName, groupSort, groupIcon, groupId) => {
    const payload = {
        name: groupName,
        group_info: {
            order: groupSort
        }
    };

    if (groupIcon) {
        payload['group_info']['icon'] = groupIcon;
    }

    return axiosInstance
        .put('admin/groups/' + groupId, payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addPermissionsToGroup = (groupId, permissions) => {
    const payload = {
        permissions
    };

    return axiosInstance
        .post('admin/groups/' + groupId + '/permissions', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addPermissionsLimitsToGroup = (groupId, permissions) => {
    const payload = {
        permissions
    };

    return axiosInstance
        .post('admin/groups/' + groupId + '/permissions/limits', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const removePermissionsGroup = (groupId) => {
    return axiosInstance
        .delete('admin/groups/' + groupId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addGroupToUser = (userId, groupId) => {
    const payload = {
        groups: {
            add: [groupId]
        }
    };

    return axiosInstance
        .post('admin/users/' + userId + '/groups', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

export const updateGroupDays = (userId, groupId, days) => {
    const payload = {
        groups: {
            groups_deadline: [
                {
                    id: groupId,
                    days: parseInt(days)
                }
            ]
        }
    };

    return axiosInstance
        .post('admin/users/' + userId + '/groups', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};
