import React, {Component} from 'react';
import coverImage from '../assets/images/cover-image.png';
import coverVideo from '../assets/images/cover-video.png';
import {FaYoutube} from "react-icons/fa";
import Constants from "../constants";
import Config from "../config";
import '../assets/sass/MediaCover.scss';

class MediaCover extends Component {
    setRef = (element) => {
        this.mediaElement = element;
    };

    handleClick = () => {
        const element = this.mediaElement;
        const cover = element.querySelector('.cover');
        const media = element.querySelector('.media');

        cover.className = 'cover hide';
        media.className = 'media show';
    };

    render() {
        let {media, type, image, mediaFolder} = this.props;
        let mediaPath = Config.image_path;

        if (mediaFolder) {
            mediaPath = mediaPath + mediaFolder;
        }

        return (
            <div
                ref={this.setRef}
                className="media-element"
                onClick={this.handleClick}
            >
                <div className="cover">
                    {
                        type === Constants.media.youtube && (
                            <div className="youtube-image-icon">
                                <div className="youtube-icon">
                                    <FaYoutube/>
                                </div>
                                <div className="image">
                                    <img src={image} alt="" className="img-responsive"/>
                                </div>
                            </div>
                        )
                    }
                    {
                        type === Constants.media.image && (
                            <div className="image">
                                <img src={coverImage} alt="" className="img-responsive"/>
                            </div>
                        )
                    }
                    {
                        type === Constants.media.video && (
                            <div className="image">
                                <img src={coverVideo} alt="" className="img-responsive"/>
                            </div>
                        )
                    }
                </div>
                <div className={`media${type !== Constants.media.record ? ' hide' : ''}`}>
                    {
                        type === Constants.media.youtube && (
                            <iframe src={media} frameBorder="0" width="95%" height="125" allowFullScreen={true}/>
                        )
                    }
                    {
                        type === Constants.media.image && (
                            <img src={mediaPath + media} alt="" className="img-responsive"/>
                        )
                    }
                    {
                        type === Constants.media.record && (
                            <audio controls style={{
                                maxWidth: '100%'
                            }}>
                                <source src={mediaPath + media}/>
                                Your browser does not support the audio element.
                            </audio>
                        )
                    }
                    {
                        type === Constants.media.video && (
                            <video width="95%" height="200" controls>
                                <source src={mediaPath + media}/>
                                Your browser does not support the video tag.
                            </video>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default MediaCover;
