import React, {Component} from 'react';
import Config from '../../config';
import CreateRoom from "../forms/CreateRoom";
import Modal from "react-modal";
import Events from '../../events';
import Labels from '../../labels';
import Constants from '../../constants';
import {convertDecimalToHex, getPermissionIdByCode, userPermissions} from '../../functions'
import {decreasePermissionCount, setChatSettings, uploadFile} from '../../apis/chat'
import {FaSignOutAlt, FaCheck, FaUserTimes, FaRegPaperPlane, FaStar, FaEdit, FaHome, FaTimes} from 'react-icons/fa';
import JsColor from "../JsColor";
import Spinner from "../functional/Spinner";
import {addStatus} from "../../apis/admin/statuses";
import '../../assets/sass/Settings.scss';
import {changePasswordInPublic} from "../../apis/auth";

class Settings extends Component {
    constructor(props) {
        super(props);

        const {user} = props;
        const chatUser = user.chatuser || {};
        const chatUserSettings = chatUser.chat_user_settings || {};

        this.state = {
            nickName: chatUser.nick_name || user.username,
            bio: chatUser.bio || (chatUser.user_type === Constants.guest ? Labels.new_guest : Labels.new_member),
            nameColor: convertDecimalToHex(chatUserSettings.display_name_color) || '#333333',
            fontColor: convertDecimalToHex(chatUserSettings.font_color) || '#333333',
            bgColor: convertDecimalToHex(chatUserSettings.background_color) || '#ffffff',
            enablePrivateChat: !!chatUserSettings.enable_private_chat,
            enableNotifications: !!chatUserSettings.enable_notification,
            fontSize: chatUserSettings.font_name || 'font-size-100',
            userImage: chatUserSettings.picture_name || null,
            fileLoaded: 0,
            isModalOpen: false,
            hiddenLogin: user.hiddenLogin,
            loading: false,
            hideStar: user.hideStar,
            modalTask: "",
            password: "",
            passwordConfirm: "",
            changePasswordError: "",
            changePasswordLoading: false,
        };

        window.jsColorContainer = 'sidebar'
    }

    updateUserSettings = () => {
        this.setState({
            loading: true
        });

        const {user} = this.props;
        const chatUser = user.chatuser || {};

        const {
            nickName, bio, nameColor,
            fontColor, bgColor, userImage,
            fontSize, enablePrivateChat,
            enableNotifications
        } = this.state;

        const data = {
            nick_name: nickName,
            bio: bio,
            display_name_color: parseInt(nameColor.replace('#', ''), 16),
            font_color: parseInt(fontColor.replace('#', ''), 16),
            background_color: parseInt(bgColor.replace('#', ''), 16),
            picture_name: userImage,
            font_name: fontSize,
            enable_private_chat: !!enablePrivateChat,
            enable_notification: !!enableNotifications
        };

        setChatSettings(chatUser.id, data)
            .then((response) => {
                this.setState({
                    loading: false
                });

                const {socket} = this.props;

                socket.emit(
                    Events.UPDATE_THE_USER,
                    user.username,
                    Constants.tasks.update_chat_user_settings,
                    response.data
                );
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.updateUserSettings();
    };

    handleChange = (e, doUpdate, task) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        }, () => {
            if (doUpdate) {
                this.updateUserSettings();

                return;
            }

            const {socket, user} = this.props;

            switch (task) {
                case Constants.tasks.hiddenLogin:
                    const {activeRoom} = this.props;

                    socket.emit(
                        Events.UPDATE_THE_USER,
                        user.username,
                        task,
                        target.checked,
                        activeRoom.id
                    );

                    break;
                case Constants.tasks.hideStar:
                    socket.emit(
                        Events.UPDATE_THE_USER,
                        user.username,
                        task,
                        target.checked,
                    );

                    break;
            }
        });
    };

    handleChangeUserImage = (e) => {
        const data = new FormData();
        const file = e.target.files[0];

        data.append('file', file, file.name);
        data.append('uploadFolder', Constants.avatars);

        const onUploadProgress = (e) => {
            const fileLoaded = e.loaded / e.total * 100;

            this.setState({
                fileLoaded
            });
        };

        uploadFile(data, onUploadProgress)
            .then((response) => {
                const {data} = response;
                const userImage = data.file;

                this.setState({
                    userImage,
                }, this.updateUserSettings);

                setTimeout(() => {
                    this.setState({
                        fileLoaded: 0
                    });
                }, 1000)
            }, (error) => {
                console.error(error);
            });
    };

    handleRemoveUserImage = () => {
        this.setState({
            userImage: null
        }, this.updateUserSettings)
    };

    handleRemoveWall = () => {
        const {socket, user} = this.props;
        const chatUser = user.chatuser || {};

        addStatus({
            chatUserId: chatUser.id,
            toChatUserId: null,
            action: Labels.delete_wall,
            room: null
        }).then(() => {
            this.setState({
                loading: false
            });

            socket.emit(Events.REMOVE_WALL);
        }, (error) => {
            console.error(error);

            this.setState({
                loading: false
            });
        });
    };

    handleSendAdv = () => {
        const promptMessage = window.prompt(Labels.write_your_message_here);

        if (promptMessage.trim()) {
            const {user, sendMessage} = this.props;
            const chatUser = user.chatuser || {};
            const permissionId = getPermissionIdByCode(Constants.permissions.advertisement, user.id);

            this.setState({
                loading: true
            });

            decreasePermissionCount(permissionId, user.id)
                .then(() => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.send_adv,
                        room: null
                    }).then(() => {
                        this.setState({
                            loading: false
                        });

                        sendMessage({
                            message: promptMessage,
                            type: Constants.message_types.adv,
                            isADV: true
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    const {setNotificationMessage} = this.props;

                    setNotificationMessage(Labels.you_exceed_number_of_tries_for_send_adds);

                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    openModal = (modalTask) => {
        this.setState({
            modalIsOpen: true,
            modalTask
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false
        });
    };

    handleChangePasswordSubmit = (e) => {
        e.preventDefault();

        const {password, passwordConfirm} = this.state;

        this.setState({
            changePasswordError: ""
        });

        if (!password.trim() || !passwordConfirm.trim()) {
            this.setState({
                changePasswordError: Labels.all_fields_required
            });

            return;
        }

        if (password !== passwordConfirm) {
            this.setState({
                changePasswordError: Labels.passwordAndPasswordConfirmAreNotEqual
            });

            return;
        }

        this.setState({
            changePasswordLoading: true
        });

        changePasswordInPublic(password)
            .then((response) => {
                const {setNotificationMessage} = this.props;
                this.closeModal();
                this.setState({
                    changePasswordLoading: false,
                });

                setNotificationMessage(Labels.password_changed);
            }, (error) => {
                console.error(error);

                this.setState({
                    changePasswordLoading: false,
                    changePasswordError: Labels.error_occurred,
                });
            })
    };

    render() {
        const {logout, buttonsColor, socket, user, activeRoom} = this.props;
        const {
            nickName, bio, userImage, enablePrivateChat,
            fontSize, enableNotifications, fileLoaded, nameColor,
            fontColor, bgColor, hiddenLogin, loading, hideStar,
            modalIsOpen, modalTask, changePasswordError, changePasswordLoading,
        } = this.state;

        let userImageUrl = window.defaultUserImage;

        if (userImage) {
            userImageUrl = Config.image_path + Constants.avatars + userImage;
        }

        return (
            <div className="settings" id="sidebarSettings">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <form
                    onSubmit={this.handleSubmit}
                    className="personal-profile-form"
                >
                    <div className="text-right">
                        <label
                            className="fill-label personal-profile-label"
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.personal_profile}
                        </label>
                    </div>
                    <div className="form-group no-flex">
                        <label
                            htmlFor="nickName"
                            className='fill-label'
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.nick_name}
                        </label><br/>
                        <input
                            type="text"
                            className="form-control"
                            id="nickName"
                            name="nickName"
                            value={nickName}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group no-flex">
                        <label
                            htmlFor="bio"
                            className='fill-label'
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.bio}
                        </label><br/>
                        <input
                            type="text"
                            className="form-control"
                            id="bio"
                            name="bio"
                            value={bio}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="nameColor"
                            className='fill-label'
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.name_color}
                        </label>
                        <JsColor id="nameColor"
                                 onChange={(selectedColor) => {
                                     this.setState({nameColor: selectedColor})
                                 }}
                                 value={nameColor}
                        />
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="fontColor"
                            className='fill-label'
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.font_color}
                        </label>
                        <JsColor id="fontColor"
                                 onChange={(selectedColor) => {
                                     this.setState({fontColor: selectedColor})
                                 }}
                                 value={fontColor}
                        />
                    </div>
                    <div className="form-group no-m">
                        <label
                            htmlFor="bgColor"
                            className='fill-label'
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.bg_color}
                        </label>
                        <JsColor id="bgColor"
                                 onChange={(selectedColor) => {
                                     this.setState({bgColor: selectedColor})
                                 }}
                                 value={bgColor}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-success save-btn"
                    >
                        {Labels.save}
                        <FaEdit/>
                    </button>
                </form>
                <div className="other-settings">
                    <div className="font-size form-group">
                        <select
                            name="fontSize"
                            id="fontSize"
                            className="form-control"
                            value={fontSize}
                            onChange={(e) => {
                                this.handleChange(e, true)
                            }}
                            style={{
                                background: buttonsColor
                            }}
                        >
                            <option value="font-size-120">{Labels.font_size_120}</option>
                            <option value="font-size-110">{Labels.font_size_110}</option>
                            <option value="font-size-105">{Labels.font_size_105}</option>
                            <option value="font-size-100">{Labels.font_size_100}</option>
                            <option value="font-size-95">{Labels.font_size_95}</option>
                            <option value="font-size-90">{Labels.font_size_90}</option>
                        </select>
                    </div>
                    <div className="settings-btn-wrapper form-group">
                        <div className='upload-file-progress' style={{
                            width: Math.round(fileLoaded) + '%'
                        }}/>
                        <label
                            htmlFor="userImage"
                            className="no-m btn btn-white"
                        >
                            <span>
                                {Labels.change_user_image}
                            </span>
                            <input type="file"
                                   id="userImage"
                                   className="form-control"
                                   name="userImage"
                                   onChange={this.handleChangeUserImage}
                            />
                            <div className="avatar">
                                {
                                    <img src={userImageUrl}
                                         alt={nickName}
                                         className="img-responsive"
                                    />
                                }
                            </div>
                        </label>
                    </div>
                    <div className="settings-btn-wrapper form-group">
                        <button
                            className="btn btn-danger"
                            onClick={this.handleRemoveUserImage}
                        >
                        <span>
                            {Labels.remove_image}
                        </span>
                            <FaUserTimes/>
                        </button>
                    </div>
                    {
                        userPermissions(Constants.permissions.delete_wall, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <button
                                    className="btn btn-danger"
                                    onClick={this.handleRemoveWall}
                                >
                                    <span>
                                        {Labels.delete_wall}
                                    </span>
                                    <FaUserTimes/>
                                </button>
                            </div>
                        )
                    }
                    <div className="settings-btn-wrapper form-group">
                        <label
                            htmlFor="enablePrivateChat"
                            className='no-m btn btn-white'
                        >
                            <span>
                                {Labels.deactivate_private_messages}
                            </span>
                            <input
                                type="checkbox"
                                name="enablePrivateChat"
                                id="enablePrivateChat"
                                checked={enablePrivateChat}
                                onChange={(e) => {
                                    this.handleChange(e, true)
                                }}
                            />
                            {
                                !enablePrivateChat && (
                                    <FaCheck/>
                                )
                            }
                        </label>
                    </div>
                    {
                        userPermissions(Constants.permissions.hide_star, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <label
                                    htmlFor="hideStar"
                                    className='no-m btn btn-white'
                                >
                                    <span>
                                        {Labels.hide_star}
                                    </span>
                                    <input
                                        type="checkbox"
                                        name="hideStar"
                                        id="hideStar"
                                        checked={hideStar}
                                        onChange={(e) => {
                                            this.handleChange(e, false, Constants.tasks.hideStar)
                                        }}
                                    />
                                    {
                                        hideStar && (
                                            <FaCheck/>
                                        )
                                    }
                                </label>
                            </div>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.hide, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <label
                                    htmlFor="hiddenLogin"
                                    className='no-m btn btn-white'
                                >
                                    {
                                        hiddenLogin && (
                                            <span>
                                                {Labels.deactivate_hidden_login}
                                            </span>
                                        )
                                    }
                                    {
                                        !hiddenLogin && (
                                            <span>
                                                {Labels.activate_hidden_login}
                                            </span>
                                        )
                                    }
                                    <input
                                        type="checkbox"
                                        name="hiddenLogin"
                                        id="hiddenLogin"
                                        checked={hiddenLogin}
                                        onChange={(e) => {
                                            this.handleChange(e, false, Constants.tasks.hiddenLogin)
                                        }}
                                    />
                                    {
                                        hiddenLogin && (
                                            <FaCheck/>
                                        )
                                    }
                                </label>
                            </div>
                        )
                    }
                    <div className="settings-btn-wrapper form-group">
                        <label
                            htmlFor="enableNotifications"
                            className='no-m btn btn-white'
                        >
                        <span>
                            {Labels.deactivate_alerts}
                        </span>
                            <input
                                type="checkbox"
                                name="enableNotifications"
                                id="enableNotifications"
                                checked={enableNotifications}
                                onChange={(e) => {
                                    this.handleChange(e, true)
                                }}
                            />
                            {
                                !enableNotifications && (
                                    <FaCheck/>
                                )
                            }
                        </label>
                    </div>
                    <div className="settings-btn-wrapper form-group">
                        <button className="btn btn-white" onClick={() => {this.openModal(Constants.tasks.change_password)}}>
                            <span>
                                {Labels.change_password}
                            </span>
                        </button>
                    </div>
                    {
                        userPermissions(Constants.permissions.manage_rooms, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <button className="btn btn-white" onClick={() => {this.openModal(Constants.tasks.room_administration)}}>
                                    <span>
                                        {Labels.room_administration}
                                    </span>
                                    <FaHome/>
                                </button>
                            </div>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.advertisement, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <button
                                    className="btn btn-white"
                                    onClick={this.handleSendAdv}
                                >
                                    <span>
                                        {Labels.send_adv}
                                    </span>
                                    <FaRegPaperPlane/>
                                </button>
                            </div>
                        )
                    }
                    {
                        userPermissions(Constants.permissions.control_panel, user.id) && (
                            <div className="settings-btn-wrapper form-group">
                                <a href={`${Config[process.env.NODE_ENV].admin_panel_url}?id=${user.id}`}
                                   target="_blank"
                                   className="btn btn-white"
                                >
                                    <span>
                                        {Labels.admin_panel}
                                    </span>
                                    <FaStar/>
                                </a>
                            </div>
                        )
                    }
                    <div className="settings-btn-wrapper">
                        <button
                            type="button"
                            className="btn btn-danger btn-logout"
                            onClick={logout}
                        >
                            <span>
                                {Labels.logout}
                            </span>
                            <FaSignOutAlt/>
                        </button>
                    </div>
                </div>
                <Modal isOpen={modalIsOpen}
                       onRequestClose={this.closeModal}
                       className="modal-content"
                       overlayClassName='modal-overlay'
                >
                    <div className="head" onMouseDown={(e) => {
                        e.stopPropagation();
                    }}>
                        <div
                            className="head-content"
                            style={{
                                background: buttonsColor
                            }}
                        >
                            <FaTimes
                                onClick={this.closeModal}
                            />
                            <div className="title">
                                <span>
                                    {
                                        modalTask === Constants.tasks.room_administration && (
                                            Labels.room_administration
                                        )
                                    }
                                    {
                                        modalTask === Constants.tasks.change_password && (
                                            Labels.change_password
                                        )
                                    }
                                </span>
                                <FaHome/>
                            </div>
                        </div>
                    </div>
                    <div className="body" onMouseDown={(e) => {
                        e.stopPropagation();
                    }}>
                        {
                            modalTask === Constants.tasks.room_administration && (
                                <CreateRoom
                                    closeModal={this.closeModal}
                                    user={user}
                                    socket={socket}
                                    task={Constants.tasks.edit}
                                    buttonsColor={buttonsColor}
                                    activeRoom={activeRoom}
                                />
                            )
                        }
                        {
                            modalTask === Constants.tasks.change_password && (
                                <form onSubmit={this.handleChangePasswordSubmit}>
                                    {
                                        changePasswordError && (
                                            <div className="alert alert-danger">{changePasswordError}</div>
                                        )
                                    }
                                    {
                                        changePasswordLoading && (
                                            <Spinner/>
                                        )
                                    }
                                    <div className="form-group">
                                        <label htmlFor="password">{Labels.password}</label>
                                        <input type="password"
                                               id="password"
                                               className="form-control"
                                               name="password"
                                               onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="passwordConfirm">{Labels.passwordConfirm}</label>
                                        <input type="password"
                                               id="passwordConfirm"
                                               className="form-control"
                                               name="passwordConfirm"
                                               onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-sm btn-success">
                                            {Labels.change}
                                        </button>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Settings;
