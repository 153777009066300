import axios from 'axios';
import Config from './config';
import {getUrlParam, handleError, userPermissions} from "./functions";
import Constants from "./constants";

const apiUrl = Config[process.env.NODE_ENV].api_url;
const apiIpUrl = Config[process.env.NODE_ENV].aip_pi_url;
const apiVersion = Config[process.env.NODE_ENV].api_v;

export const axiosInstance = axios.create({
    baseURL: apiUrl + apiVersion
});

export const axiosIpInstance = axios.create({
    baseURL: apiIpUrl + apiVersion
});

const prepareAxiosInstance = (axiosInstance) => {
    axiosInstance.interceptors.request.use(function (config) {
        // Do something before request is sent
        const idParam = getUrlParam('id');

        if (idParam && !userPermissions(Constants.permissions.control_panel, idParam)) {
            window.location.href = "/";
        }

        if (!handleError()) {
            return config;
        }
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        handleError(error);
        return Promise.reject(error);
    });
}

prepareAxiosInstance(axiosInstance);
prepareAxiosInstance(axiosIpInstance);

export const setAuthorizationToken = (token, userId) => {
    if (userId) {
        localStorage.setItem('token_' + userId, token);
    }

    setAuthorizationHeaders(token);
};

export const setAuthorizationHeaders = (token) => {
    const headers = {
        headers: {
            Authorization: 'Token ' + token
        }
    };

    Object.assign(axiosInstance.defaults, headers);
    Object.assign(axiosIpInstance.defaults, headers);
};
