import React, {Component} from 'react';
import emoji from '../../assets/images/emoji.gif';
import moment from 'moment';
import Config from "../../config";
import {FaSignOutAlt, FaRegPaperPlane, FaShareAlt} from 'react-icons/fa';
import Labels from '../../labels';
import Constants from "../../constants";
import {blockIp, uploadFile} from "../../apis/chat";
import axios from "axios";
import {blockDevice} from "../../apis/admin/block";
import '../../assets/sass/MessageInput.scss';

let times = [];
let tries = 0;
let IGNORE_TIME = 300; // One second
let lastClick = null;

class MessageInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            openEmoji: false,
            fileLoaded: 0,
            disableInputMessage: false,
            numberOfChars: 257
        };

        this.emojiRef = React.createRef();
        this.messageInputRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillMount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (
            event.target &&
            event.target.className &&
            (typeof event.target.className === 'object' ||
                (typeof event.target.className === 'string' &&
                    event.target.className.indexOf('emoji-icon') < 0))
        ) {
            if (this.emojiRef.current && !this.emojiRef.current.contains(event.target)) {
                this.setState({
                    openEmoji: false
                });
            }
        }
    };

    handleEmojiClick = () => {
        const {openEmoji, disableInputMessage} = this.state;
        const {userAllowed, area} = this.props;

        if (area === Constants.public && (!userAllowed || disableInputMessage)) {
            return;
        }

        this.setState({
            openEmoji: !openEmoji
        });
    };

    handleLeaveRoomClick = () => {
        const {changeActiveRoom, userAllowed} = this.props;

        if (userAllowed) {
            changeActiveRoom();
        }
    };

    handleSubmit = () => {
        const now = Date.now();
        const {disableInputMessage} = this.state;

        if (disableInputMessage) {
            return ;
        }

        if (!(!lastClick || now - lastClick > IGNORE_TIME)) {
            this.setState({
                disableInputMessage: true
            })

            setTimeout(() => {
                this.setState({
                    disableInputMessage: false
                });
            }, 1000 * 10);

            return ;
        }

        lastClick = now;

        this.messageInputRef.current.focus();

        const {siteSettings, loggedInUserLikes, attachmentInputId} = this.props;

        if (attachmentInputId === Constants.attachments_ids.wall && siteSettings && siteSettings.likes_count > loggedInUserLikes) {
            const {setNotificationMessage} = this.props;

            setNotificationMessage(Labels.you_dont_have_enough_likes);

            return;
        }

        const {message} = this.state;

        if (!message.trim()) {
            return;
        }

        const {userExceedSendingMessagesAtCertainPeriod} = this.props;

        if (userExceedSendingMessagesAtCertainPeriod) {
            times.push(moment(new Date(), 'DD/MM/YYYY HH:mm:ss'));

            if (times.length === Config[process.env.NODE_ENV].user_typing_times) {
                const time = 1000 * 5;
                const start = times[0];
                const end = times[Config[process.env.NODE_ENV].user_typing_times - 1];
                const duration = moment.duration(end.diff(start));
                const milliseconds = duration.asMilliseconds();

                times.splice(0, times.length);

                if (milliseconds < time) {
                    tries++;

                    if (tries > 1) {
                        const {user} = this.props;
                        const chatUser = user.chatuser || {};
                        const userLoginInfo = chatUser.userlogininfo || {};

                        axios.all([
                            blockIp(userLoginInfo.ip),
                            blockDevice(user.fingerCode, user.userAgent, user.username)
                        ]).then(axios.spread(() => {
                            const {logout} = this.props;

                            if (logout) {
                                logout();
                            }
                        }), (errors) => {
                            console.error(errors);
                        });
                    } else {
                        const {setNotificationMessage} = this.props;

                        setNotificationMessage(Labels.your_request_ignored);

                        this.setState({
                            disableInputMessage: true
                        });

                        setTimeout(() => {
                            this.setState({
                                disableInputMessage: false
                            });
                        }, Config[process.env.NODE_ENV].message_input_pause_time);
                    }

                    return;
                }
            }
        }

        this.prepareAndSendMessage();
    };

    handleChange = (e) => {
        const {numberOfChars} = this.state;
        const message = e.target.value;

        if (message.length < numberOfChars) {
            this.setState({
                message
            });
        }
    };

    handleKeyDown = (e) => {
        const x = e.keyCode;

        if (x === 13) {
            this.handleSubmit();
        }
    };

    getYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return 'error';
        }
    };

    checkIfMessageShortCut = (message, shortcuts) => {
        for (let shortcut of shortcuts) {
            if (message === shortcut.word) {
                return shortcut.replacement;
            }
        }

        return false;
    };

    prepareAndSendMessage = (message, mediaType) => {
        message = message || this.state.message;
        message = message.trim();

        if (!message) {
            return;
        }

        const {sendMessage, attachmentInputId} = this.props;

        if (message.indexOf('youtu.be') > -1 || message.indexOf('youtube') > -1) {
            const youtubeV = this.getYouTubeId(message);
            const youtubeImage = 'https://img.youtube.com/vi/' + youtubeV + '/0.jpg';
            const youtubeEmped = 'https://www.youtube-nocookie.com/embed/' + youtubeV;

            sendMessage({
                message: youtubeEmped,
                mediaType: Constants.media.youtube,
                image: youtubeImage,
                isWall: attachmentInputId === Constants.attachments_ids.wall
            });
        } else {
            let {mapping, shortcuts} = this.props;
            let preparedMessage = '';
            let preparedMessageParts = [];
            let messageParts;

            for (let messagePart of message.split(' ')) {
                const replacedMessagePart = this.checkIfMessageShortCut(messagePart, shortcuts);

                preparedMessage += (replacedMessagePart ? replacedMessagePart : messagePart) + ' ';
            }

            messageParts = preparedMessage.split(' ');

            for (let i = 0; i < messageParts.length; i++) {
                let messagePart = messageParts[i];
                let replaced = false;

                if (messagePart) {
                    for (let shortcut of shortcuts) {
                        if (shortcut.word === messagePart) {
                            preparedMessageParts.push(shortcut.replacement);

                            replaced = true;

                            break;
                        }
                    }

                    if (!replaced) {
                        for (let key in mapping) {
                            if (!mapping.hasOwnProperty(key)) {
                                continue;
                            }

                            if (messagePart === key) {
                                const replacement = [
                                    '<div class="icon smiley"><img src="',
                                    process.env.PUBLIC_URL,
                                    '/uploads/',
                                    Constants.smiles_images,
                                    mapping[key],
                                    '" class="img-responsive"/></div>'
                                ].join('');

                                preparedMessageParts.push(replacement);

                                replaced = true;

                                break;
                            }
                        }
                    }

                    if (!replaced) {
                        preparedMessageParts.push(messagePart);
                    }
                }
            }

            sendMessage({
                message: preparedMessageParts.join(' '),
                mediaType: mediaType,
                isWall: attachmentInputId === Constants.attachments_ids.wall
            });
        }

        this.setState({
            message: ""
        });
    };

    emojiClicked = (icon) => {
        let {message} = this.state;

        this.setState({
            message: message + ' ' + icon.replacement
        });
    };

    handleUploadFile = (e) => {
        const {siteSettings, loggedInUserLikes, attachmentInputId} = this.props;

        if (siteSettings && ((attachmentInputId === Constants.attachments_ids.wall && siteSettings.likes_count > loggedInUserLikes) || (siteSettings.mms_max_likes_allowed > loggedInUserLikes))) {
            const {setNotificationMessage} = this.props;

            setNotificationMessage(Labels.you_dont_have_enough_likes);

            return;
        }

        const data = new FormData();
        const file = e.target.files[0];
        const {uploadFolder} = this.props;

        if (file) {
            data.append('file', file, file.name);

            if (uploadFolder) {
                data.append('uploadFolder', uploadFolder);
            }

            const onUploadProgress = (e) => {
                const fileLoaded = e.loaded / e.total * 100;

                this.setState({
                    fileLoaded
                });
            };

            uploadFile(data, onUploadProgress)
                .then((response) => {
                    const {data} = response;
                    const uploadedFile = data.file;
                    const fileType = data.type;

                    this.prepareAndSendMessage(uploadedFile, fileType);

                    setTimeout(() => {
                        this.setState({
                            fileLoaded: 0
                        });
                    }, 1000);
                }, (error) => {
                    console.error(error);
                });
        }
    };

    render() {
        const {message, openEmoji, fileLoaded, disableInputMessage, numberOfChars} = this.state;

        let {
            attachment,
            userAllowed,
            buttonsColor,
            icons,
            contentColor,
            showJewelCount,
            attachmentInputId,
            showLeaveOfRoomButton
        } = this.props;

        if (userAllowed === undefined) {
            userAllowed = true;
        }

        return (
            <div
                className="message-input"
                style={{
                    background: contentColor ? contentColor : ''
                }}
            >
                <div className='upload-file-progress' style={{
                    width: Math.round(fileLoaded) + '%'
                }}/>
                {
                    attachment && (
                        <label
                            htmlFor={attachmentInputId}
                            className="leave-room btn btn-dark btn-sm"
                            title={Labels.share}
                            style={{
                                background: buttonsColor,
                                borderColor: buttonsColor
                            }}
                        >
                            <FaShareAlt/>
                            <input
                                type="file"
                                name={attachmentInputId}
                                id={attachmentInputId}
                                className="form-control"
                                style={{display: 'none'}}
                                onChange={this.handleUploadFile}
                            />
                        </label>
                    )
                }
                {
                    showLeaveOfRoomButton && (
                        <button
                            className="leave-room btn btn-dark btn-sm"
                            title={Labels.leave_room}
                            onClick={this.handleLeaveRoomClick}
                            style={{
                                background: buttonsColor,
                                borderColor: buttonsColor
                            }}
                        >
                            <FaSignOutAlt/>
                        </button>
                    )
                }
                <div className={`emoji-icon-wrapper ${((!userAllowed || disableInputMessage) && attachmentInputId !== Constants.attachments_ids.wall) ? 'disabled' : ''}`}
                     onClick={this.handleEmojiClick}>
                    <img src={emoji} alt="Emoji" className="emoji-icon img-responsive"/>
                </div>
                <div ref={this.emojiRef} className={`emojis${openEmoji ? ' active' : ''}`}>
                    <div className="triangle">
                        <div className="inner-one"/>
                    </div>
                    <div className="emojis-container">
                        {
                            icons.map((icon) => {
                                return (
                                    <div
                                        key={icon.id}
                                        className="emoji"
                                        onClick={() => {
                                            this.emojiClicked(icon)
                                        }}
                                    >
                                        <img
                                            src={Config.image_path + Constants.smiles_images + icon.word}
                                            alt={icon.word}
                                            className="img-responsive"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <form className="send-message-form">
                    {
                        showJewelCount && (
                            <i className="jewel-count">{numberOfChars - message.length - 1}</i>
                        )
                    }
                    <textarea ref={this.messageInputRef}
                              name="messageContent"
                              id="messageContent"
                              className={`form-control message-input-text-field${showJewelCount ? ' padding-right' : ''}`}
                              value={message}
                              disabled={!userAllowed || disableInputMessage}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown}
                              placeholder={Labels.write_your_message_here}
                    />
                    <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        onClick={this.handleSubmit}
                        disabled={!userAllowed || disableInputMessage}
                        style={{
                            background: buttonsColor,
                            borderColor: buttonsColor
                        }}
                    >
                        <FaRegPaperPlane/>
                    </button>
                </form>
            </div>
        );
    }
}

export default MessageInput;
