import React, {Component} from 'react';
import io from 'socket.io-client';
import ChatContainer from './components/chats/ChatContainer'
import HomeLogin from './components/HomeLogin'
import AdminPanel from './components/admin/AdminPanel';
import {Helmet} from "react-helmet";
import Events from './events';
import {
    clearLocalStorageById,
    convertDecimalToHex,
    getFingerPrint,
    getUrlParam,
    setStyleAttribute,
    userPermissions
} from './functions';
import {getIpCountry, getSiteSettings, setChatSettings} from './apis/chat';
import {setAuthorizationToken} from "./axios";
import Constants from "./constants";
import _ from 'lodash';
import Spinner from "./components/functional/Spinner";
import {getUserInfo, logoutAPI} from "./apis/auth";
import Config from "./config";
import Labels from "./labels";
import Fingerprint2 from 'fingerprintjs2';
import {getBlockedDevices} from "./apis/admin/block";
import defaultImage from './assets/images/default.png';
import {addRecord} from "./apis/admin/record";
import './assets/sass/App.scss';
import axios from "axios";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            socket: null,
            user: null,
            isAdminPanel: false,
            connectedUsers: [],
            siteSettings: {},
            templateColor: '',
            buttonsColor: '',
            contentColor: '',
            loading: false,
            dontConfirmOnReload: true,
            blockedDevices: []
        };
    }

    componentWillMount() {
        const socket = io(Config[process.env.NODE_ENV].client_socket_port);

        socket.on('connect', () => {
            socket.emit(Events.GET_CONNECTED_USERS, (connectedUsers) => {
                this.setConnectedUsers(connectedUsers);
            });

            console.log("Connected from client side.");
        });

        this.setState({
            socket
        });

        socket.on(Events.CONNECTED_USERS_UPDATED, (connectedUsers) => {
            this.setState({
                connectedUsers: _.values(connectedUsers)
            });
        });

        socket.on(Events.USER_CONNECTED, (connectedUsers) => {
            this.setConnectedUsers(connectedUsers);
        });

        socket.on(Events.SITE_SETTINGS_UPDATED, (updatedSiteSettings) => {
            this.setState({
                siteSettings: updatedSiteSettings
            });
        });

        socket.on(Events.UPDATE_THE_USER, (updatedUser) => {
            updatedUser = updatedUser || {};

            const updatedUserGroups = updatedUser.groups || [];
            const updatedUserGroup = updatedUserGroups[0] || {};

            if (updatedUserGroup.permissions) {
                localStorage.setItem('userPermissions_' + updatedUser.id, JSON.stringify(updatedUserGroup.permissions));
            }

            this.setState({
                user: updatedUser
            });

            localStorage.setItem('user_' + updatedUser.id, JSON.stringify(updatedUser));
        });

        socket.on(Events.USER_EXPULSION, () => {
            this.setConfirmOnReloadStatus(true);
            this.logout();
        });

        socket.on('reconnect', () => {
            this.setConfirmOnReloadStatus(true);
            this.logout();
        });

        window.addEventListener("beforeunload", (event) => {
            const idParam = getUrlParam('id');
            const {user, dontConfirmOnReload} = this.state;

            if (!idParam && !dontConfirmOnReload) {
                event.returnValue = "Write something clever here..";
            }

            if (user) {
                const chatUser = user.chatuser || {};

                localStorage.setItem('last', user.id);
                localStorage.setItem('last_chat_user', chatUser.id);

                sessionStorage.setItem('userId', user.id);
            }
        });

        window.addEventListener('load', () => {
            const idParam = getUrlParam('id');

            if (!idParam) {
                clearLocalStorageById();
            }
        });

        window.addEventListener('resize', () => {
            setStyleAttribute('wrapper', 'height', window.innerHeight);
        });
    }

    componentDidMount() {
        setStyleAttribute('wrapper', 'height', window.innerHeight);

        this.setState({
            loading: true
        });

        const idParam = getUrlParam('id');

        if (idParam) {
            const loggedInUserJson = localStorage.getItem('user_' + idParam) || '{}';
            const loggedInUser = JSON.parse(loggedInUserJson);
            const controlPanelPermission = Constants.permissions.control_panel;
            const hasAccessToAdminPanel = !!loggedInUser.id && userPermissions(controlPanelPermission, idParam);

            this.setState({
                isAdminPanel: hasAccessToAdminPanel,
                user: loggedInUser.id ? loggedInUser : null
            });

            if (hasAccessToAdminPanel) {
                const token = localStorage.getItem('token_' + idParam);

                setAuthorizationToken(token, idParam);
            }
        }

        const tokenParam = getUrlParam('token');
        const hiddenLoginParam = getUrlParam('hiddenLogin');
        const promises = [getSiteSettings(), getIpCountry()];

        if (tokenParam) {
            setAuthorizationToken(tokenParam);

            window.history.replaceState({}, document.title, "/");

            promises.push(getUserInfo(tokenParam));
        }

        axios.all(promises).then(axios.spread((...response) => {
            const siteSettings = response[0] && response[0].data;
            const ipCountry = response[1] && response[1].data;
            const userInfo = response[2] && response[2].data;

            if (siteSettings) {
                document.title = siteSettings.url;
                window.defaultUserImage = Config.image_path + siteSettings.default_user_image_url || defaultImage;
                window.defaultRoomImage = Config.image_path + siteSettings.default_room_image_url || defaultImage;

                this.setState({
                    siteSettings,
                    buttonsColor: convertDecimalToHex(siteSettings.button_color) || '#333333',
                    templateColor: convertDecimalToHex(siteSettings.template_color) || '#333333',
                    contentColor: convertDecimalToHex(siteSettings.content_color) || '#333333',
                });
            }

            if (ipCountry) {
                const country = ipCountry.country;
                const ip = ipCountry.ip;

                localStorage.setItem('_country', country);
                localStorage.setItem('_ip', ip);
            }

            if (userInfo) {
                const {socket} = this.state;
                const user = userInfo.user;

                setAuthorizationToken(tokenParam, user.id);

                addRecord(user, Constants.register_login_from_out);

                socket.emit(Events.VERIFY_USER, user, !!hiddenLoginParam, window.fc, window.ua, this.setUser);
            }

            this.setState({
                loading: false
            });
        }), (error) => {
            console.error(error);

            this.setState({
                loading: false
            });
        })

        if (window.requestIdleCallback) {
            window.requestIdleCallback(() => {
                this.getFingerPrintCode();
            });
        } else {
            setTimeout(() => {
                this.getFingerPrintCode();
            }, 500);
        }
    }

    getFingerPrintCode = () => {
        Fingerprint2.getPromise().then((components) => {
            const fingerPrint = getFingerPrint(components);

            window.fc = fingerPrint['fingerCode'];
            window.ua = fingerPrint['userAgent'];

            getBlockedDevices(fingerPrint['fingerCode'])
                .then((response) => {
                    const results = response.data.results;

                    this.setState({
                        blockedDevices: results
                    });

                }, (error) => {
                    console.error(error);
                })
        });
    };

    setConnectedUsers = (connectedUsers) => {
        this.setState({
            connectedUsers: _.values(connectedUsers)
        });
    };

    setUser = (user, isAlreadyConnected) => {
        if (!isAlreadyConnected) {
            this.setState({
                user
            });

            this.setConfirmOnReloadStatus(false);

            localStorage.setItem('user_' + user.id, JSON.stringify(user));
            localStorage.setItem('userPermissions_' + user.id, JSON.stringify(user.groups[0].permissions || []));

            const {socket} = this.state;

            socket.emit(Events.USER_CONNECTED, user);

            return;
        }

        alert(Labels.this_user_already_connected);
    };

    setUserState = (user, callback) => {
        this.setState({
            user
        }, () => {
            if (callback) {
                callback();
            }
        });
    };

    setConfirmOnReloadStatus = (status) => {
        this.setState({
            dontConfirmOnReload: status
        });
    };

    isUserConnected = (username) => {
        const {connectedUsers} = this.state;

        return !!_.find(connectedUsers, (connectedUser) => {
            return connectedUser.username === username;
        });
    };

    logout = () => {
        const {user} = this.state;

        if (!user) {
            return;
        }

        (async () => {
            const chatUser = user.chatuser;
            const data = {
                enable_private_chat: true,
                enable_notification: true
            };

            await setChatSettings(chatUser.id, data)
                .then(() => {
                }, (error) => {
                    console.error(error);
                });

            logoutAPI()
                .then(() => {
                    const {socket} = this.state;

                    socket.emit(Events.LOGOUT, user.username, () => {
                        this.setState({
                            user: null
                        });

                        this.setConfirmOnReloadStatus(true);

                        clearLocalStorageById(user.id);
                        window.location.reload();
                    });
                }, (error) => {
                    console.error(error);
                });
        })();
    };

    checkIfDeviceBlocked = () => {
        const {blockedDevices} = this.state;

        return _.findIndex(blockedDevices, (blockedDevice) => {
            return blockedDevice.device_code === window.fc;
        });
    };

    componentWillUnmount() {
        window.removeEventListener('beforeunload', () => {
        });
        window.removeEventListener('load', () => {
        });
    }

    render() {
        const {
            user, socket, isAdminPanel,
            connectedUsers, siteSettings,
            buttonsColor, templateColor,
            contentColor, loading
        } = this.state;

        const chatUser = user ? user.chatuser || {} : {};
        const chatUserSettings = chatUser.chat_user_settings || {};

        return (
            <div id="wrapper" className={`${chatUserSettings.font_name}`}>
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <Helmet>
                    <meta name="description" content={siteSettings.description}/>
                    <meta property="og:title" content={siteSettings.url}/>
                    <meta property="og:description" content={siteSettings.description}/>
                    <meta name="keywords" content={siteSettings.tags}/>
                </Helmet>
                {
                    !user && (
                        <HomeLogin
                            socket={socket}
                            setUser={this.setUser}
                            connectedUsers={connectedUsers}
                            siteSettings={siteSettings}
                            buttonsColor={buttonsColor}
                            checkIfDeviceBlocked={this.checkIfDeviceBlocked}
                        />
                    )
                }
                {
                    user && !isAdminPanel && (
                        <ChatContainer
                            user={user}
                            socket={socket}
                            connectedUsers={connectedUsers}
                            logout={this.logout}
                            buttonsColor={buttonsColor}
                            templateColor={templateColor}
                            contentColor={contentColor}
                            siteSettings={siteSettings}
                            setUserState={this.setUserState}
                            isUserConnected={this.isUserConnected}
                        />
                    )
                }
                {
                    user && isAdminPanel && (
                        <AdminPanel
                            user={user}
                            socket={socket}
                        />
                    )
                }
            </div>
        );
    }
}

export default App;
