import React, {Component} from 'react';
import {FaTimes, FaImage} from "react-icons/fa";
import Config from "../../config";
import Labels from '../../labels';
import {getRooms, deleteRoom, updateRoom} from '../../apis/admin/rooms';
import Events from "../../events";
import Spinner from "../functional/Spinner";
import {uploadFile} from "../../apis/chat";
import Constants from "../../constants";
import {addStatus} from "../../apis/admin/statuses";
import '../../assets/sass/AdminRooms.scss';

class AdminRooms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rooms: [],
            loading: false,
            successMessage: '',
            error: ''
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        getRooms()
            .then((response) => {
                const rooms = response.data;

                this.setState({
                    rooms,
                    loading: false
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    }


    handleChangeImage = (e, id) => {
        this.setState({
            loading: true
        });

        const data = new FormData();
        const file = e.target.files[0];

        data.append('file', file, file.name);
        data.append('uploadFolder', Constants.rooms_images);

        uploadFile(data)
            .then((response) => {
                const {data} = response;
                const userImage = data.file;

                updateRoom(id, userImage)
                    .then((response) => {
                        const {user} = this.props;
                        const chatUser = user.chatuser || {};

                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: null,
                            action: Labels.change_room_image,
                            room: null
                        }).then(() => {
                            const updatedRoom = response.data;
                            const {rooms} = this.state;
                            const newRooms = rooms.map((room) => {
                                if (room.id === updatedRoom.id) {
                                    return updatedRoom;
                                }

                                return room;
                            });

                            this.setState({
                                rooms: newRooms,
                                loading: false
                            })
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    })
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    deleteRoomFunc = (id) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_room);

        if (confirm) {
            this.setState({
                loading: true
            });

            deleteRoom(id)
                .then(() => {
                    const {user} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.remove_room,
                        room: null
                    }).then(() => {
                        const {socket} = this.props;

                        socket.emit(Events.DELETE_ROOM, id);

                        const {rooms} = this.state;
                        const newRooms = rooms.filter((room) => {
                            return parseInt(room.id) !== parseInt(id);
                        });

                        this.setState({
                            rooms: newRooms,
                            successMessage: Labels.room_deletedSuccessfully,
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    render() {
        const {rooms, successMessage, error, loading} = this.state;

        return (
            <div className="admin-rooms">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="admin-room-content">
                    {
                        successMessage && (
                            <div className="alert alert-success">
                                {successMessage}
                            </div>
                        )
                    }
                    {
                        error && (
                            <div className="alert alert-danger">
                                {error}
                            </div>
                        )
                    }
                    <table>
                        <thead>
                        <tr>
                            <th>{Labels.rooms_1}</th>
                            <th>{Labels.room_owner}</th>
                            <th>{Labels.settings}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rooms.length > 0 && (
                                rooms.map((room) => {
                                    let roomUser = room.owner_user;
                                    let roomOwnerNickName = roomUser ? roomUser.nick_name : Constants.admin;
                                    let imageUrl = window.defaultRoomImage;

                                    if (room.image) {
                                        imageUrl = Config.image_path + Constants.rooms_images + room.image
                                    }

                                    return (
                                        <tr key={room.id}>
                                            <td>{room.nick_name}</td>
                                            <td>
                                                {roomOwnerNickName}
                                            </td>
                                            <td className="setting-cell">
                                                <div className="image">
                                                    {
                                                        <img src={imageUrl}
                                                             alt={room.nick_name}
                                                             className="img-responsive"
                                                        />
                                                    }
                                                </div>
                                                <label
                                                    htmlFor={`roomImage-${room.id}`}
                                                    className='btn btn-sm btn-info btn-change-image'
                                                >
                                                    <input
                                                        type="file"
                                                        id={`roomImage-${room.id}`}
                                                        className="form-control"
                                                        name="roomImage"
                                                        onChange={(e) => {
                                                            this.handleChangeImage(e, room.id)
                                                        }}
                                                    />
                                                    <FaImage/>
                                                </label>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        this.deleteRoomFunc(room.id)
                                                    }}
                                                >
                                                    <FaTimes/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default AdminRooms;
