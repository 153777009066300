import {axiosInstance} from '../../axios';
import Constants from "../../constants";

export const getFilterWords = () => {
    return axiosInstance
        .get('admin/words')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addFilterWord = (word, category) => {
    const data = {
        word,
        category
    };

    return axiosInstance
        .post('admin/words', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteFilterWord = (wordId) => {
    return axiosInstance
        .delete('admin/words/' + wordId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addMessageToFilter = (message, toUser, word, room) => {
    const fromUserId = message.user.chatuser.id;
    const type = message.type;
    const payload = {
        message: message.message,
        words: [word.word]
    };

    let toUserId = null;
    let url = null;

    if (type === Constants.message_types.private_messages) {
        toUserId = toUser.chatuser.id;
        url = 'chat_users/' + fromUserId + '/to_users/' + toUserId;
    } else {
        url = 'chat_users/' + fromUserId + '/rooms/' + room.id;
    }

    return axiosInstance
        .put(url, payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getFilterWordsMessages = (category) => {
    return axiosInstance
        .get('admin/words/messages?limit=1000&category=' + category)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
