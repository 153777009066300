import React, {Component} from 'react';
import Config from "../config";
import {FaTimes} from "react-icons/all";
import Labels from "../labels";
import {deleteShortcut} from "../apis/admin/shortcuts";
import {addStatus} from "../apis/admin/statuses";
import '../assets/sass/IconsListing.scss';

/**
 * This component for listing and remove icons with/without type.
 */
class IconsListing extends Component {
    removeIcon = (id) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_shortcut);

        if (confirm) {
            const {onRemoveIcon} = this.props;

            if (onRemoveIcon) {
                onRemoveIcon();
            }

            deleteShortcut(id)
                .then((response) => {
                    const {user, type} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.icons_types[type] + Labels.removing,
                        room: null
                    }).then(() => {
                        const {handleRemoveIconSuccess} = this.props;

                        if (handleRemoveIconSuccess) {
                            handleRemoveIconSuccess(response, id);
                        }
                    }, (error) => {
                        console.error(error);
                    });
                }, (error) => {
                    const {handleRemoveIconFailed} = this.props;

                    if (handleRemoveIconFailed) {
                        handleRemoveIconFailed(error);
                    }
                });
        }
    };

    render() {
        let {icons, type, typeIndex, uploadFolder} = this.props;
        let imagePath = Config.image_path;

        if (uploadFolder) {
            imagePath = imagePath + uploadFolder;
        }

        return (
            <div className="icons-listing">
                {
                    icons.map((icon) => {
                        return (
                            icon[typeIndex] === type && (
                                <div key={icon.id} className="icon">
                                    <div className="image">
                                        <img
                                            src={imagePath + icon.word}
                                            alt={icon.word}
                                            className="img-responsive"
                                        />
                                    </div>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => {
                                            this.removeIcon(icon.id)
                                        }}
                                    >
                                        <FaTimes/>
                                    </button>
                                </div>
                            )
                        )
                    })
                }
            </div>
        );
    }
}

export default IconsListing;