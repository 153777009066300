import React, {Component} from 'react';
import {FaCheck, FaTimes} from "react-icons/fa";
import axios from 'axios';
import Labels from '../../labels';
import _ from 'lodash';
import {getPermissions, getShortcuts} from '../../apis/chat';
import {
    getPermissionsGroups,
    createPermissionsGroup,
    addPermissionsLimitsToGroup,
    addPermissionsToGroup,
    removePermissionsGroup,
    updatePermissionsGroup
} from '../../apis/admin/permissions';
import {filterPermissionsGroups, userPermissions} from "../../functions";
import Spinner from "../functional/Spinner";
import Config from "../../config";
import Constants from "../../constants";
import {addStatus} from "../../apis/admin/statuses";
import '../../assets/sass/Permissions.scss';

class Permissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            originalGroups: [],
            groupName: '',
            groupSort: 9000,
            groupIcon: '',
            selectedGroup: {},
            permissions: null,
            supersIcons: [],
            activeSuperIcon: {},
            success: '',
            error: '',
            loading: false
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        axios.all([
            getPermissionsGroups(),
            getShortcuts()
        ]).then(axios.spread((permissionsGroupsResponse, shortcutsResponse) => {
            const {user} = this.props;

            let groups = filterPermissionsGroups(permissionsGroupsResponse.data.results || [], user);

            groups.push({
                id: -9,
                name: Labels.add_new_permission
            });

            const selectedGroup = groups[0];
            const shortcuts = shortcutsResponse.data.results;

            const supersIcons = shortcuts.filter((shortcut) => {
                if (shortcut.shortcut_type === Constants.shortcut_types.super_icon) {
                    let newShortCut = shortcut;

                    newShortCut.active = false;

                    return newShortCut;
                }

                return undefined;
            });

            let {groupName, groupIcon, groupSort} = this.state;

            if (selectedGroup.id !== -9) {
                groupName = selectedGroup.name;
                groupIcon = selectedGroup.group_info.icon || '';
                groupSort = selectedGroup.group_info.order;
            }

            this.setState({
                groups,
                originalGroups: groups,
                selectedGroup,
                groupName,
                groupIcon,
                groupSort,
                supersIcons,
                loading: false
            }, () => {
                this.resetPermissions();
            });
        }, (errors) => {
            console.error(errors);
        }));
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    permissionChanged = (e, permissionId) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {permissions} = this.state;

        let newPermissions = _.cloneDeep(permissions);

        for (let key in newPermissions) {
            if (!newPermissions.hasOwnProperty(key)) {
                continue;
            }

            if (permissionId === key) {
                let permission = newPermissions[key];

                permission.value = value;
                break;
            }
        }

        this.setState({
            permissions: newPermissions
        });
    };

    addUpdateGroupPermissions = (group, task) => {
        let limits = [];
        let {groups, permissions, originalGroups} = this.state;
        let fields = {
            remove: [],
            add: []
        };

        for (let key in permissions) {
            if (!permissions.hasOwnProperty(key)) {
                continue;
            }

            const permission = permissions[key];

            if (permission && permission.type) {
                if (permission.type === 'boolean') {
                    if (permission.value) {
                        fields.add.push(key);
                    } else {
                        fields.remove.push(key);
                    }
                } else {
                    if (permission.value > 0) {
                        fields.add.push(key);

                        limits.push({
                            id: key,
                            limit: parseInt(permission.value)
                        });
                    } else {
                        fields.remove.push(key);
                    }
                }
            }
        }

        axios.all([
            addPermissionsToGroup(group.id, fields),
            addPermissionsLimitsToGroup(group.id, limits)
        ]).then(axios.spread((permissionsResponse, permissionsLimitsResponse) => {
            const {user} = this.props;
            const chatUser = user.chatuser || {};

            addStatus({
                chatUserId: chatUser.id,
                toChatUserId: null,
                action: task === Constants.tasks.create ? Labels.create_group : Labels.update_group,
                room: null
            }).then(() => {
                const newGroupWithPermissions = permissionsLimitsResponse.data;

                if (task === Constants.tasks.create) {
                    groups.push(newGroupWithPermissions);
                }

                if (task === Constants.tasks.update) {
                    groups = groups.map((group) => {
                        if (group.id === newGroupWithPermissions.id) {
                            return newGroupWithPermissions;
                        }

                        return group;
                    });

                    originalGroups = originalGroups.map((group) => {
                        if (group.id === newGroupWithPermissions.id) {
                            return newGroupWithPermissions;
                        }

                        return group;
                    });
                }

                this.setState({
                    groups,
                    originalGroups,
                    selectedGroup: newGroupWithPermissions,
                    loading: false
                }, () => {
                    this.resetPermissions();
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
        }, (errors) => {
            console.error(errors);

            this.setState({
                loading: false
            });
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            error: '',
            success: '',
            loading: true
        });

        const {groupName, groupSort, groupIcon, originalGroups} = this.state;

        if (!groupName.trim()) {
            this.setError(Labels.set_name_is_required);

            return;
        }

        const alreadyExist = originalGroups.filter((group) => {
            return group.name === groupName;
        })[0];

        if (alreadyExist) {
            updatePermissionsGroup(groupName, groupSort, groupIcon, alreadyExist.id)
                .then((response) => {
                    const updatedGroup = response.data;

                    this.addUpdateGroupPermissions(updatedGroup, Constants.tasks.update);
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        } else {
            createPermissionsGroup(groupName, groupSort, groupIcon)
                .then((response) => {
                    const createdGroup = response.data;

                    this.addUpdateGroupPermissions(createdGroup, Constants.tasks.create);
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }

        this.setState({
            success: Labels.saved_successfully
        });
    };

    getPermissionValue = (permission) => {
        const {selectedGroup} = this.state;

        let permissionValue = '';

        if (selectedGroup.id === -9) {
            permissionValue = permission.type === 'boolean' ? false : 1;
        } else {
            const groupPermissions = selectedGroup.permissions || [];
            const groupPermissionsLimits = selectedGroup.permissions_limit || [];
            const groupPermission = groupPermissions.filter((item) => {
                return item.id === permission.id;
            })[0];

            if (groupPermission) {
                if (groupPermission.type === 'boolean') {
                    permissionValue = true;
                } else {
                    const groupPermissionLimit = groupPermissionsLimits.filter((item) => {
                        return item.permission_id === groupPermission.id;
                    })[0] || {};

                    permissionValue = groupPermissionLimit.limit || 0;
                }
            } else {
                if (permission.type === 'boolean') {
                    permissionValue = false;
                } else {
                    permissionValue = '0';
                }
            }
        }

        return permissionValue;
    };

    resetPermissions = () => {
        const {permissions} = this.state;

        if (!permissions) {
            getPermissions()
                .then((response) => {
                    const permissionsArr = response.data;

                    let permissions = {};

                    for (let i = 0; i < permissionsArr.length; i++) {
                        const permission = permissionsArr[i];
                        const permissionValue = this.getPermissionValue(permission);

                        permissions[permission.id] = {
                            code: permission.codename,
                            value: permissionValue,
                            type: permission.type,
                            id: permission.id
                        }
                    }

                    this.setState({
                        permissions
                    });
                }, (error) => {
                    console.error(error);
                });
        } else {
            for (let key in permissions) {
                if (!permissions.hasOwnProperty(key)) {
                    continue;
                }

                const permission = permissions[key];
                const permissionValue = this.getPermissionValue(permission);

                permissions[key] = {
                    code: permission.code,
                    value: permissionValue,
                    type: permission.type,
                    id: key * 1
                };

                this.setState({
                    permissions
                });
            }
        }
    };

    onGroupChanged = (e) => {
        const id = e.target.value;
        const {groups} = this.state;
        const selectedGroup = groups.filter((group) => {
            return group.id === id * 1;
        })[0];

        let {groupName, groupIcon, groupSort} = this.state;

        if (selectedGroup.id === -9) {
            groupName = '';
            groupIcon = '';
            groupSort = 9000;
        } else {
            groupName = selectedGroup.name;
            groupIcon = selectedGroup.group_info.icon || '';
            groupSort = selectedGroup.group_info.order;
        }

        this.setState({
            selectedGroup: selectedGroup,
            groupName,
            groupIcon,
            groupSort
        }, () => {
            this.resetPermissions()
        });
    };

    removeGroup = () => {
        const {selectedGroup} = this.state;

        if (selectedGroup.id !== -9) {
            const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_permissions_group);

            this.setState({
                loading: true
            });

            if (confirm) {
                removePermissionsGroup(selectedGroup.id)
                    .then(() => {
                        const {user} = this.props;
                        const chatUser = user.chatuser || {};

                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: null,
                            action: Labels.remove_group,
                            room: null
                        }).then(() => {
                            let {groups, groupName, groupIcon, groupSort} = this.state;

                            const newGroups = groups.filter((group) => {
                                return group.id !== selectedGroup.id;
                            });
                            const newSelectedGroup = newGroups[0];

                            if (newSelectedGroup.id === -9) {
                                groupName = '';
                                groupIcon = '';
                                groupSort = 9000;
                            } else {
                                groupName = newSelectedGroup.name;
                                groupIcon = newSelectedGroup.group_info.icon || '';
                                groupSort = newSelectedGroup.group_info.order;
                            }

                            this.setState({
                                groups: newGroups,
                                selectedGroup: newSelectedGroup,
                                groupName,
                                groupIcon,
                                groupSort,
                                loading: false
                            }, () => {
                                this.resetPermissions()
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
            }
        }
    };

    superIconClicked = (icon) => {
        this.setState({
            activeSuperIcon: icon,
            groupIcon: icon.word
        });
    };

    setError = (error) => {
        this.setState({
            error
        });
    };

    render() {
        const {
            groups, groupName, groupSort, supersIcons,
            activeSuperIcon, groupIcon, permissions,
            success, error, selectedGroup, loading
        } = this.state;

        const {user} = this.props;
        const userGroups = user.groups;
        const userGroup = userGroups[0] || {};
        const userGroupInfo = userGroup.group_info || {};

        return (
            <div className="permissions">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="permissions-content">
                    <div className="add-permission">
                        <select
                            name="permissionsList"
                            id="permissionsList"
                            className="form-control"
                            onChange={this.onGroupChanged}
                            value={selectedGroup.id}
                        >
                            {
                                groups.length > 0 && (
                                    groups.map((group) => {
                                        return (
                                            <option
                                                key={group.id}
                                                value={group.id}
                                            >
                                                {group.name}
                                            </option>
                                        )
                                    })
                                )
                            }
                        </select>
                        <button className="btn btn-danger btn-sm" onClick={this.removeGroup}
                                disabled={selectedGroup && selectedGroup.id === -9}>
                            <FaTimes/>
                            {Labels.remove}
                        </button>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        {
                            success && (
                                <div className="alert alert-success">
                                    {success}
                                </div>
                            )
                        }
                        {
                            error && (
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            )
                        }
                        <div className="form-group">
                            <label htmlFor="groupSort">
                                {Labels.sorting}
                            </label>
                            <input
                                type="number"
                                name="groupSort"
                                id="groupSort"
                                className="form-control fixed-width"
                                onChange={this.handleChange}
                                value={groupSort}
                                min={10}
                                max={9000}
                            />
                        </div>
                        <div className="form-group flex-column">
                            <label htmlFor="groupName">
                                {Labels.set_name}
                            </label>
                            <input
                                type="text"
                                value={groupName}
                                id="groupName"
                                className="form-control"
                                name="groupName"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group flex-column">
                            <label htmlFor="groupIcon">
                                {Labels.icon}
                            </label>
                            <input
                                type="text"
                                value={groupIcon}
                                id="groupIcon"
                                className="form-control"
                                name="groupIcon"
                                onChange={this.handleChange}
                            />
                        </div>
                        {
                            permissions && (
                                Object.keys(permissions).map((key) => {
                                    let permission = permissions[key];

                                    return (
                                        <div className="form-group" key={key}>
                                            <label htmlFor={permission.code}>{Labels[permission.code]}</label>
                                            <input
                                                type={permission.type === 'boolean' ? 'checkbox' : 'number'}
                                                id={permission.code}
                                                value={permission.value}
                                                name={permission.code}
                                                className={permission.type !== 'boolean' ? 'form-control fixed-width' : ''}
                                                onChange={(e) => {
                                                    this.permissionChanged(e, key)
                                                }}
                                                min={0}
                                                checked={permission.value}
                                                disabled={!userPermissions(permission.code, user.id) || userGroupInfo.order < groupSort}
                                            />
                                        </div>
                                    )

                                })
                            )
                        }

                        <br/>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-success btn-save"
                            >
                                <FaCheck/>
                                {Labels.save}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="icons">
                    {
                        supersIcons.map((icon) => {
                            return (
                                <div
                                    key={icon.id}
                                    className={`icon${activeSuperIcon.id === icon.id ? ' active' : ''}`}
                                    onClick={() => {
                                        this.superIconClicked(icon)
                                    }}
                                >
                                    <div className="image">
                                        <img src={Config.image_path + Constants.super_images + icon.word}
                                             alt=""
                                             className="img-responsive"
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Permissions;
