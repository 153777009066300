import React, {Component} from 'react';
import ListingUsers from '../ListingUsers';
import Labels from '../../labels';
import _ from 'lodash';
import '../../assets/sass/Connected.scss';

class Connected extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            searchResults: []
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const {connectedUsers} = this.props;
        let searchResults = [];

        if (value) {
            searchResults = _.filter(connectedUsers, (user) => {
                const chatUser = user.chatuser || {};
                const username = chatUser.nick_name || user.username || '';

                return (
                    username.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    username.toLowerCase().indexOf(value.toLowerCase()) > -1
                );
            });
        }

        this.setState({
            [name]: value,
            searchResults
        });
    };

    render() {
        const {
            handleConnectedUsersClick,
            user,
            templateColor,
            buttonsColor,
            connectedUsers,
            activeRoomUsers
        } = this.props;
        const {search, searchResults} = this.state;
        const activeRoomUsersIds = _.map(activeRoomUsers, 'id');
        const usersInRoom = connectedUsers.filter((connectedUser) => {
            return activeRoomUsersIds.indexOf(connectedUser.id) > -1;
        });
        const otherUsers = connectedUsers.filter((connectedUser) => {
            return activeRoomUsersIds.indexOf(connectedUser.id) < 0;
        });

        return (
            <div className="connected-users">
                <div className="connected-users-search-box">
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="text"
                            name="search"
                            className={'form-control'}
                            onChange={this.handleChange}
                            placeholder={Labels.search}
                            value={search}
                            style={{
                                background: templateColor,
                                borderColor: templateColor
                            }}
                        />
                    </form>
                    {
                        search && searchResults.length > 0 && (
                            <ListingUsers
                                users={searchResults}
                                handleConnectedUsersClick={handleConnectedUsersClick}
                                task='CONNECTED'
                                loggedInUser={user}
                                showUserActivityFlag={true}
                            />
                        )
                    }
                </div>
                {
                    usersInRoom.length > 0 && (
                        <div
                            className="head"
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.connected_in_room}
                        </div>
                    )
                }
                {
                    !search && usersInRoom.length > 0 && (
                        <ListingUsers
                            users={usersInRoom}
                            handleConnectedUsersClick={handleConnectedUsersClick}
                            task='CONNECTED'
                            loggedInUser={user}
                            showUserActivityFlag={true}
                        />
                    )
                }
                {
                    otherUsers.length > 0 && (
                        <div
                            className="head"
                            style={{
                                background: buttonsColor
                            }}
                        >
                            {Labels.connected_in_chat}
                        </div>
                    )
                }
                {
                    !search && otherUsers.length > 0 && (
                        <ListingUsers
                            users={otherUsers}
                            handleConnectedUsersClick={handleConnectedUsersClick}
                            task='CONNECTED'
                            loggedInUser={user}
                            showUserActivityFlag={true}
                        />
                    )
                }
            </div>
        );
    }
}

export default Connected;
