import React, {Component} from 'react';
import {FaTimes} from 'react-icons/fa';
import Connected from './Connected';
import PrivateConversations from './PrivateConversations';
import Rooms from './Rooms';
import Wall from './Wall';
import Settings from './Settings';
import Labels from '../../labels';
import Constants from "../../constants";
import '../../assets/sass/Sidebar.scss';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.sideBarRef = React.createRef();
    }

    handleClickOutside = (e) => {
        if (this.sideBarRef && this.sideBarRef.current && !this.sideBarRef.current.contains(e.target)) {
            const {closeSidebar} = this.props;

            closeSidebar();
        }
    };

    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillMount() {
        window.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        let {
            task, closeSidebar, rooms, activeRoom, privateConversations,
            changeActiveRoom, connectedUsers, user, socket, setActiveConversation,
            logout, handleConnectedUsersClick, shortcuts, buttonsColor, icons, mapping, contentColor,
            templateColor, siteSettings, setNotificationMessage, sendMessage,
            userAllowed, wall, wallTitle, wallSubtitle, openWallMessageModal
        } = this.props;

        wallTitle = wallTitle || 'الحائط للابداع فقط';

        return (
            <div ref={this.sideBarRef} className={"sidebar " + (task ? 'active' : '')} id="sidebar">
                <div
                    className="sidebar-header"
                    style={{
                        background: templateColor
                    }}
                >
                    <button
                        type="button"
                        className="btn btn-sm btn-danger digi-btn"
                        onClick={closeSidebar}
                    >
                        <FaTimes/>
                    </button>
                    {
                        task && task === Constants.sidebar_tasks.wall && (
                            wallTitle
                        )
                    }
                    {
                        task && task !== Constants.sidebar_tasks.wall && (
                            Labels[task]
                        )
                    }
                </div>
                <div
                    className={`sidebar-body ${task}`}
                    style={{
                        background: contentColor
                    }}
                >
                    {
                        task === Constants.sidebar_tasks.connected && (
                            <Connected
                                activeRoomUsers={activeRoom ? activeRoom.users : []}
                                connectedUsers={connectedUsers}
                                handleConnectedUsersClick={handleConnectedUsersClick}
                                user={user}
                                templateColor={templateColor}
                                buttonsColor={buttonsColor}
                            />
                        )
                    }
                    {
                        task === Constants.sidebar_tasks.private_conversations && (
                            <PrivateConversations
                                privateConversations={privateConversations}
                                setActiveConversation={setActiveConversation}
                                user={user}
                                closeSidebar={closeSidebar}
                                socket={socket}
                            />
                        )
                    }
                    {
                        task === Constants.sidebar_tasks.rooms && (
                            <Rooms
                                rooms={rooms}
                                user={user}
                                activeRoom={activeRoom}
                                changeActiveRoom={changeActiveRoom}
                                socket={socket}
                                templateColor={templateColor}
                                buttonsColor={buttonsColor}
                                setNotificationMessage={setNotificationMessage}
                                userAllowed={userAllowed}
                            />
                        )
                    }
                        <Wall
                            socket={socket}
                            user={user}
                            shortcuts={shortcuts}
                            buttonsColor={buttonsColor}
                            contentColor={contentColor}
                            icons={icons}
                            mapping={mapping}
                            handleConnectedUsersClick={handleConnectedUsersClick}
                            task={task}
                            siteSettings={siteSettings}
                            setNotificationMessage={setNotificationMessage}
                            sendMessage={sendMessage}
                            wall={wall}
                            wallSubtitle={wallSubtitle}
                            openWallMessageModal={openWallMessageModal}
                        />
                    {
                        task === Constants.sidebar_tasks.settings && (
                            <Settings
                                socket={socket}
                                user={user}
                                logout={logout}
                                activeRoom={activeRoom}
                                buttonsColor={buttonsColor}
                                setNotificationMessage={setNotificationMessage}
                                sendMessage={sendMessage}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Sidebar;
