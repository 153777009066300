import {axiosInstance} from '../../axios';

export const getStatuses = (limit, offset) => {
    return axiosInstance
        .get('admin/audits?limit=' + limit + '&offset=' + offset)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addStatus = ({chatUserId, toChatUserId = '', action, room = ''}) => {
    const payload = {
        admin: chatUserId,
        chat_user: toChatUserId,
        action,
        room
    };

    return axiosInstance
        .post('admin/audits', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const searchStatuses = (username) => {
    return axiosInstance
        .get('admin/audits?nick_name=' + username + '&admin_nick_name=' + username)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};