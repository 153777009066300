import React, {Component} from 'react';
import Labels from "../../labels";
import Constants from "../../constants";
import {convertDecimalToHex, createMarkup, getMessageTime, userPermissions} from "../../functions";
import {FaComment, FaSignOutAlt} from "react-icons/all";
import MediaCover from "../MediaCover";
import Config from "../../config";
import {FaTimes} from "react-icons/fa";

class Message extends Component {

    handleClick = (message) => {
        const {callback} = this.props;

        if (callback) {
            callback(message);
        }
    };

    render() {
        const {messages, changeActiveRoom, buttonsColor, deleteMessageCallback, user} = this.props;

        return (
            <span>
                {
                    messages && messages.length > 0 && messages.map(message => {
                        const messageTime = getMessageTime(message);
                        const messageUser = message.user || {};
                        const userGroups = messageUser.groups || [];
                        const userGroup = userGroups[0] || {};
                        const userGroupInfo = userGroup.group_info || {};
                        const chatUser = messageUser.chatuser || {};
                        const chatUserSettings = chatUser.chat_user_settings || {};

                        const mediaTypes = [
                            Constants.media.image,
                            Constants.media.video,
                            Constants.media.record,
                            Constants.media.youtube
                        ];

                        const messageTypes = [
                            Constants.message_types.entrance,
                            Constants.message_types.leave,
                            Constants.message_types.welcome,
                            Constants.message_types.daily,
                            Constants.message_types.logout
                        ];

                        const messagesTypes1 = [
                            Constants.message_types.entrance,
                            Constants.message_types.welcome,
                            Constants.message_types.leave,
                            Constants.message_types.private_messages,
                            Constants.message_types.daily
                        ];

                        const entranceLeaveTypes = [
                            Constants.message_types.leave,
                            Constants.message_types.entrance
                        ];

                        const messagesTypes2 = [
                            Constants.message_types.welcome,
                            Constants.message_types.logout,
                            Constants.message_types.daily,
                        ];

                        const entranceLeaveCondition = message.type && entranceLeaveTypes.indexOf(message.type) > -1;

                        let messageClasses = [];

                        if (message.type && messageTypes.indexOf(message.type) > -1) {
                            messageClasses.push('highlighted-message')
                        }

                        if (entranceLeaveCondition) {
                            messageClasses.push('entrance-leave-message')
                        }

                        let imageUrl = window.defaultUserImage;
                        let roomImageUrl = window.defaultRoomImage;

                        if (chatUserSettings.picture_name) {
                            imageUrl = Config.image_path + Constants.avatars + chatUserSettings.picture_name;
                        }

                        if (message.roomImage) {
                            roomImageUrl = Config.image_path + Constants.rooms_images + message.roomImage;
                        }

                        let messageToDisplay = message.message;
                        let messageToDisplayHasSmile = messageToDisplay && messageToDisplay.indexOf('smiley') > -1;

                        if (messageToDisplayHasSmile) {
                            messageToDisplay = messageToDisplay.replace('smiley', '');
                        }

                        return (
                            <span key={message.id}>
                                <div
                                    className={`message ${messageClasses.join(' ')}`}
                                >
                                    {
                                        userPermissions(Constants.permissions.delete_public_messages, user.id) && deleteMessageCallback && messageTypes.indexOf(message.type) < 0 && (
                                            <div
                                                className="delete-message"
                                                onClick={() => {
                                                    deleteMessageCallback(message.id)
                                                }}
                                            >
                                                <FaTimes/>
                                            </div>
                                        )
                                    }
                                    <div className="message-time">
                                        {
                                            messageTime.diff_mins === 0 && (
                                                Labels.now
                                            )
                                        }
                                        {
                                            messageTime.diff_mins > 0 && messageTime.diff_mins < 60 && (
                                                Labels.minute + messageTime.diff_mins
                                            )
                                        }
                                        {
                                            messageTime.diff_mins > 60 && (
                                                Labels.hours + messageTime.diff_hours
                                            )
                                        }
                                    </div>
                                    <div
                                        className={'image-content-wrapper' + (messagesTypes2.indexOf(message.type) > -1 ? '' : ' clicked')}
                                        onClick={() => this.handleClick(message)}
                                    >
                                        {
                                            message.type && [Constants.message_types.private_messages, Constants.message_types.adv].indexOf(message.type) < 0 && (
                                                <img src={roomImageUrl}
                                                     className="img-responsive"
                                                     alt={message.roomName}
                                                />
                                            )
                                        }
                                        {
                                            (!message.type || [Constants.message_types.private_messages, Constants.message_types.adv].indexOf(message.type)) > -1 && (
                                                <img src={imageUrl}
                                                     className="img-responsive"
                                                     alt={message.sender}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="message-user">
                                        <div>
                                            {
                                                message.type && [Constants.message_types.welcome, Constants.message_types.daily].indexOf(message.type) > -1 && (
                                                    <div>
                                                        {message.sender || Labels.welcome_message}
                                                    </div>
                                                )
                                            }
                                            {
                                                (!message.type || (message.type && [Constants.message_types.welcome, Constants.message_types.daily].indexOf(message.type) < 0)) && (
                                                    <div className="user-name username-style">
                                                        {
                                                            message.user.gift && (
                                                                <div className="gift">
                                                                    <img
                                                                        src={Config.image_path + Constants.gifts_images + message.user.gift}
                                                                        alt="message.user.gift"
                                                                        className="img-responsive"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            !messageUser.hideStar && userGroupInfo && userGroupInfo.icon && (
                                                                <span className="user-icon">
                                                                    <img
                                                                        src={Config.image_path + Constants.super_images + userGroupInfo.icon}
                                                                        className="img-responsive"
                                                                        alt=""/>
                                                                </span>
                                                            )
                                                        }
                                                        <span
                                                            className="username-text"
                                                            style={{
                                                                background: convertDecimalToHex(chatUserSettings.background_color),
                                                                color: convertDecimalToHex(chatUserSettings.display_name_color)
                                                            }}
                                                        >
                                                            {chatUser.nick_name || message.sender}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="message-info">
                                            <span
                                                style={{
                                                    color: messagesTypes1.indexOf(message.type) < 0 ? convertDecimalToHex(chatUserSettings.font_color) : ''
                                                }}
                                            >
                                                {
                                                    mediaTypes.indexOf(message.mediaType) > -1 ?
                                                        <MediaCover
                                                            media={message.message}
                                                            type={message.mediaType === Constants.media.youtube ? Constants.media.youtube : message.mediaType}
                                                            image={message.roomImage}
                                                            mediaFolder={Constants.wall_private_media_folder}
                                                        />
                                                        :
                                                        (
                                                            messageToDisplayHasSmile ?
                                                                <div
                                                                    dangerouslySetInnerHTML={createMarkup(messageToDisplay)}
                                                                />
                                                                :
                                                                <div>{messageToDisplay}</div>
                                                        )
                                                }
                                            </span>
                                            {
                                                message.type === Constants.message_types.adv && (
                                                    <span className="adv">
                                                            {Labels.adv}
                                                        <FaComment/>
                                                    </span>
                                                )
                                            }
                                            {
                                                entranceLeaveCondition && (
                                                    <button type="button"
                                                            className="btn btn-sm btn-dark"
                                                            style={{
                                                                borderColor: buttonsColor,
                                                                background: buttonsColor
                                                            }}
                                                            onClick={() => {
                                                                changeActiveRoom(message.roomId)
                                                            }}
                                                    >
                                                        {message.roomName}
                                                        <FaSignOutAlt/>
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </span>
                        );
                    })
                }
            </span>
        );
    }
}

export default Message;