import React, {Component} from 'react';
import axios from 'axios';
import {FaTimes} from "react-icons/fa";
import Labels from '../../labels';
import _ from 'lodash';
import {
    getBlockedIps,
    getBlockedCountries,
    blockIp,
    blockCountry,
    removeBlock,
    getBlockedDevices,
    allowDevice,
    blockDevice
} from '../../apis/admin/block';
import uuidv4 from 'uuid/v4';
import {addStatus} from "../../apis/admin/statuses";
import Spinner from "../functional/Spinner";
import {getDate} from "../../functions";
import '../../assets/sass/Block.scss';

class Block extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blocks: [],
            blockedDevices: [],
            block: '',
            error: '',
            loading: false
        }
    }

    componentDidMount() {
        axios.all([getBlockedIps(), getBlockedCountries(), getBlockedDevices()])
            .then(axios.spread((blockedIpsResponse, blockedCountriesResponse, blockedDevicesResponse) => {
                const blocks = blockedIpsResponse.data.results.concat(blockedCountriesResponse.data.results);
                const blockedDevices = blockedDevicesResponse.data.results;

                blocks.map((block) => {
                    return block.id = uuidv4() + block.ip_address || block.country;
                });

                this.setState({
                    blocks: blocks,
                    blockedDevices: blockedDevices
                })
            }, (errors) => {
                console.error(errors);
            }));
    }

    validateIPaddress = (ipaddress) => {
        const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipaddress.match(ipformat);
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            error: ''
        });

        const {block, blocks, blockedDevices} = this.state;
        const {user} = this.props;
        const chatUser = user.chatuser || {};

        if (block && block.trim()) {
            if (this.validateIPaddress(block)) {
                this.setState({
                    loading: true
                });

                blockIp(block)
                    .then(() => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: null,
                            action: Labels.adding_band_ip,
                            room: null
                        }).then(() => {
                            let newBlocks = _.cloneDeep(blocks);

                            newBlocks.push({
                                ip_address: block,
                                until_date: null,
                                id: uuidv4() + block
                            });

                            this.setState({
                                block: '',
                                blocks: newBlocks,
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
            } else {
                if (block.trim().length === 2) {
                    this.setState({
                        loading: true
                    });

                    blockCountry(block.trim())
                        .then(() => {
                            addStatus({
                                chatUserId: chatUser.id,
                                toChatUserId: null,
                                action: Labels.adding_band_country,
                                room: null
                            }).then(() => {
                                let newBlocks = _.cloneDeep(blocks);

                                newBlocks.push({
                                    country: block,
                                    until_date: null,
                                    id: uuidv4() + block
                                });

                                this.setState({
                                    block: '',
                                    blocks: newBlocks,
                                    loading: false
                                });
                            }, (error) => {
                                console.error(error);

                                this.setState({
                                    loading: false
                                });
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        });
                } else if (block.trim().length === 32){
                    this.setState({
                        loading: true
                    });

                    blockDevice(block.trim()).then((response) =>  {
                        let newBlocks = _.cloneDeep(blockedDevices);

                        newBlocks.push({
                            id: uuidv4() + block,
                            username: "",
                            device_code: block,
                            until_date: "",
                        });

                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: null,
                            action: Labels.adding_band_device,
                            room: null
                        });

                        this.setState({
                            loading: false,
                            blockedDevices: newBlocks,
                            block: '',
                        });
                    }, (error) => {
                        this.setState({
                            loading: false
                        });
                    });
                } else {
                    this.setError(Labels.invalid_country_code_or_ip)
                }
            }
        } else {
            this.setError(Labels.this_field_required);
        }
    };

    removeBlock = (blockInfo, isDevice) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_block);

        if (confirm) {
            this.setState({
                loading: true
            });

            const {user} = this.props;
            const chatUser = user.chatuser || {};

            if (isDevice) {
                allowDevice(blockInfo.device_code)
                    .then(() => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: null,
                            action: Labels.remove_band,
                            room: null
                        }).then(() => {
                            const {blockedDevices} = this.state;
                            const newBlocks = blockedDevices.filter((blockedDevice) => {
                                return blockedDevice.id !== blockInfo.id;
                            });

                            this.setState({
                                blockedDevices: newBlocks,
                                loading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                loading: false
                            });
                        })
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                return;
            }

            removeBlock(blockInfo)
                .then(() => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.remove_band,
                        room: null
                    }).then(() => {
                        const {blocks} = this.state;
                        const newBlocks = blocks.filter((block) => {
                            return block.id !== blockInfo.id;
                        });

                        this.setState({
                            blocks: newBlocks,
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    setError = (error) => {
        this.setState({
            error
        });
    };

    render() {
        const {blocks, block, error, loading, blockedDevices} = this.state;

        return (
            <div className="block">
                {
                    loading && (
                        <Spinner/>
                    )
                }

                <div className="add-block">
                    {
                        error && (
                            <div className="alert alert-danger">{error}</div>
                        )
                    }
                    <form onSubmit={this.handleSubmit}>
                        <input type="text"
                               name="block"
                               className="form-control"
                               placeholder={Labels.add_block_placeholder}
                               value={block}
                               onChange={this.handleChange}
                        />
                        <button className="btn btn-success">
                            {Labels.addison}
                        </button>
                    </form>
                </div>
                <div className="block-content">
                    <div className="blocks">
                        <table>
                            <thead>
                            <tr>
                                <th>{Labels.member}</th>
                                <th>{Labels.block}</th>
                                <th>{Labels.block_date}</th>
                                <th>{Labels.remove}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                blocks.map((block) => {
                                    let blockedMember = block.chat_user || {};
                                    let blockInfo = {};

                                    blockInfo.id = block.id;
                                    blockInfo.member = blockedMember.nick_name || '--';
                                    blockInfo.block = block.ip_address || block.country;
                                    blockInfo.expired_at = getDate(block.created) || '--';
                                    blockInfo.type = block.ip_address ? 'ip' : 'country';

                                    return (
                                        <tr key={blockInfo.id}>
                                            <td>{blockInfo.member}</td>
                                            <td>{blockInfo.block}</td>
                                            <td>{blockInfo.expired_at}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        this.removeBlock(blockInfo)
                                                    }}
                                                >
                                                    <FaTimes/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                blockedDevices.map((blockedDevice) => {
                                    return (
                                        <tr key={blockedDevice.id}>
                                            <td>{blockedDevice.username}</td>
                                            <td>{blockedDevice.device_code}</td>
                                            <td>{blockedDevice.until_date}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        this.removeBlock(blockedDevice, true)
                                                    }}
                                                >
                                                    <FaTimes/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block;
