import React, {Component} from 'react';
import {FaCheck, FaTimes} from "react-icons/fa";
import Labels from '../../labels';
import {getMessages, addMessage, deleteMessage} from '../../apis/admin/messages';
import '../../assets/sass/AdminMessages.scss';
import Constants from "../../constants";
import {addStatus} from "../../apis/admin/statuses";
import Spinner from "../functional/Spinner";

class AdminMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messageTitle: '',
            messageContent: '',
            messages: [],
            error: '',
            success: '',
            loading: false
        };
    }

    componentDidMount() {
        getMessages()
            .then((response) => {
                const messages = response.data.results;

                this.setState({
                    messages
                })
            }, (error) => {
                console.error(error);
            });
    }


    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    };

    addMessage = (category) => {
        this.setState({error: ''});

        const {messageTitle, messageContent} = this.state;

        if (messageContent.trim() && messageTitle.trim()) {
            this.setState({
                loading: true
            });

            addMessage(messageTitle.trim(), category, messageContent.trim())
                .then((response) => {
                    const {user} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.adding_removing_messages[category] + Labels.adding_messages,
                        room: null
                    }).then(() => {
                        const message = response.data;

                        let {messages} = this.state;

                        messages.push(message);

                        this.setState({
                            messages,
                            messageTitle: '',
                            messageContent: '',
                            loading: false
                        })
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        } else {
            this.setError(Labels.all_fields_required);
        }
    };

    removeMessage = (id, category) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_message);

        if (confirm) {
            this.setState({
                loading: true
            });

            deleteMessage(id)
                .then(() => {
                    const {user} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.adding_removing_messages[category] + Labels.removing_messages,
                        room: null
                    }).then(() => {
                        const {messages} = this.state;
                        const newMessages = messages.filter((message) => {
                            return parseInt(message.id) !== parseInt(id);
                        });

                        this.setState({
                            messages: newMessages,
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    setError = (error) => {
        this.setState({
            error
        });
    };

    render() {
        const {
            messageTitle,
            messageContent,
            messages,
            error,
            loading
        } = this.state;

        return (
            <div className="admin-messages">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="admin-messages-content">
                    {
                        error && (
                            <div className="alert alert-danger">{error}</div>
                        )
                    }
                    <div className="form-group">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="messageTitle"
                                placeholder={Labels.messageTitle}
                                onChange={this.handleChange}
                                value={messageTitle}
                            />
                        </div>
                        <textarea
                            name="messageContent"
                            id="messageContent"
                            className="form-control"
                            onChange={this.handleChange}
                            value={messageContent}
                            placeholder={Labels.messageContent}
                        >
                        </textarea>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {this.addMessage(Constants.message_categories.welcome)}}
                        >
                            <FaCheck/>
                            {Labels.add_to_welcome_messages}
                        </button>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-trikwaz"
                            onClick={() => {this.addMessage(Constants.message_categories.daily)}}
                        >
                            <FaCheck/>
                            {Labels.add_to_daily_messages}
                        </button>
                    </div>
                    <div className="form-group">
                        <div>
                            <strong>{Labels.messages}</strong>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th>{Labels.category}</th>
                                <th>{Labels.title}</th>
                                <th>{Labels.message}</th>
                                <th>{Labels.remove}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                messages.length > 0 && (
                                    messages.map((message) => {
                                        return (
                                            <tr key={message.id}>
                                                <td>{Labels[message.category]}</td>
                                                <td>{message.title}</td>
                                                <td>{message.message}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => {
                                                            this.removeMessage(message.id, message.category)
                                                        }}
                                                    >
                                                        <FaTimes/>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminMessages;
