import {axiosInstance} from '../../axios';

export const getMembers = (limit, offset, excludeDefaultGroupUsers) => {
    let url = 'admin/users?limit=10000';

    if (limit) {
        url = 'admin/users?limit=' + limit + '&offset=' + offset;
    }

    if (excludeDefaultGroupUsers) {
        url += '&exclude_default_group_users=true'
    }

    return axiosInstance
        .get(url)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteMember = (userId) => {
    return axiosInstance
        .delete('admin/users/' + userId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const updateUserLikes = (userId, likesNumber) => {
    const payLoad = {
        count: likesNumber
    };

    return axiosInstance
        .put('admin/users/' + userId + '/likes', payLoad)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const searchUsers = (username) => {
    return axiosInstance
        .get('admin/users?user_name=' + username)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};