import React, {Component} from 'react';
import Sidebar from '../sidebar/Sidebar';
import ChatHeading from './ChatHeading';
import MessageInput from '../messaging/MessageInput';
import Messages from '../messaging/Messages';
import ChatActions from './ChatActions'
import Modal from "react-modal";
import UserProfileModal from "../UserProfileModal";
import Notification from "../Notification";
import Events from '../../events';
import Labels from '../../labels';
import _ from 'lodash';
import {
    getRooms,
    getShortcuts,
    getFilterWords,
    getMessages,
    getLoggedInUserLikes,
    getPermissions,
    deleteRoom
} from '../../apis/chat';
import {FaTimes, FaWindowMinimize, FaExpand, FaCompress} from "react-icons/fa";
import {addMessageToFilter} from '../../apis/admin/filter';
import uuidv4 from 'uuid/v4';
import Constants from "../../constants";
import axios from 'axios';
import {
    prepareRooms,
    prepareShortcuts,
    createMessage,
    removeUserFromRoom,
    isHiddenLogin,
    calculateMinutes
} from '../../functions';
import Spinner from "../functional/Spinner";
import Config from "../../config";
import moment from "moment";
import '../../assets/sass/ChatContainer.scss';

Modal.setAppElement('#root');

let dailyMessagesIntervalId;
let cleaningActivePrivateConversationIntervalId;
let monitoringUserActivityIntervalId;
let cleaningWallIntervalId;

class ChatContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rooms: [],
            messages: [],
            activeRoom: {},
            userActiveRoom: {},
            dailyMessages: [],
            welcomeMessages: [],
            unreadWallMessages: 0,
            sidebarTask: '',
            privateConversations: [],
            activePrivateConversation: {},
            unreadConversations: 0,
            isModalOpen: false,
            modalTask: null,
            userModalInfo: null,
            privateMessagesModalExpanded: false,
            words: [],
            shortcuts: [],
            icons: [],
            mapping: {},
            gifts: [],
            notificationMessages: [],
            loggedInUserLikes: 0,
            loading: false,
            canDoLike: true,
            userLastActionDate: moment(),
            wall: [],
            wallCommentsModalIsOpen: false,
            wallMessageModelUser: {},
            wallMessageModalId: ''
        };
    }

    componentWillMount() {
        const {socket} = this.props;

        socket.on(Events.LEAVE_ACTIVE_ROOM, () => {
            this.setState({
                activeRoom: {}
            })
        })

        socket.on(Events.USER_JOINED_ROOM, (roomId, joinedUser) => {
            let {rooms, welcomeMessages} = this.state;

            for (let room of rooms) {
                if (room.id === roomId) {
                    const {user} = this.props;

                    if (joinedUser.id === user.id) {
                        this.setState({
                            activeRoom: room
                        });

                        this.sendMessage({
                            message: Labels.entrance_message,
                            type: Constants.message_types.entrance,
                            user: joinedUser,
                            forUserOnly: true
                        });

                        for (let message of welcomeMessages) {
                            this.sendMessage({
                                message: message.message,
                                type: Constants.message_types.welcome,
                                sender: message.title,
                                forUserOnly: true
                            });
                        }
                    } else {
                        this.sendMessage({
                            message: Labels.entrance_message,
                            type: Constants.message_types.entrance,
                            user: joinedUser,
                            forUserOnly: true
                        });
                    }

                    break;
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.UPDATE_ROOMS_USERS, (roomId, joinedLeftUser, task, roomsUsers) => {
            let {rooms} = this.state;

            for (let room of rooms) {
                if (roomsUsers && task === Constants.tasks.add_user) {
                    const roomUsers = roomsUsers[room.id];

                    if (roomUsers && roomUsers.length) {
                        room.users = roomUsers;
                    }
                } else {
                    if (room.id === roomId) {
                        if (task === Constants.tasks.add_user) {
                            room.users.push(joinedLeftUser);
                        } else if (task === Constants.tasks.remove_user) {
                            removeUserFromRoom(joinedLeftUser.id, room);
                        }

                        break;
                    }
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.USER_LEAVED_ROOM, (roomId, leftUser, message) => {
            message = message || Labels.user_leave_room;

            let type = Constants.message_types.user_leave_room;
            let activeRoom = null;

            if (roomId) {
                message = Labels.leave_message;
                type = Constants.message_types.leave;

                const {rooms} = this.state;

                for (let room of rooms) {
                    if (room.id === roomId) {
                        activeRoom = room;

                        break;
                    }
                }
            }

            this.sendMessage({
                message: message,
                type: type,
                user: leftUser,
                useSocket: false,
                activeRoom: activeRoom
            });
        });

        socket.on(Events.MESSAGE_SENT, (message, privateConversationId) => {
            this.addMessage(message, privateConversationId);

            if (privateConversationId) {
                this.calculateUnReadConversations();
            }
        });

        socket.on(Events.USER_JOINED_PRIVATE_CONVERSATION, (privateConversationName, user, toUser) => {
            let {privateConversations} = this.state;
            let isAlreadyExists = _.find(privateConversations, (privateConversation) => {
                return (
                    privateConversation.id === privateConversationName || (
                        privateConversation.id.indexOf(user.username) > -1 &&
                        privateConversation.id.indexOf(toUser.username) > -1
                    )
                );
            });

            if (isAlreadyExists) {
                this.setState({
                    activePrivateConversation: isAlreadyExists
                });

                return;
            }

            const sender = user.nick_name || user.username;
            const receiver = toUser.nick_name || toUser.username;
            const loggedInUser = this.props.user;
            const privateConversation = {
                id: privateConversationName,
                users: [user, toUser],
                read: loggedInUser.id === user.id,
                name: sender + ' & ' + receiver,
                createdBy: user,
                messages: []
            };

            privateConversations.push(privateConversation);

            if (loggedInUser.id === user.id) {
                this.setState({
                    privateConversations,
                    activePrivateConversation: privateConversation
                });

                return;
            }

            this.setState({
                privateConversations
            });
        });

        socket.on(Events.REMOVE_PRIVATE_CONVERSATION, (privateConversation) => {
            let {activePrivateConversation, privateConversations, modalIsOpen, modalTask} = this.state;
            let deletedPrivateConversationIsActive = privateConversation.id === activePrivateConversation.id;

            privateConversations = privateConversations.filter((pConversation) => {
                return pConversation.id !== privateConversation.id;
            });

            if (deletedPrivateConversationIsActive) {
                activePrivateConversation = {};
            }

            this.setState({
                activePrivateConversation,
                privateConversations,
                modalIsOpen: deletedPrivateConversationIsActive ? false : modalIsOpen,
                modalTask: deletedPrivateConversationIsActive ? null : modalTask
            });

            this.calculateUnReadConversations();
        });

        socket.on(Events.REMOVE_PRIVATE_CONVERSATIONS, (username) => {
            let {privateConversations, activePrivateConversation, modalTask} = this.state;
            let isActivePrivateConversationDeleted = false;

            const newPrivateConversations = privateConversations.filter((conversation) => {
                let isUserExists = false;

                for (let user of conversation.users) {
                    if (user.username === username) {
                        isUserExists = true;

                        break;
                    }
                }

                if (conversation.id === activePrivateConversation.id) {
                    isActivePrivateConversationDeleted = !isUserExists;
                }

                return !isUserExists;
            });

            this.setState({
                privateConversations: newPrivateConversations,
                activePrivateConversation: isActivePrivateConversationDeleted ? activePrivateConversation : null
            });

            this.calculateUnReadConversations();

            if (modalTask === Constants.message_types.private_message) {
                this.closeModal();
            }
        });

        socket.on(Events.SEND_NOTIFICATION, (user, message, task, image) => {
            let messageContent = [
                '<div class="notification-content">',
            ];

            if (user) {
                const chatUser = user.chatuser;

                messageContent = messageContent.concat([
                    '<div class="username">' + chatUser.nick_name + '</div>',
                ]);
            }

            messageContent = messageContent.concat([
                '<div class="message-icon">',
                '<div>' + message + '</div>',
            ]);

            const withImageTasks = [Constants.gift_notification, Constants.like_notification];

            if (withImageTasks.indexOf(task) > -1) {
                let notificationImage = window.defaultRoomImage;

                if (image) {
                    if (task === Constants.like_notification) {
                        notificationImage = image
                    } else {
                        notificationImage = Config.image_path + image;
                    }
                }

                messageContent = messageContent.concat([
                    '<img src="' + notificationImage + '" alt=""/>'
                ]);
            }

            messageContent = messageContent.concat([
                '</div>',
                '</div>',
            ]);

            this.setNotificationMessage(messageContent.join(''));
        });

        socket.on(Events.ADD_ROOM, (room) => {
            let {rooms} = this.state;

            this.setState({
                rooms: rooms.concat(room)
            });
        });

        socket.on(Events.UPDATE_ROOM, (updatedRoom) => {
            let {rooms} = this.state;

            for (let room of rooms) {
                if (room.id === updatedRoom.id) {
                    room.nick_name = updatedRoom.nick_name;
                    room.description = updatedRoom.description;
                    room.welcome_message = updatedRoom.welcome_message;
                    room.seats = updatedRoom.seats;
                    room.type = updatedRoom.type;
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.DELETE_ROOM, () => {
            let {user} = this.props;
            let {activeRoom} = this.state;

            removeUserFromRoom(user.id, activeRoom);

            this.setState({
                activeRoom
            });
        });

        socket.on(Events.ROOM_DELETED, (roomId) => {
            let {rooms} = this.state;

            for (let [index, room] of rooms.entries()) {
                if (room.id === roomId) {
                    rooms.splice(index, 1);

                    break;
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.adv_message_sent, (message) => {
            if (this.checkIfUserInActiveRoom()) {
                this.addMessage(message);
            }
        });

        socket.on(Events.remove_non_const_empty_room, (roomId) => {
            deleteRoom(roomId)
                .then(() => {
                }, (error) => {
                    console.error(error);
                });
        });

        socket.on(Events.changing_hidden_login_status, (hiddenLogin, user) => {
            let message = Labels.entrance_message;
            let type = Constants.message_types.entrance;

            if (hiddenLogin) {
                message = Labels.user_logged_out;
                type = null;
            }

            this.sendMessage({
                message: message,
                type: type,
                user: user,
                useSocket: false
            });

            let {rooms, activeRoom} = this.state;

            for (let room of rooms) {
                if (room.id === activeRoom.id) {
                    for (let [index, roomUser] of room.users.entries()) {
                        if (roomUser.id === user.id) {
                            room.users[index] = user;

                            break;
                        }
                    }

                    break;
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.set_room_the_user_expelled_from, (roomId) => {
            let {rooms} = this.state;
            let {user} = this.props;

            for (let room of rooms) {
                if (room.id === roomId) {
                    room.usersExpelledFromTheRoom[user.id] = user.id;

                    setTimeout(() => {
                        delete room.usersExpelledFromTheRoom[user.id];

                        this.setState({
                            rooms
                        });
                    }, Config[process.env.NODE_ENV].room_expulsion_period);

                    break;
                }
            }

            this.setState({
                rooms
            });
        });

        socket.on(Events.default_wall_messages, (wall) => {
            this.setState({
                wall: this.state.wall.concat(wall)
            });
        });

        socket.on(Events.WALL_MESSAGE_SENT, (message) => {
            let {wall} = this.state;

            wall.push(message);

            this.setState({
                wall
            });

            this.setUnreadWallMessages();
            this.setUserLastActionDate();
        });

        socket.on(Events.DELETE_WALL_MESSAGE, (messageId) => {
            let {wall} = this.state;

            for (let [index, wallMessage] of wall.entries()) {
                if (wallMessage.id === messageId) {
                    wall.splice(index, 1);

                    break;
                }
            }

            this.setState({
                wall
            });
        });

        socket.on(Events.REMOVE_WALL, () => {
            this.setState({
                wall: []
            });
        });

        socket.on(Events.WALL_MESSAGE_LIKE, (messageId) => {
            let {wall} = this.state;

            for (let wallMessage of wall) {
                if (wallMessage.id === messageId) {
                    wallMessage['likes'] = wallMessage['likes'] || 0;
                    wallMessage['likes']++;

                    break;
                }
            }

            this.setState({
                wall
            });
        });

        socket.on(Events.SEND_WALL_MESSAGE_COMMENT, (messageId, message) => {
            let {wall} = this.state;

            for (let wallMessage of wall) {
                if (wallMessage.id === messageId) {
                    wallMessage['comments'] = wallMessage['comments'] || [];
                    wallMessage['comments'].push(message);

                    break;
                }
            }

            this.setState({
                wall
            });
        });

        socket.on(Events.UPDATE_SHORTCUTS, (updatedShortcuts) => {
            this.setShortcuts(updatedShortcuts);
        });

        socket.on(Events.DELETE_PUBLIC_MESSAGE, (messageId) => {
            const {messages} = this.state;
            const messageIndex = _.findIndex(messages, (message) => {
                return messageId === message.id;
            });

            if (messageIndex >= 0) {
                messages.splice(messageIndex, 1);

                this.setState({
                    messages
                });
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        const {socket, user} = this.props;

        axios.all([
            getRooms(),
            getFilterWords(),
            getShortcuts(),
            getPermissions(),
            getMessages()
        ]).then(axios.spread((
            roomsResponse,
            filterWordsResponse,
            shortcutsResponse,
            permissionsResponse,
            messagesResponse
        ) => {
            let returnedRooms = roomsResponse.data;

            if (!returnedRooms || (returnedRooms && !returnedRooms.length)) {
                throw "No rooms returned from the backend!";
            }

            const rooms = prepareRooms(returnedRooms);
            const words = filterWordsResponse.data;
            const shortcuts = shortcutsResponse.data.results;
            const permissions = permissionsResponse.data;
            const messages = messagesResponse.data.results;
            const country = localStorage.getItem('_country');
            const ip = localStorage.getItem('_ip');

            socket.emit(
                Events.UPDATE_THE_USER,
                user.username,
                Constants.tasks.set_user_country_ip,
                {
                    ip,
                    country
                }
            );

            let dailyMessages = [];
            let welcomeMessages = [];

            for (let i = 0; i < messages.length; i++) {
                const message = messages[i];

                if (message.category === Constants.message_categories.welcome) {
                    welcomeMessages.push(message);

                } else {
                    dailyMessages.push(message);
                }
            }

            this.setState({
                rooms: rooms,
                activeRoom: rooms[0],
                userActiveRoom: rooms[0],
                dailyMessages: dailyMessages,
                welcomeMessages: welcomeMessages,
                words: words,
                loading: false
            }, () => {
                socket.emit(Events.JOIN_ROOM, rooms[0].id, user);

                this.displayDailyMessages();
                this.setShortcuts(shortcuts);
            });

            localStorage.setItem('permissions_' + user.id, JSON.stringify(permissions));

            socket.emit(
                Events.UPDATE_THE_USER,
                user.username,
                Constants.tasks.check_user_activity,
                null,
                null,
                null
            );
        }), (errors) => {
            console.error(errors);

            this.setState({
                loading: false
            });
        });

        const numberOfMessages = Config[process.env.NODE_ENV].number_of_messages;
        const cleaningMessagesPeriod = Config[process.env.NODE_ENV].cleaning_messages_period;
        const monitoringUserActivityPeriod = Config[process.env.NODE_ENV].monitoring_user_activity_period;

        cleaningActivePrivateConversationIntervalId = setInterval(() => {
            let {activePrivateConversation} = this.state;

            if (activePrivateConversation && activePrivateConversation.read) {
                if (activePrivateConversation.messages && activePrivateConversation.messages.length > numberOfMessages) {
                    const count = activePrivateConversation.messages.length - numberOfMessages;

                    activePrivateConversation.messages.splice(0, count);

                    this.setState({
                        activePrivateConversation
                    });
                }
            }
        }, cleaningMessagesPeriod);

        monitoringUserActivityIntervalId = setInterval(() => {
            const {userLastActionDate} = this.state;
            const minutes = calculateMinutes(userLastActionDate);

            socket.emit(
                Events.UPDATE_THE_USER,
                user.username,
                Constants.tasks.check_user_activity,
                null,
                null,
                minutes
            );
        }, monitoringUserActivityPeriod);

        cleaningWallIntervalId = setInterval(() => {
            let {wall} = this.state;

            if (wall.length > numberOfMessages) {
                wall.splice(0, wall.length - numberOfMessages);

                this.setState({
                    wall
                });
            }
        }, cleaningMessagesPeriod);
    }

    setUserLastActionDate = () => {
        const userLastActionDate = moment();

        this.setState({
            userLastActionDate
        });

        const {user, socket} = this.props;
        const userActivityTypes = [
            Constants.user_activity.active,
            Constants.user_activity.hidden_login,
            Constants.user_activity.active_disable_private_chat
        ];

        if (userActivityTypes.indexOf(user.activity) < 0) {
            const minutes = calculateMinutes(userLastActionDate)

            socket.emit(
                Events.UPDATE_THE_USER,
                user.username,
                Constants.tasks.check_user_activity,
                null,
                null,
                minutes
            );
        }
    };

    sendMessage = ({
            message,
            type,
            sender,
            mediaType,
            user,
            activeRoom,
            forUserOnly = false,
            image,
            isWall = false,
            privateConversation,
            useSocket = true,
            isADV = false
        }) => {
        user = user || this.props.user;
        activeRoom = activeRoom || this.state.activeRoom;

        const {socket} = this.props;
        const newMessage = createMessage(message, user, activeRoom, type, sender, mediaType, image);

        if (useSocket) {
            socket.emit(
                Events.SEND_MESSAGE,
                newMessage,
                activeRoom.id,
                forUserOnly,
                isWall,
                privateConversation,
                isADV
            );
        } else {
            this.addMessage(newMessage);
        }

        if (user.username === this.props.user.username) {
            this.checkFilter(newMessage);
        }
    };

    addMessage = (message, privateConversationId) => {
        const {user} = this.props;
        const {messages} = this.state;
        const numberOfMessages = Config[process.env.NODE_ENV].number_of_messages;
        const messagesTypes = [
            Constants.message_types.entrance,
            Constants.message_types.logout,
            Constants.message_types.leave,
            Constants.message_types.user_leave_room
        ];

        if (messagesTypes.indexOf(message.type) > -1 && isHiddenLogin(message.user, user)) {
            return;
        }

        if (privateConversationId) {
            let {privateConversations, modalTask, activePrivateConversation} = this.state;

            for (let privateConversation of privateConversations) {
                if (privateConversation.id === privateConversationId) {
                    privateConversation.messages.push(message);

                    const isPrivateMessageModalTask = modalTask === Constants.message_types.private_message;

                    if (!isPrivateMessageModalTask && activePrivateConversation.id === privateConversation.id) {
                        privateConversation.read = false;
                    }

                    this.setState({
                        privateConversations
                    });

                    break;
                }
            }

            this.setUserLastActionDate();

            return;
        }

        if (messages.length > numberOfMessages) {
            messages.splice(0, messages.length - numberOfMessages);
        }

        this.setState({
            messages: messages.concat(message)
        });

        if (!message.type || message.type === Constants.message_types.adv) {
            this.setUserLastActionDate();
        }
    };

    displayDailyMessages = () => {
        const {dailyMessages} = this.state;
        const dailyMessagesPeriod = Config[process.env.NODE_ENV].daily_messages_period;

        let counter = 0;

        if (dailyMessages.length) {
            dailyMessagesIntervalId = setInterval(() => {
                if (counter > dailyMessages.length - 1) {
                    counter = 0;
                }

                const message = dailyMessages[counter];

                this.sendMessage({
                    message: message.message,
                    type: Constants.message_types.daily,
                    sender: message.title,
                    forUserOnly: true,
                });

                counter++;
            }, dailyMessagesPeriod);
        }
    };

    setShortcuts = (shortcuts) => {
        const stringShortcuts = prepareShortcuts(shortcuts, Constants.shortcut_types.string);
        const icons = prepareShortcuts(shortcuts, Constants.shortcut_types.smile_icon);
        const gifts = prepareShortcuts(shortcuts, Constants.shortcut_types.gift_icon);

        this.setState({
            icons: icons.shortcuts,
            mapping: icons.mapping,
            shortcuts: stringShortcuts.shortcuts,
            gifts: gifts.shortcuts,
        });
    };

    setUnreadWallMessages = () => {
        let {unreadWallMessages, sidebarTask} = this.state;

        if (sidebarTask === Constants.sidebar_tasks.wall) {
            unreadWallMessages = 0;
        } else {
            unreadWallMessages++;
        }

        this.setState({
            unreadWallMessages
        });
    };

    calculateUnReadConversations = () => {
        let {privateConversations} = this.state;
        let {user} = this.props;
        let unreadConversations = 0;

        for (let privateConversation of privateConversations) {
            if (!privateConversation.read && user.username !== privateConversation.createdBy.username) {
                unreadConversations++;
            }
        }

        this.setState({
            unreadConversations
        });
    };

    createAndOpenPrivateConversationModal = () => {
        this.closeModal();

        const {user, socket} = this.props;
        const {userModalInfo} = this.state;
        const chatUser = user.chatuser || {};

        socket.emit(Events.JOIN_PRIVATE_CONVERSATION, user, userModalInfo);

        getLoggedInUserLikes(chatUser.id)
            .then((response) => {
                const data = response.data;
                const loggedInUserLikes = data.likes_count;
                const modalTask = Constants.message_types.private_message;

                this.setState({
                    modalTask,
                    loggedInUserLikes
                });

                this.openModal();
            }, (error) => {
                console.error(error);
            });
    };

    changeActiveRoom = (roomId, toUser) => {
        const previousActiveRoom = this.state.activeRoom;
        const {user, socket} = this.props;
        const {rooms} = this.state;

        if (roomId) {
            for (let room of rooms) {
                if (room.id === roomId) {
                    if (room.usersExpelledFromTheRoom[toUser ? toUser.id : user.id]) {
                        this.setNotificationMessage(Labels.you_Expelled_from_this_room);

                        return;
                    }

                    break;
                }
            }

            if (roomId === previousActiveRoom.id && this.checkIfUserInActiveRoom()) {
                return;
            }
        }

        socket.emit(Events.CHANGE_ACTIVE_ROOM, previousActiveRoom.id, previousActiveRoom.type, roomId, toUser ? toUser : user);
    };

    setSidebarTask = (task) => {
        this.setState({
            sidebarTask: task
        });
    };

    closeSidebar = () => {
        this.setState({
            sidebarTask: null
        });
    };

    checkFilter = (message) => {
        if (message) {
            const {words, userModalInfo, activeRoom} = this.state;

            for (let word of words) {
                if (message.message.indexOf(word.word) > -1) {
                    addMessageToFilter(message, userModalInfo, word, activeRoom)
                        .then(() => {
                        }, (error) => {
                            console.error(error);
                        });
                }
            }
        }
    };

    openModal = () => {
        this.setState({
            modalIsOpen: true
        });
    };

    closeModal = () => {
        if (this.state.modalTask === Constants.message_types.private_message) {
            let {activePrivateConversation} = this.state;

            if (!activePrivateConversation || !activePrivateConversation.messages.length) {
                const {socket} = this.props;

                socket.emit(Events.REMOVE_PRIVATE_CONVERSATION, activePrivateConversation);
            }
        }

        this.setState({
            modalIsOpen: false,
            modalTask: null
        });
    };

    handleClickedMessage = (message) => {
        const messagesTypes = [
            Constants.message_types.welcome,
            Constants.message_types.logout,
            Constants.message_types.daily,
        ];

        if (messagesTypes.indexOf(message.type) > -1) {
            return;
        }

        this.handleConnectedUsersClick(message.user);
    };

    setActiveConversation = (conversation) => {
        const {modalTask, privateConversations} = this.state;
        const {user} = this.props;
        const filteredUser = conversation.users.filter(cUser => {
            return cUser.id !== user.id;
        });

        if (modalTask && modalTask !== Constants.message_types.private_message) {
            this.closeModal();
        }

        let activePrivateConversation = privateConversations.filter((privateConversation) => {
            return privateConversation.id === conversation.id;
        })[0];

        activePrivateConversation.read = true;

        this.setState({
            modalTask: Constants.message_types.private_message,
            activePrivateConversation: activePrivateConversation,
            userModalInfo: filteredUser[0] || user
        });

        this.openModal();
        this.calculateUnReadConversations();
    };

    resetWallCounter = () => {
        this.setState({
            unreadWallMessages: 0
        });
    };

    handleConnectedUsersClick = (clickedUser) => {
        if (clickedUser) {
            this.closeModal();

            const {socket, isUserConnected} = this.props;

            if (isUserConnected(clickedUser.username)) {
                socket.emit(Events.GET_USER_ACTIVE_ROOM, clickedUser.socketId, (userActiveRoomId) => {
                    let userActiveRoom;

                    if (userActiveRoomId) {
                        const {rooms} = this.state;

                        for (let room of rooms) {
                            if (room.id === userActiveRoomId) {
                                userActiveRoom = room;

                                break;
                            }
                        }
                    }

                    this.setState({
                        userModalInfo: clickedUser,
                        userActiveRoom: userActiveRoom
                    });

                    this.openModal();
                });
            }
        }
    };

    toggleModalExpansion = () => {
        const {privateMessagesModalExpanded} = this.state;

        this.setState({
            privateMessagesModalExpanded: !privateMessagesModalExpanded
        });
    };

    checkIfUserInActiveRoom = () => {
        const {user} = this.props;
        const {activeRoom} = this.state;
        const userExist = _.find(activeRoom.users, (roomUser) => {
            return user.id === roomUser.id;
        });

        return !!userExist;
    };

    setNotificationMessage = (message) => {
        const {notificationMessages} = this.state;

        let newMessages = _.cloneDeep(notificationMessages);

        const newMessage = {
            id: uuidv4(),
            message
        };

        newMessages.push(newMessage);

        this.setState({
            notificationMessages: newMessages
        });
    };

    removeNotificationMessage = (id) => {
        const {notificationMessages} = this.state;

        let newMessages = _.cloneDeep(notificationMessages);

        for (let i in newMessages) {
            if (!newMessages.hasOwnProperty(i)) {
                continue;
            }

            const message = newMessages[i];

            if (message.id === id) {
                newMessages.splice(i, 1);

                break;
            }
        }

        this.setState({
            notificationMessages: newMessages
        });
    };

    componentWillUnmount() {
        const {socket} = this.props;

        socket.off();

        if (dailyMessagesIntervalId) {
            clearInterval(dailyMessagesIntervalId);
        }

        if (cleaningActivePrivateConversationIntervalId) {
            clearInterval(cleaningActivePrivateConversationIntervalId);
        }

        if (monitoringUserActivityIntervalId) {
            clearInterval(monitoringUserActivityIntervalId);
        }

        if (cleaningWallIntervalId) {
            clearInterval(cleaningWallIntervalId);
        }
    }

    updateCanDoLikeStatus = (status) => {
        this.setState({
            canDoLike: status
        });
    };

    openWallMessageModal = (messageId, user) => {
        this.setState({
            wallCommentsModalIsOpen: true,
            wallMessageModelUser: user,
            wallMessageModalId: messageId
        });
    }

    closeWallMessageModal = () => {
        this.setState({
            wallCommentsModalIsOpen: false
        });
    }

    sendWallMessageComment = (params) => {
        const {socket, user} = this.props;
        const {wallMessageModalId} = this.state;
        const message = params.message || '';
        const newMessage = createMessage(message, user);

        socket.emit(Events.SEND_WALL_MESSAGE_COMMENT, wallMessageModalId, newMessage);
    };

    handleDeleteMessage = (messageId) => {
        const {socket} = this.props;

        socket.emit(Events.DELETE_PUBLIC_MESSAGE, messageId);
    }

    render() {
        const {
            user, connectedUsers, socket,
            logout, buttonsColor, templateColor,
            contentColor, siteSettings
        } = this.props;

        let {
            sidebarTask, rooms, activeRoom, canDoLike,
            modalIsOpen, modalTask, userModalInfo,
            privateConversations, activePrivateConversation,
            unreadWallMessages, unreadConversations,
            privateMessagesModalExpanded,
            shortcuts, icons, mapping, gifts, notificationMessages,
            loggedInUserLikes, loading, userActiveRoom,
            messages, wall, wallMessageModelUser, wallMessageModalId
        } = this.state;

        userModalInfo = userModalInfo || {};

        const messageWallComments = _.find(wall, (wallMessage) => {
            return wallMessage.id === wallMessageModalId
        }) || {};

        const chatUser = userModalInfo.chatuser || {};
        const chatUserSettings = chatUser.chat_user_settings || {};
        const userGroups = userModalInfo.groups || [];
        const userGroup = userGroups[0] || {};
        const userGroupInfo = userGroup.group_info || {};

        let imageUrl = window.defaultUserImage;

        if (chatUserSettings.picture_name) {
            imageUrl = Config.image_path + Constants.avatars + chatUserSettings.picture_name;
        }

        return (
            <div className="chat-container">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <Sidebar
                    task={sidebarTask}
                    closeSidebar={this.closeSidebar}
                    rooms={rooms}
                    activeRoom={activeRoom}
                    changeActiveRoom={this.changeActiveRoom}
                    connectedUsers={connectedUsers}
                    user={user}
                    socket={socket}
                    privateConversations={privateConversations}
                    setActiveConversation={this.setActiveConversation}
                    logout={logout}
                    handleConnectedUsersClick={this.handleConnectedUsersClick}
                    shortcuts={shortcuts}
                    buttonsColor={buttonsColor}
                    contentColor={contentColor}
                    templateColor={templateColor}
                    icons={icons}
                    mapping={mapping}
                    siteSettings={siteSettings}
                    setNotificationMessage={this.setNotificationMessage}
                    sendMessage={this.sendMessage}
                    userAllowed={this.checkIfUserInActiveRoom()}
                    wall={wall}
                    openWallMessageModal={this.openWallMessageModal}
                />
                {
                    activeRoom && (
                        <div className="chat-room-container">
                            <ChatHeading
                                activeRoom={activeRoom}
                            />
                            <Messages
                                messages={messages}
                                user={user}
                                callback={this.handleClickedMessage}
                                changeActiveRoom={this.changeActiveRoom}
                                contentColor={contentColor}
                                buttonsColor={buttonsColor}
                                deleteMessageCallback={this.handleDeleteMessage}
                            />
                            <MessageInput
                                sendMessage={this.sendMessage}
                                changeActiveRoom={this.changeActiveRoom}
                                user={user}
                                roomId={activeRoom.id}
                                userAllowed={this.checkIfUserInActiveRoom()}
                                shortcuts={shortcuts}
                                buttonsColor={buttonsColor}
                                icons={icons}
                                mapping={mapping}
                                setNotificationMessage={this.setNotificationMessage}
                                userExceedSendingMessagesAtCertainPeriod={true}
                                showJewelCount={true}
                                logout={logout}
                                showLeaveOfRoomButton={true}
                                area={Constants.public}
                            />
                        </div>
                    )
                }
                <ChatActions
                    roomsCount={rooms ? rooms.length : 0}
                    connectedUsers={connectedUsers}
                    user={user}
                    setSidebarTask={this.setSidebarTask}
                    unreadWallMessages={unreadWallMessages}
                    resetWallCounter={this.resetWallCounter}
                    privateConversationsLength={unreadConversations}
                    buttonsColor={buttonsColor}
                    templateColor={templateColor}
                />
                <Modal isOpen={modalIsOpen}
                       onRequestClose={this.closeModal}
                       overlayClassName={'modal-overlay' +
                       (modalTask && modalTask === Constants.message_types.private_message ? ' private-message' : '')}
                       className={'modal-content' +
                       (modalTask && modalTask === Constants.message_types.private_message ? ' messages-with-message-input-box private-message' : '') +
                       (privateMessagesModalExpanded ? ' expanded' : '')}
                >
                    {
                        modalTask && modalTask === Constants.message_types.private_message && (
                            <div className="head private-message-modal-header" onMouseDown={(e) => {
                                e.stopPropagation();
                            }}>
                                <div className="username-style">
                                    <div className="image-content-wrapper">
                                        {
                                            <img src={imageUrl}
                                                 className="img-responsive"
                                                 alt={chatUser.nick_name || userModalInfo.username}
                                            />
                                        }
                                    </div>
                                    {
                                        (chatUser.nick_name || userModalInfo.username) && (
                                            <div className="username">
                                                {chatUser.nick_name || userModalInfo.username}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="buttons">
                                    <button
                                        className="btn btn-sm btn-info digi-btn"
                                        onClick={this.toggleModalExpansion}
                                    >
                                        {
                                            privateMessagesModalExpanded && (
                                                <FaCompress/>
                                            )
                                        }
                                        {
                                            !privateMessagesModalExpanded && (
                                                <FaExpand/>
                                            )
                                        }
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger digi-btn"
                                        onClick={this.closeModal}
                                    >
                                        <FaWindowMinimize/>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        !modalTask && (
                            <div className="head" onMouseDown={(e) => {
                                e.stopPropagation();
                            }}>
                                <div
                                    className="head-content"
                                    style={{
                                        background: buttonsColor
                                    }}
                                >
                                    <div className="username-style">
                                        <div className="image-content-wrapper">
                                            {
                                                <img src={imageUrl}
                                                     className="img-responsive"
                                                     alt={chatUser.nick_name || userModalInfo.username}
                                                />
                                            }
                                        </div>
                                        {
                                            !user.hideStar && userGroupInfo && userGroupInfo.icon && (
                                                <span className="user-icon">
                                                    <img
                                                        src={Config.image_path + Constants.super_images + userGroupInfo.icon}
                                                        className="img-responsive"
                                                        alt={userGroupInfo.icon}
                                                    />
                                                </span>
                                            )
                                        }
                                        {
                                            (chatUser.nick_name || userModalInfo.username) && (
                                                <div className="username">
                                                    {chatUser.nick_name || userModalInfo.username}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <FaTimes
                                        onClick={this.closeModal}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="body" onMouseDown={(e) => {
                        e.stopPropagation();
                    }}>
                        {
                            modalTask && modalTask === Constants.message_types.private_message && (
                                <div className="messages-with-message-input">
                                    <Messages
                                        messages={activePrivateConversation ? activePrivateConversation.messages : []}
                                        user={user}
                                        includeMediaCover={true}
                                        contentColor={contentColor}
                                        buttonsColor={buttonsColor}
                                    />
                                    <MessageInput
                                        attachment={true}
                                        attachmentInputId={Constants.attachments_ids.private_conversation}
                                        sendMessage={(params) => {
                                            params = Object.assign(params, {privateConversation: activePrivateConversation.id});
                                            this.sendMessage(params)
                                        }}
                                        shortcuts={shortcuts}
                                        buttonsColor={buttonsColor}
                                        icons={icons}
                                        mapping={mapping}
                                        siteSettings={siteSettings}
                                        loggedInUserLikes={loggedInUserLikes}
                                        uploadFolder={Constants.wall_private_media_folder}
                                    />
                                </div>
                            )
                        }
                        {
                            !modalTask && (
                                <UserProfileModal
                                    user={user}
                                    toUser={userModalInfo}
                                    createAndOpenPrivateConversationModal={this.createAndOpenPrivateConversationModal}
                                    buttonsColor={buttonsColor}
                                    gifts={gifts}
                                    socket={socket}
                                    closeProfileModalCallback={this.closeModal}
                                    siteSettings={siteSettings}
                                    setNotificationMessage={this.setNotificationMessage}
                                    userActiveRoom={userActiveRoom}
                                    checkFilter={this.checkFilter}
                                    canDoLike={canDoLike}
                                    canDoLikeCallback={this.updateCanDoLikeStatus}
                                    changeActiveRoom={this.changeActiveRoom}
                                    rooms={rooms}
                                />
                            )
                        }
                    </div>
                </Modal>
                <Notification
                    notificationMessages={notificationMessages}
                    removeNotificationMessage={this.removeNotificationMessage}
                    buttonsColor={buttonsColor}
                    contentColor={contentColor}
                />
                <Modal
                    isOpen={this.state.wallCommentsModalIsOpen}
                    onRequestClose={this.closeWallMessageModal}
                    overlayClassName={'modal-overlay'}
                    className="modal-content messages-with-message-input-box"
                >
                    <div className="head">
                        <div className="head-content"
                             style={{
                                 background: buttonsColor
                             }}>
                            <div className="username-style">
                                <div className="image-content-wrapper">
                                    {
                                        <img src={imageUrl}
                                             className="img-responsive"
                                             alt={wallMessageModelUser.username}
                                        />
                                    }
                                </div>
                                <div className="username">
                                    {wallMessageModelUser.username}
                                </div>
                            </div>
                            <FaTimes onClick={this.closeWallMessageModal}/>
                        </div>
                    </div>
                    <div className="body">
                        <div className="messages-with-message-input">
                            <Messages
                                messages={messageWallComments['comments'] || []}
                                user={user}
                                includeMediaCover={true}
                                contentColor={contentColor}
                                buttonsColor={buttonsColor}
                            />
                            <MessageInput
                                sendMessage={this.sendWallMessageComment}
                                shortcuts={shortcuts}
                                buttonsColor={buttonsColor}
                                icons={icons}
                                mapping={mapping}
                                siteSettings={siteSettings}
                                loggedInUserLikes={loggedInUserLikes}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ChatContainer;
