import React, {Component} from 'react';
import '../../assets/sass/ChatHeading.scss';

class ChatHeading extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeClass: null
        }
    }

    handleClick = (e) => {
        e.preventDefault();

        let {activeClass} = this.state;

        if (!activeClass) {
            activeClass = 'active';
        } else {
            activeClass = null;
        }

        this.setState({activeClass});
    };

    render() {
        const {activeRoom} = this.props;
        const {activeClass} = this.state;

        return (
            <div className={"chat-heading" + (activeClass ? ' active' : '')} onClick={this.handleClick}>
                <div className="active-chat-room-name">
                    {activeRoom.nick_name}
                </div>
                <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>              <div className="">
                    {activeRoom.nick_name}
                </div>
            </div>
        );
    }
}

export default ChatHeading;
