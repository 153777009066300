import React, {Component} from 'react';
import MessageInput from "../messaging/MessageInput";
import MediaCover from '../MediaCover';
import Config from "../../config";
import {FaHeart, FaTimes, FaComment} from "react-icons/fa";
import Events from '../../events';
import Constants from "../../constants";
import Labels from "../../labels";
import {convertDecimalToHex, getMessageTime, userPermissions} from '../../functions';
import {getLoggedInUserLikes} from "../../apis/chat";
import {addStatus} from "../../apis/admin/statuses";
import wallBanner from '../../assets/images/wallBanner.png';
import '../../assets/sass/wall.scss';
import _ from 'lodash';

class Wall extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedInUserLikes: 0
        };
    }

    scrollTop = () => {
        const messagesContainer = document.getElementById('wallMessages');

        messagesContainer.scrollIntoView();
    };

    componentWillMount() {
        /**
         * do some staff here!
         */
    }

    componentDidMount() {
        const {user} = this.props;
        const chatUser = user.chatuser || {};

        this.scrollTop();

        getLoggedInUserLikes(chatUser.id)
            .then((response) => {
                const data = response.data;
                const loggedInUserLikes = data.likes_count;

                this.setState({
                    loggedInUserLikes
                });
            }, (error) => {
                console.error(error);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollTop();
    }

    createMarkup = (message) => {
        return {__html: message};
    };

    handleDeleteWallMessage = (messageId, user) => {
        const {socket} = this.props;
        const chatUser = user.chatuser || {};

        this.setState({
            loading: true
        });

        addStatus({
            chatUserId: chatUser.id,
            toChatUserId: null,
            action: Labels.remove_wall_message_member_to_member,
            room: null
        }).then(() => {
            this.setState({
                loading: false
            });

            socket.emit(Events.DELETE_WALL_MESSAGE, messageId);
        }, (error) => {
            console.error(error);

            this.setState({
                loading: false
            });
        });
    };

    canDeleteMessage = (messageUserId) => {
        const {user} = this.props;

        return messageUserId === user.id || userPermissions(Constants.permissions.delete_wall, user.id);
    };

    handleWallMessageLikeClick = (messageId, messageUser) => {
        const {socket, user} = this.props;
        const usersWallMessagesLiked = JSON.parse(localStorage.getItem('usersWallMessagesLiked') || '{}');
        const userWallMessagesLiked = usersWallMessagesLiked[user.id];

        if (userWallMessagesLiked && userWallMessagesLiked.length) {
            const userWallMessageLiked = _.find(userWallMessagesLiked, (userWallMessageLiked) => {
                return userWallMessageLiked === messageId;
            });

            if (userWallMessageLiked) {
                return;
            }
        }

        usersWallMessagesLiked[user.id] = usersWallMessagesLiked[user.id] || [];
        usersWallMessagesLiked[user.id].push(messageId);

        localStorage.setItem('usersWallMessagesLiked', JSON.stringify(usersWallMessagesLiked));

        socket.emit(Events.WALL_MESSAGE_LIKE, messageId);
        socket.emit(
            Events.SEND_NOTIFICATION,
            user.username,
            messageUser.username,
            Labels['someone_liked_your_wall_message']
        );
    }

    componentWillUnmount() {
        /**
         * Do some staff here!
         */
    }

    render() {
        let {loggedInUserLikes} = this.state;
        let {
            user, shortcuts, buttonsColor, icons,
            mapping, handleConnectedUsersClick, contentColor,
            task, siteSettings, setNotificationMessage, sendMessage,
            wall, wallSubtitle, openWallMessageModal
        } = this.props;

        wallSubtitle = wallSubtitle || 'هنا مركز ابداعك';

        return (
            <div className={`wall${task === Constants.sidebar_tasks.wall ? ' active-side-bar-component' : ''}`}>
                <div className="wall_upper-section">
                    <div className="wall-banner">
                        <img src={wallBanner} className="img-responsive" alt={Labels.wall_banner}/>
                    </div>
                    <div className="wall_subtitle">
                        {wallSubtitle}
                    </div>
                    <div className="wall-messages" id="wallMessages">
                        {
                            wall && wall.length > 0 && (
                                wall.slice(0).reverse().map((message) => {
                                    const messageTime = getMessageTime(message);
                                    const messageUser = message.user || {};
                                    const userGroups = messageUser.groups;
                                    const userGroup = userGroups[0] || {};
                                    const userGroupInfo = userGroup.group_info || {};
                                    const chatUser = messageUser.chatuser || {};
                                    const chatUserSettings = chatUser.chat_user_settings || {};
                                    const mediaTypes = [
                                        Constants.media.image,
                                        Constants.media.video,
                                        Constants.media.record,
                                        Constants.media.youtube
                                    ];

                                    let imageUrl = window.defaultUserImage;

                                    if (chatUserSettings.picture_name) {
                                        imageUrl = Config.image_path + Constants.avatars + chatUserSettings.picture_name;
                                    }

                                    return (
                                        <div className="wall-message"
                                             key={message.id}
                                        >
                                            <div className="wall-message_user-image-remove-message">
                                                <div
                                                    className={`image-content-wrapper${user.username !== messageUser.username ? ' clickable' : ''}`}
                                                    onClick={() => handleConnectedUsersClick(messageUser)}
                                                >
                                                    {
                                                        <img src={imageUrl}
                                                             className="img-responsive"
                                                             alt={message.sender}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.canDeleteMessage(messageUser.id) && (
                                                        <div
                                                            className="delete-message"
                                                            onClick={() => {
                                                                this.handleDeleteWallMessage(message.id, message.user)
                                                            }}
                                                        >
                                                            <FaTimes/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="info">
                                                <div className="sender-name username-style">
                                                    {
                                                        message.user.gift && (
                                                            <div className="gift">
                                                                <img
                                                                    src={Config.image_path + Constants.gifts_images + message.user.gift}
                                                                    alt="message.user.gift"
                                                                    className="img-responsive"
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !user.hideStar && userGroupInfo && userGroupInfo.icon && (
                                                            <span className="user-icon">
                                                                <img
                                                                    src={Config.image_path + Constants.super_images + userGroupInfo.icon}
                                                                    className="img-responsive"
                                                                    alt=""/>
                                                            </span>
                                                        )
                                                    }
                                                    <span
                                                        className="username-text"
                                                        style={{
                                                            background: convertDecimalToHex(chatUserSettings.background_color),
                                                            color: convertDecimalToHex(chatUserSettings.display_name_color)
                                                        }}
                                                    >
                                                        {chatUser.nick_name || message.sender}
                                                    </span>
                                                </div>
                                                <div className="message-content">
                                                    {
                                                        mediaTypes.indexOf(message.mediaType) > -1 ?
                                                            <MediaCover
                                                                media={message.message}
                                                                type={message.mediaType === Constants.media.youtube ? Constants.media.youtube : message.mediaType}
                                                                image={message.roomImage}
                                                                mediaFolder={Constants.wall_private_media_folder}
                                                            />
                                                            :
                                                            <div
                                                                dangerouslySetInnerHTML={this.createMarkup(message.message)}
                                                                className="clearfix"
                                                            />
                                                    }
                                                </div>
                                                {
                                                    <div className="message-time">
                                                        {
                                                            messageTime.diff_mins === 0 && (
                                                                Labels.now
                                                            )
                                                        }
                                                        {
                                                            messageTime.diff_mins > 0 && messageTime.diff_mins < 60 && (
                                                                Labels.minute + messageTime.diff_mins
                                                            )
                                                        }
                                                        {
                                                            messageTime.diff_mins > 60 && (
                                                                Labels.hours + messageTime.diff_hours
                                                            )
                                                        }
                                                    </div>
                                                }
                                                <div className="wall-message_actions">
                                                    <a href="javascript: void(0)"
                                                       onClick={() => {openWallMessageModal(message.id, message.user)}}
                                                       className="wall-message_actions_comment">
                                                        <FaComment/><span>{(message['comments'] && message['comments'].length) || 0}</span>
                                                    </a>
                                                    <a href="javascript: void(0)"
                                                       onClick={() => {this.handleWallMessageLikeClick(message.id, message.user)}}
                                                       className="wall-message_actions_like">
                                                        <FaHeart/><span>{message['likes'] || 0}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
                <MessageInput
                    attachment={true}
                    attachmentInputId={Constants.attachments_ids.wall}
                    sendMessage={sendMessage}
                    shortcuts={shortcuts}
                    buttonsColor={buttonsColor}
                    icons={icons}
                    mapping={mapping}
                    contentColor={contentColor}
                    siteSettings={siteSettings}
                    loggedInUserLikes={loggedInUserLikes}
                    setNotificationMessage={setNotificationMessage}
                    uploadFolder={Constants.wall_private_media_folder}
                />
            </div>
        );
    }
}

export default Wall;
