import React, {Component} from 'react';
import Labels from "../labels";

class WordsFilter extends Component {
    render() {
        const {messages, title} = this.props;

        return (
            <div className="filter-words-messages">
                <div className="form-group">
                    <label htmlFor="#">{title}</label>
                </div>
                <div className="filter-messages">
                    {
                        messages.length > 0 && (
                            messages.map((message) => {
                                const wordMessage = message.word_messages[0] || {};

                                return (
                                    <div key={message.id}
                                         className="filter-messages-content form-group">
                                        <div className="content">
                                            <div className="message">{message.message}</div>
                                            <div className="user">
                                                {message.from_user.nick_name}
                                            </div>
                                        </div>
                                        <div className="word">
                                            <label htmlFor="#">
                                                {Labels.word}
                                            </label>
                                            {wordMessage.word.word}
                                        </div>
                                    </div>
                                );
                            })
                        )
                    }
                </div>
            </div>
        );
    }
}

export default WordsFilter;