import React, {Component} from 'react';
import {FaCheck, FaTimes} from 'react-icons/fa';
import Labels from '../../labels';
import _ from 'lodash';
import {getShortcuts} from '../../apis/chat';
import {createShortcut, deleteShortcut} from '../../apis/admin/shortcuts';
import Constants from "../../constants";
import {addStatus} from "../../apis/admin/statuses";
import Spinner from "../functional/Spinner";
import '../../assets/sass/ShortCuts.scss';
import Events from "../../events";

let wholeShortcuts;

class Shortcuts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shortcutName: '',
            shortcutNickname: '',
            shortcuts: [],
            error: '',
            loading: false
        };
    }

    componentDidMount() {
        getShortcuts()
            .then((response) => {
                const shortcuts = response.data.results;

                this.setState({
                    shortcuts: shortcuts.filter((shortcut) => {
                        return shortcut.shortcut_type === Constants.shortcut_types.string;
                    })
                });

                wholeShortcuts = shortcuts;
            }, (error) => {
                console.error(error);
            });
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            error: ''
        });

        const {shortcutName, shortcutNickname} = this.state;

        if (shortcutName.trim() && shortcutNickname.trim()) {
            this.setState({
                loading: true
            });

            createShortcut(shortcutName.trim(), shortcutNickname.trim())
                .then((response) => {
                    const {user, socket} = this.props;
                    const chatUser = user.chatuser || {};
                    const shortcut = response.data;

                    this.setState({
                        shortcuts: this.state.shortcuts.concat(shortcut),
                        shortcutName: '',
                        shortcutNickname: '',
                        loading: false
                    });

                    wholeShortcuts = wholeShortcuts.concat(shortcut);

                    socket.emit(Events.UPDATE_SHORTCUTS, wholeShortcuts);

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.create_shortcut,
                        room: null
                    }).then(() => {
                        /**
                         * Do some staff here!
                         */
                    }, (error) => {
                        console.error(error);
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        } else {
            this.setError(Labels.all_fields_required);
        }
    };

    removeShortcut = (id) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_shortcut);

        if (confirm) {
            this.setState({
                loading: true
            });

            deleteShortcut(id)
                .then(() => {
                    const {user} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.remove_shortcut,
                        room: null
                    }).then(() => {
                        const {shortcuts} = this.state;
                        const clonedShortcuts = _.cloneDeep(shortcuts);
                        const newShortcuts = clonedShortcuts.filter((shortcut) => {
                            return parseInt(shortcut.id) !== parseInt(id);
                        });

                        this.setState({
                            shortcuts: newShortcuts,
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    setError = (error) => {
        this.setState({
            error
        });
    };

    render() {
        const {
            shortcutName,
            shortcutNickname,
            shortcuts,
            error,
            loading
        } = this.state;

        return (
            <div className="shortcuts">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="add-shortcut">
                    <form onSubmit={this.handleSubmit}>
                        {
                            error && (
                                <div className="alert alert-danger">{error}</div>
                            )
                        }
                        <div className="form-group">
                            <input
                                type="text"
                                name="shortcutName"
                                className="form-control"
                                id="shortcutName"
                                onChange={this.handleChange}
                                value={shortcutName}
                                placeholder={Labels.shortcut_name}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="shortcutNickname"
                                className="form-control"
                                id="shortcutNickname"
                                onChange={this.handleChange}
                                value={shortcutNickname}
                                placeholder={Labels.shortcut_nickname}
                            />
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-sm btn-success"
                            >
                                <FaCheck/>
                                {Labels.addition}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="shortcuts-content">
                    <table>
                        <thead>
                        <tr>
                            <th>{Labels.shortcut_name_1}</th>
                            <th>{Labels.shortcut_nickname_1}</th>
                            <th>{Labels.delete}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            shortcuts.length > 0 && (
                                shortcuts.map((shortcut) => {
                                    return (
                                        <tr key={shortcut.id}>
                                            <td>{shortcut.word}</td>
                                            <td>{shortcut.replacement}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        this.removeShortcut(shortcut.id)
                                                    }}
                                                >
                                                    <FaTimes/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Shortcuts;
