import React, {Component} from 'react';
import {FaPlus, FaTimes, FaUserAlt} from 'react-icons/fa';
import Modal from "react-modal";
import CreateRoom from "../forms/CreateRoom";
import JoinPrivateRoom from "../forms/JoinPrivateRoom";
import Labels from '../../labels';
import Constants from "../../constants";
import lockImage from '../../assets/images/lock.png';
import {getConnectedUsersCount, userPermissions} from "../../functions";
import Config from "../../config";
import '../../assets/sass/rooms.scss';

Modal.setAppElement('#root');

class Rooms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            modalTask: null,
            clickedRoom: null
        };
    }

    handleRoomClick = (room) => {
        const {activeRoom, userAllowed, user} = this.props;

        if (room.id === activeRoom.id && userAllowed) {
            return;
        }

        let modalTask = null;

        if (
            room &&
            room.room_type === Constants.room_types.private_room &&
            !userPermissions(Constants.permissions.skip_closed_rooms, user.id))
        {
            modalTask = Constants.tasks.join_room;
        } else {
            const {changeActiveRoom} = this.props;

            changeActiveRoom(room.id);
        }

        this.setState({
            modalTask,
            clickedRoom: room
        }, () => {
            if (modalTask) {
                this.openModal();
            }
        });
    };

    createNewRoom = () => {
        const modalTask = Constants.tasks.create_room;

        this.setState({
            modalTask
        }, () => {
            this.openModal();
        });
    };

    openModal = () => {
        this.setState({
            modalIsOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false
        });
    };

    render() {
        const {modalTask, clickedRoom} = this.state;
        const {
            rooms, activeRoom, user, socket, templateColor,
            changeActiveRoom, buttonsColor, userAllowed,
            setNotificationMessage
        } = this.props;

        return (
            <div className="rooms-container">
                <div
                    className="add-new-room"
                    style={{
                        background: templateColor
                    }}
                >
                    {
                        userPermissions(Constants.permissions.create_room, user.id) && (
                            <button
                                className="btn btn-success btn-sm"
                                onClick={this.createNewRoom}
                            >
                                {Labels.new_room}
                                <FaPlus/>
                            </button>
                        )
                    }
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        className="modal-content"
                        overlayClassName='modal-overlay'
                    >
                        <div className="head" onMouseDown={(e) => {
                            e.stopPropagation();
                        }}>
                            <div
                                className="head-content"
                                style={{
                                    background: buttonsColor
                                }}
                            >
                                <FaTimes
                                    onClick={this.closeModal}
                                />
                                <div className="title">
                                    {
                                        modalTask && modalTask === Constants.tasks.join_room && (
                                            Labels.enter_room_password
                                        )
                                    }
                                    {
                                        modalTask && modalTask === Constants.tasks.create_room && (
                                            Labels.create_new_room
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="body" onMouseDown={(e) => {
                            e.stopPropagation();
                        }}>
                            {
                                modalTask === Constants.tasks.create_room ?
                                    <CreateRoom
                                        closeModal={this.closeModal}
                                        user={user}
                                        socket={socket}
                                        buttonsColor={buttonsColor}
                                        changeActiveRoom={changeActiveRoom}
                                        setNotificationMessage={setNotificationMessage}
                                    />
                                    :
                                    <JoinPrivateRoom
                                        closeModal={this.closeModal}
                                        user={user}
                                        room={clickedRoom}
                                        changeActiveRoom={changeActiveRoom}
                                    />
                            }
                        </div>
                    </Modal>
                </div>
                <div className="rooms">
                    {
                        rooms && rooms.length > 0 && (
                            rooms.sort((a, b) => {
                                return b.users.length - a.users.length;
                            }).map((room) => {
                                const className = room.id === activeRoom.id && userAllowed ? 'active' : '';

                                let imageUrl = window.defaultRoomImage;

                                if (room.image) {
                                    imageUrl = Config.image_path + Constants.rooms_images + room.image;
                                }

                                return (
                                    <div
                                        key={room.id}
                                        className={'room ' + className}
                                        onClick={() => this.handleRoomClick(room)}
                                    >
                                        <span className="room-statistics jewel-count">
                                            <span>
                                                {room.seats} / {getConnectedUsersCount(room.users, user)}
                                            </span>
                                            <FaUserAlt/>
                                        </span>
                                        <div className="room-info-photo">
                                            <div className="image-content-wrapper">
                                                {
                                                    <img src={imageUrl}
                                                         className="img-responsive"
                                                         alt={room.nick_name}
                                                    />
                                                }
                                            </div>
                                            <div className="room-info">
                                                <div className="name">
                                                    {
                                                        room.room_type === Constants.room_types.private_room && (
                                                            <span className="locked-room">
                                                                <img src={lockImage}
                                                                     alt=""
                                                                     className={'img-responsive'}
                                                                />
                                                            </span>
                                                        )
                                                    }
                                                    <span>
                                                        {room.nick_name}
                                                    </span>
                                                </div>
                                                <div className="desc">
                                                    {room.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Rooms;
