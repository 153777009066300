import React, {Component} from 'react';
import {FaCheck, FaHome} from "react-icons/fa";
import axios from "axios";
import Labels from '../../labels';
import {convertDecimalToHex} from '../../functions';
import {getSiteSettings, setSiteSettings} from '../../apis/admin/siteAdministration';
import {createShortcut} from '../../apis/admin/shortcuts';
import {getShortcuts, uploadFile} from '../../apis/chat';
import _ from 'lodash';
import Spinner from "../functional/Spinner";
import JsColor from "../JsColor";
import Constants from "../../constants";
import IconsListingWithUpload from "../IconsListingWithUpload";
import {addStatus} from "../../apis/admin/statuses";
import Config from "../../config";
import Events from "../../events";
import '../../assets/sass/SiteAdministration.scss';

let wholeShortcuts;

class SiteAdministration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteName: '',
            pageTitle: '',
            siteDescription: '',
            siteKeyWords: '',
            javascript: '',
            templatesColor: '#ffffff',
            contentColor: '#ffffff',
            buttonsColor: '#ffffff',
            periodForDailyMessages: 0,
            likesNumber: 0,
            periodBetweenWallMessages: 0,
            allowGuestsToLogin: false,
            allowMemberingToSignup: false,
            likesNumberForPrivateMessages: 0,
            likesNumberForAlerts: 0,
            likesNumberForSendingImagesVideos: 0,
            success: false,
            iconsShortcuts: [],
            loading: false,
            logo: '',
            banner: '',
            logoLoaded: 0,
            bannerLoaded: 0,
            numberUsersFromSameIp: 0,
            usernameMaxLength: 0,
            generalRoomMaxLines: 0,
            kickPeriod: 0,
            giftMaxNumbers: 0,
            smileMaxNumbers: 0,
            welcomeMessage: '',
            defaultUserImageLoaded: 0,
            defaultRoomImageLoaded: 0,
            defaultRoomImage: '',
            defaultUserImage: ''
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        axios.all([
            getSiteSettings(),
            getShortcuts()
        ]).then(axios.spread((siteSettingsResponse, shortcutsResponse) => {
            const siteSettings = siteSettingsResponse.data || {};
            const shortcutsData = shortcutsResponse.data || {};
            const shortcuts = shortcutsData.results || [];
            const iconsShortcuts = shortcuts.filter((shortcut) => {
                return shortcut.shortcut_type.indexOf(Constants.icon) > -1;
            });

            this.setState({
                siteName: siteSettings.title,
                pageTitle: siteSettings.url,
                siteDescription: siteSettings.description,
                siteKeyWords: siteSettings.tags,
                javascript: siteSettings.script,
                templatesColor: convertDecimalToHex(siteSettings.template_color) || '#ffffff',
                contentColor: convertDecimalToHex(siteSettings.content_color) || '#ffffff',
                buttonsColor: convertDecimalToHex(siteSettings.button_color) || '#ffffff',
                periodForDailyMessages: siteSettings.daily_messages_period,
                likesNumber: siteSettings.likes_count,
                periodBetweenWallMessages: siteSettings.wall_messages_period,
                allowGuestsToLogin: siteSettings.allow_gusts ? siteSettings.allow_gusts : false,
                allowMemberingToSignup: siteSettings.allow_members ? siteSettings.allow_members : false,
                likesNumberForPrivateMessages: siteSettings.dm_max_likes_allowed,
                likesNumberForAlerts: siteSettings.notifications_max_likes_allowed,
                likesNumberForSendingImagesVideos: siteSettings.mms_max_likes_allowed,
                iconsShortcuts,
                loading: false,
                logo: siteSettings.logo || '',
                banner: siteSettings.banner || '',
                numberUsersFromSameIp: siteSettings.number_users_from_same_ip ? siteSettings.number_users_from_same_ip : 0,
                usernameMaxLength: siteSettings.username_max_length ? siteSettings.username_max_length : 0,
                generalRoomMaxLines: siteSettings.general_room_max_lines ? siteSettings.general_room_max_lines : 0,
                kickPeriod: siteSettings.kick_period ? siteSettings.kick_period : 0,
                giftMaxNumbers: siteSettings.gift_max_numbers ? siteSettings.gift_max_numbers : 0,
                smileMaxNumbers: siteSettings.smile_max_numbers ? siteSettings.smile_max_numbers : 0,
                welcomeMessage: siteSettings.welcome_message || '',
                defaultRoomImage: siteSettings.default_room_image_url || '',
                defaultUserImage: siteSettings.default_user_image_url || ''
            });

            wholeShortcuts = shortcuts;
        }), (errors) => {
            console.error(errors);

            this.setState({
                loading: false
            });
        });
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        const state = this.state;
        const data = {
            title: state.siteName,
            url: state.pageTitle,
            description: state.siteDescription,
            tags: state.siteKeyWords,
            script: state.javascript,
            template_color: parseInt(state.templatesColor.replace('#', ''), 16),
            content_color: parseInt(state.contentColor.replace('#', ''), 16),
            button_color: parseInt(state.buttonsColor.replace('#', ''), 16),
            daily_messages_period: state.periodForDailyMessages,
            likes_count: state.likesNumber,
            wall_messages_period: state.periodBetweenWallMessages,
            allow_gusts: state.allowGuestsToLogin,
            allow_members: state.allowMemberingToSignup,
            dm_max_likes_allowed: state.likesNumberForPrivateMessages,
            notifications_max_likes_allowed: state.likesNumberForAlerts,
            mms_max_likes_allowed: state.likesNumberForSendingImagesVideos,
            logo: state.logo,
            banner: state.banner,
            number_users_from_same_ip: state.numberUsersFromSameIp,
            username_max_length: state.usernameMaxLength,
            general_room_max_lines: state.generalRoomMaxLines,
            kick_period: state.kickPeriod,
            gift_max_numbers: state.giftMaxNumbers,
            smile_max_numbers: state.smileMaxNumbers,
            welcome_message: state.welcomeMessage,
            default_room_image_url: state.defaultRoomImage,
            default_user_image_url: state.defaultUserImage,
        };

        setSiteSettings(data)
            .then(() => {
                const {user} = this.props;
                const chatUser = user.chatuser || {};

                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: null,
                    action: Labels.site_admin_updated,
                    room: null
                }).then(() => {
                    const {socket} = this.props;

                    socket.emit(Events.SITE_SETTINGS_UPDATED, data);

                    this.setState({
                        success: true,
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                success: false
                            })
                        }, 5000);
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    handleLoadIconSuccess = (response, type) => {
        const {data} = response;
        const image = data.file;

        createShortcut(image, Constants.shortcut_replacement, type)
            .then((response) => {
                const {user, socket} = this.props;
                const chatUser = user.chatuser || {};
                const shortcutIcon = response.data;

                if (shortcutIcon) {
                    let {iconsShortcuts} = this.state;

                    iconsShortcuts.push(shortcutIcon);

                    this.setState({
                        iconsShortcuts,
                        loading: false
                    });

                    wholeShortcuts = wholeShortcuts.concat(shortcutIcon);

                    socket.emit(Events.UPDATE_SHORTCUTS, wholeShortcuts);
                }

                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: null,
                    action: Labels.icons_types[type] + Labels.adding,
                    room: null
                }).then(() => {
                    /**
                     * Do some staff here!
                     */
                }, (error) => {
                    console.error(error);
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    handleLoadIconFailed = (error) => {
        console.error(error);

        this.setState({
            loading: false
        });
    };

    handleRemoveIconSuccess = (response, id) => {
        const {iconsShortcuts} = this.state;
        const clonedShortcuts = _.cloneDeep(iconsShortcuts);
        const newShortcuts = clonedShortcuts.filter((shortcut) => {
            return parseInt(shortcut.id) !== parseInt(id);
        });

        this.setState({
            iconsShortcuts: newShortcuts,
            loading: false
        });
    };

    handleRemoveIconFailed = (error) => {
        console.error(error);

        this.setState({
            loading: false
        });
    };

    handleUploadFile = (e) => {
        const data = new FormData();
        const file = e.target.files[0];
        const name = e.target.name;
        const fileProgress = name + 'Loaded';

        if (file) {
            data.append('file', file, file.name);

            const onUploadProgress = (e) => {
                this.setState({
                    [fileProgress]: e.loaded / e.total * 100
                });
            };

            uploadFile(data, onUploadProgress)
                .then((response) => {
                    const {data} = response;
                    const uploadedFile = data.file;

                    setTimeout(() => {
                        this.setState({
                            [fileProgress]: 0,
                            [name]: uploadedFile
                        });
                    }, 1000);
                }, (error) => {
                    console.error(error);
                });
        }
    };

    render() {
        const {user} = this.props;
        const {
            templatesColor, contentColor, buttonsColor,
            likesNumber, periodBetweenWallMessages,
            allowGuestsToLogin, allowMemberingToSignup,
            likesNumberForPrivateMessages, likesNumberForAlerts,
            likesNumberForSendingImagesVideos, success,
            siteName, pageTitle, siteDescription,
            siteKeyWords, javascript, iconsShortcuts,
            loading, periodForDailyMessages, logoLoaded,
            logo, banner, bannerLoaded, numberUsersFromSameIp,
            usernameMaxLength, generalRoomMaxLines, kickPeriod,
            giftMaxNumbers, smileMaxNumbers, welcomeMessage,
            defaultUserImageLoaded, defaultRoomImageLoaded,
            defaultUserImage, defaultRoomImage
        } = this.state;

        return (
            <div className="site-administration">
                {
                    loading && (
                        <Spinner/>
                    )
                }

                <div className="site-administration-content">
                    <form onSubmit={this.handleSubmit}>
                        {
                            success && (
                                <div className="alert alert-success fixed">
                                    {Labels.saved_successfully}
                                </div>
                            )
                        }

                        <div className="form-group">
                            <button
                                type="button"
                                className="btn btn-sm btn-info btn-site-settings"
                            >
                                <FaHome/>
                                {Labels.site_settings}
                            </button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="siteName">{Labels.site_name}</label>
                            <textarea
                                name="siteName"
                                id="siteName"
                                className="form-control"
                                onChange={this.handleChange}
                                value={siteName}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pageTitle">{Labels.page_title}</label>
                            <textarea
                                name="pageTitle"
                                id="pageTitle"
                                className="form-control"
                                onChange={this.handleChange}
                                value={pageTitle}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="siteDescription">{Labels.site_description}</label>
                            <textarea
                                name="siteDescription"
                                id="siteDescription"
                                className="form-control"
                                onChange={this.handleChange}
                                value={siteDescription}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="welcomeMessage">{Labels.welcome_bar}</label>
                            <textarea
                                name="welcomeMessage"
                                id="welcomeMessage"
                                className="form-control"
                                onChange={this.handleChange}
                                value={welcomeMessage}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="siteKeyWords">{Labels.site_key_words}</label>
                            <textarea
                                name="siteKeyWords"
                                id="siteKeyWords"
                                className="form-control"
                                onChange={this.handleChange}
                                value={siteKeyWords}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="javascript">{Labels.javascript}</label>
                            <textarea
                                name="javascript"
                                id="javascript"
                                className="form-control"
                                onChange={this.handleChange}
                                value={javascript}
                            >
                        </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="templatesColor">{Labels.templatesColor}</label>
                            <JsColor id="templatesColor"
                                     onChange={(selectedColor) => {
                                         this.setState({templatesColor: selectedColor})
                                     }}
                                     value={templatesColor}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contentColor">{Labels.contentColor}</label>
                            <JsColor id="contentColor"
                                     onChange={(selectedColor) => {
                                         this.setState({contentColor: selectedColor})
                                     }}
                                     value={contentColor}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="buttonsColor">{Labels.buttonsColor}</label>
                            <JsColor id="buttonsColor"
                                     onChange={(selectedColor) => {
                                         this.setState({buttonsColor: selectedColor})
                                     }}
                                     value={buttonsColor}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="periodForDailyMessages">
                                {Labels.messages}
                            </label>
                            <div className="input-span">
                                <input
                                    type="number"
                                    name="periodForDailyMessages"
                                    id="periodForDailyMessages"
                                    className="form-control fixed-width"
                                    onChange={this.handleChange}
                                    value={periodForDailyMessages}
                                    min={0}
                                />
                                <span>
                                {Labels.periodForDailyMessages}
                            </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="#">
                                {Labels.wall}
                            </label>
                            <div className="input-span">
                                <input
                                    type="number"
                                    name="likesNumber"
                                    id="likesNumber"
                                    className="form-control fixed-width"
                                    onChange={this.handleChange}
                                    value={likesNumber}
                                    min={0}
                                />
                                <span>
                                {Labels.likesNumber}
                            </span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    name="periodBetweenWallMessages"
                                    id="periodBetweenWallMessages"
                                    className="form-control fixed-width"
                                    onChange={this.handleChange}
                                    value={periodBetweenWallMessages}
                                    min={0}
                                />
                                <span>
                                {Labels.periodBetweenWallMessages}
                            </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="#">{Labels.guests}</label>
                            <div className="label-checkbox">
                                <input
                                    type="checkbox"
                                    id="allowGuestsToLogin"
                                    name="allowGuestsToLogin"
                                    value={allowGuestsToLogin}
                                    onChange={this.handleChange}
                                    checked={allowGuestsToLogin}
                                />
                                <label
                                    htmlFor="allowGuestsToLogin"
                                    className="no-style"
                                >
                                    {Labels.allow_guests_to_login}
                                </label>
                            </div>
                            <div className="label-checkbox">
                                <input
                                    type="checkbox"
                                    id="allowMemberingToSignup"
                                    name="allowMemberingToSignup"
                                    value={allowMemberingToSignup}
                                    onChange={this.handleChange}
                                    checked={allowMemberingToSignup}
                                />
                                <label
                                    htmlFor="allowMemberingToSignup"
                                    className="no-style"
                                >
                                    {Labels.allowMemberingToSignup}
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="likesNumberForPrivateMessages"
                                    value={likesNumberForPrivateMessages}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.likesNumberForPrivateMessages}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="likesNumberForAlerts"
                                    value={likesNumberForAlerts}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.likesNumberForAlerts}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="likesNumberForSendingImagesVideos"
                                    value={likesNumberForSendingImagesVideos}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.likesNumberForSendingImagesVideos}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="numberUsersFromSameIp"
                                    value={numberUsersFromSameIp}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.numberUsersFromSameIp}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="usernameMaxLength"
                                    value={usernameMaxLength}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.usernameMaxLength}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="generalRoomMaxLines"
                                    value={generalRoomMaxLines}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.generalRoomMaxLines}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="kickPeriod"
                                    value={kickPeriod}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.kickPeriod}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="giftMaxNumbers"
                                    value={giftMaxNumbers}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.giftMaxNumbers}</span>
                            </div>
                            <div className="input-span">
                                <input
                                    type="number"
                                    className="form-control fixed-width"
                                    name="smileMaxNumbers"
                                    value={smileMaxNumbers}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                                <span>{Labels.smileMaxNumbers}</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="file-input">
                                <div className='upload-file-progress' style={{
                                    width: Math.round(logoLoaded) + '%'
                                }}/>
                                <label htmlFor="logo" title={Labels.logo}>
                                    {Labels.logo}
                                </label>
                                <input
                                    type="file"
                                    name="logo"
                                    id="logo"
                                    onChange={this.handleUploadFile}
                                />
                                {
                                    logo && (
                                        <div className="img-preview mt">
                                            <img
                                                src={Config.image_path + logo}
                                                alt={Labels.logo}
                                                className="img-responsive"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="file-input">
                                <div className='upload-file-progress' style={{
                                    width: Math.round(bannerLoaded) + '%'
                                }}/>
                                <label htmlFor="banner" title={Labels.banner}>
                                    {Labels.banner}
                                </label>
                                <input
                                    type="file"
                                    name="banner"
                                    id="banner"
                                    onChange={this.handleUploadFile}
                                />
                                {
                                    banner && (
                                        <div className="img-preview mt">
                                            <img
                                                src={Config.image_path + banner}
                                                alt={Labels.banner}
                                                className="img-responsive"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="file-input">
                                <div className='upload-file-progress' style={{
                                    width: Math.round(defaultUserImageLoaded) + '%'
                                }}/>
                                <label htmlFor="defaultUserImage" title={Labels.defaultUserImage}>
                                    {Labels.defaultUserImage}
                                </label>
                                <input
                                    type="file"
                                    name="defaultUserImage"
                                    id="defaultUserImage"
                                    onChange={this.handleUploadFile}
                                />
                                {
                                    defaultUserImage && (
                                        <div className="img-preview mt">
                                            <img
                                                src={Config.image_path + defaultUserImage}
                                                alt={Labels.defaultUserImage}
                                                className="img-responsive"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="file-input">
                                <div className='upload-file-progress' style={{
                                    width: Math.round(defaultRoomImageLoaded) + '%'
                                }}/>
                                <label htmlFor="defaultRoomImage" title={Labels.defaultRoomImage}>
                                    {Labels.defaultRoomImage}
                                </label>
                                <input
                                    type="file"
                                    name="defaultRoomImage"
                                    id="defaultRoomImage"
                                    onChange={this.handleUploadFile}
                                />
                                {
                                    defaultRoomImage && (
                                        <div className="img-preview mt">
                                            <img
                                                src={Config.image_path + defaultRoomImage}
                                                alt={Labels.defaultRoomImage}
                                                className="img-responsive"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success btn-save">
                                <FaCheck/>
                                {Labels.save}
                            </button>
                        </div>
                    </form>
                    <IconsListingWithUpload icons={iconsShortcuts}
                                            type={Constants.shortcut_types.super_icon}
                                            typeIndex="shortcut_type"
                                            name="sawaber"
                                            onLoadIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleLoadIconSuccess={this.handleLoadIconSuccess}
                                            handleLoadIconFailed={this.handleLoadIconFailed}
                                            onRemoveIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleRemoveIconSuccess={this.handleRemoveIconSuccess}
                                            handleRemoveIconFailed={this.handleRemoveIconFailed}
                                            uploadFolder={Constants.super_images}
                                            user={user}
                    />
                    <IconsListingWithUpload icons={iconsShortcuts}
                                            type={Constants.shortcut_types.gift_icon}
                                            typeIndex="shortcut_type"
                                            name="gifts"
                                            onLoadIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleLoadIconSuccess={this.handleLoadIconSuccess}
                                            handleLoadIconFailed={this.handleLoadIconFailed}
                                            onRemoveIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleRemoveIconSuccess={this.handleRemoveIconSuccess}
                                            handleRemoveIconFailed={this.handleRemoveIconFailed}
                                            uploadFolder={Constants.gifts_images}
                                            user={user}
                    />
                    <IconsListingWithUpload icons={iconsShortcuts}
                                            type={Constants.shortcut_types.smile_icon}
                                            typeIndex="shortcut_type"
                                            name="smiles"
                                            onLoadIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleLoadIconSuccess={this.handleLoadIconSuccess}
                                            handleLoadIconFailed={this.handleLoadIconFailed}
                                            onRemoveIcon={() => {
                                                this.setState({loading: true})
                                            }}
                                            handleRemoveIconSuccess={this.handleRemoveIconSuccess}
                                            handleRemoveIconFailed={this.handleRemoveIconFailed}
                                            uploadFolder={Constants.smiles_images}
                                            user={user}
                    />
                </div>
            </div>
        );
    }
}

export default SiteAdministration;
