const Constants = require('./constants');


const fileType = (ext) => {
    const imageExts = ['pe', 'jpg', 'jpeg', 'gif', 'png', 'bmp', 'ico', 'svg', 'svgz', 'tif', 'tiff', 'ai', 'drw', 'pct', 'psp', 'xcp', 'psd', 'raw'];
    const videoExts = ['avi', 'divx', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg', 'mpg', 'ogm', 'ogv', 'ogx', 'rm', 'rmvb', 'smil', 'webm', 'wmv', 'xvid'];
    const recordExts = ['acc', 'aif', 'flac', 'iff', 'm4a', 'm4b', 'mid', 'midi', 'mp3', 'mpa', 'mpc', 'oga', 'ogg', 'ra', 'ram', 'snd', 'wav', 'wma'];

    if (imageExts.indexOf(ext.toLowerCase()) > -1) {
        return Constants.media.image;
    }

    if (videoExts.indexOf(ext.toLowerCase()) > -1) {
        return Constants.media.video;
    }

    if (recordExts.indexOf(ext.toLowerCase()) > -1) {
        return Constants.media.record;
    }

    return false;
};

module.exports = {
    fileType
};
