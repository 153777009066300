import {axiosInstance} from '../../axios';
import Constants from "../../constants";

export const createShortcut = (word, replacement, type) => {
    type = type || Constants.shortcut_types.string;

    const data = {
        word,
        replacement,
        shortcut_type: type
    };

    return axiosInstance
        .post('/admin/shortcuts', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteShortcut = (shortcutId) => {
    return axiosInstance
        .delete('admin/shortcuts/' + shortcutId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
