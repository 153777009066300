import React, {Component} from 'react';
import {FaTimes} from "react-icons/fa";
import Labels from '../../labels';
import {deleteSubscription} from "../../apis/admin/subscriptions";
import Spinner from "../functional/Spinner";
import {getMembers} from "../../apis/admin/members";
import moment from "moment";
import _ from "lodash";
import Constants from "../../constants";
import '../../assets/sass/Subscriptions.scss';

class Subscriptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscriptions: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        getMembers(null, null, true)
            .then((response) => {
                let membersData = response.data || {};
                let users = membersData.results || [];
                let subscriptions = [];

                for (let user of users) {
                    const actualUser = user.user;
                    const groups = actualUser.groups;
                    const group = groups[0];

                    if (!group || (group && group.name === Constants.defaultGroup)) {
                        continue;
                    }

                    const groupInfo = group.group_info || {};
                    const userGroupDeadlines = actualUser.user_group_deadlines;
                    const groupDeadline = _.find(userGroupDeadlines, (groupDeadline) => {
                        return groupDeadline.group_id === group.id
                    });
                    const todayDate = moment();
                    const deadLineDate = moment(groupDeadline.deadline);
                    const remainingDays = deadLineDate.diff(todayDate, 'days');

                    const subscription = {
                        id: user.id,
                        groupName: group.name,
                        username: actualUser.username,
                        nickname: user.nick_name,
                        period: groupDeadline.period,
                        remainingDays: remainingDays,
                        groupId: group.id,
                        groupOrder: groupInfo.order || -999,
                        deadline: groupDeadline.deadline
                    };

                    subscriptions.push(subscription);
                }

                this.setState({
                    subscriptions,
                    loading: false
                })
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    }

    removeSubscription = (id, groupId, userId) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_subscription);

        if (confirm) {
            this.setState({
                loading: true
            });

            deleteSubscription(groupId, userId)
                .then(() => {
                    let {subscriptions} = this.state;
                    let newSubscriptions = subscriptions.filter((subscription) => {
                        return subscription.id !== id;
                    });

                    this.setState({
                        subscriptions: newSubscriptions,
                        loading: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });

                    console.error(error);
                });
        }
    };

    componentWillUnmount() {
    }

    render() {
        const {subscriptions, loading} = this.state;
        const {user} = this.props;
        const userGroups = user.groups;
        const userGroup = userGroups[0] || {};
        const userGroupInfo = userGroup.group_info || {};

        return (
            <div className="subscriptions">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="subscriptions-content">
                    {
                        subscriptions.length > 0 && (
                            <table>
                                <thead>
                                <tr>
                                    <th>{Labels.subscription}</th>
                                    <th>{Labels.member}</th>
                                    <th>{Labels.nick_name}</th>
                                    <th>{Labels.period}</th>
                                    <th>{Labels.remaining_days}</th>
                                    <th>{Labels.remove}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    subscriptions.map((subscription) => {
                                        return (
                                            <tr key={subscription.id}>
                                                <td>{subscription.groupName}</td>
                                                <td>{subscription.username}</td>
                                                <td>{subscription.nickname}</td>
                                                <td>{subscription.period}</td>
                                                <td>{subscription.remainingDays}</td>
                                                <td>
                                                    {
                                                        userGroupInfo.order >= subscription.groupOrder && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger"
                                                                onClick={() => {
                                                                    this.removeSubscription(subscription.id, subscription.groupId, subscription.id)
                                                                }}
                                                            >
                                                                <FaTimes/>
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        userGroupInfo.order < subscription.groupOrder && (
                                                            '--'
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        )
                    }
                    {
                        subscriptions.length <= 0 && (
                            <div className="alert alert-info">
                                {Labels.no_subscriptions}
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Subscriptions;
