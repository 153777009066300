import React, {Component} from 'react';
import Labels from '../../labels';
import {getStatuses, searchStatuses} from "../../apis/admin/statuses";
import Spinner from "../functional/Spinner";
import {displayStatues, getOffsetValue} from "../../functions";

class Statuses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statuses: [],
            searchedStatuses: [],
            loading: false,
            limit: 20,
            offset: 0,
            showLoadMore: true,
            searchTerm: '',
            isSearch: false
        }
    }

    componentDidMount() {
        this.loadMore();
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    loadMore = () => {
        const {limit, offset} = this.state;

        this.setState({
            loading: true
        });

        getStatuses(limit, offset)
            .then((response) => {
                const responseData = response.data || {};
                const statuses = responseData.results;
                const next = responseData.next;
                const offset = getOffsetValue(next);

                this.setState({
                    loading: false,
                    statuses: this.state.statuses.concat(statuses),
                    offset: offset ? offset : this.state.offset,
                    showLoadMore: !!next
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            })
    };

    searchForStatuses = (e) => {
        e.preventDefault();

        const {searchTerm, isSearch} = this.state;

        if (searchTerm.trim()) {
            this.setState({
                loading: true
            });

            searchStatuses(searchTerm)
                .then((response) => {
                    const data = response.data;
                    const results = data.results;

                    this.setState({
                        loading: false,
                        searchedStatuses: results,
                        isSearch: true
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                })
        } else {
            if (isSearch) {
                this.closeSearch();
            }
        }
    };

    closeSearch = () => {
        this.setState({
            isSearch: false,
            searchTerm: ''
        })
    };

    render() {
        const {
            loading,
            statuses,
            showLoadMore,
            searchedStatuses,
            isSearch,
            searchTerm
        } = this.state;

        return (
            <div className="statuses">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="search-box">
                    <form onSubmit={this.searchForStatuses} className="flex-row">
                        <input type="text"
                               className="form-control"
                               placeholder={Labels.search_status}
                               name="searchTerm"
                               value={searchTerm}
                               onChange={this.handleChange}
                        />
                        {
                            isSearch && (
                                <button type="button" className="btn btn-sm btn-danger" onClick={this.closeSearch}>
                                    X
                                </button>
                            )
                        }
                        <button type="submit" className="btn btn-trikwaz">{Labels.do_search}</button>
                    </form>
                </div>
                <div className="statuses-content">
                    <table>
                        <thead>
                        <tr>
                            <th>{Labels.status}</th>
                            <th>{Labels.member}</th>
                            <th>{Labels.next_member}</th>
                            <th>{Labels.rooms_1}</th>
                            <th>{Labels.time}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isSearch && searchedStatuses.length === 0 && (
                                <tr>
                                    <td colSpan="6">
                                        <div className="alert alert-danger">{Labels.no_members}</div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            isSearch && searchedStatuses.length > 0 && (
                                displayStatues(searchedStatuses)
                            )
                        }
                        {
                            !isSearch && statuses.length > 0 && (
                                displayStatues(statuses)
                            )
                        }
                        </tbody>
                    </table>
                    {
                        !loading && showLoadMore && !isSearch && (
                            <div className="text-center load-more">
                                <button type="button" className="btn btn-trikwaz" onClick={this.loadMore}>
                                    {Labels.loadMore}
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Statuses;
