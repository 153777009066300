import React, {Component} from 'react';
import Events from '../../events';
import Labels from '../../labels';
import {createRoom, updateRoom, deleteRoom, decreasePermissionCount} from '../../apis/chat';
import {FaCheck, FaTimes} from "react-icons/fa";
import {getPermissionIdByCode, prepareRooms, userPermissions} from "../../functions";
import Constants from "../../constants";
import Spinner from "../functional/Spinner";
import '../../assets/sass/CreateRoomForm.scss';
import {addStatus} from "../../apis/admin/statuses";

class CreateRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomName: '',
            roomDescription: '',
            roomWelcomeMessage: '',
            roomPassword: '',
            roomSize: '',
            roomActivation: false,
            loading: false
        }
    }

    componentDidMount() {
        const {task, activeRoom} = this.props;

        if (task === Constants.tasks.edit) {
            const roomName = activeRoom.nick_name || '';
            const roomDescription = activeRoom.description || '';
            const roomWelcomeMessage = activeRoom.welcome_message || '';
            const roomSize = activeRoom.seats || 2;

            this.setState({
                roomName,
                roomDescription,
                roomWelcomeMessage,
                roomSize
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        const {roomActivation} = this.state;

        if (roomActivation) {
            const {user} = this.props;
            const permissionId = getPermissionIdByCode(Constants.permissions.max_room_limit, user.id);

            decreasePermissionCount(permissionId, user.id)
                .then(() => {
                    this.handleCreateUpdateRoom();
                }, (error) => {
                    const {setNotificationMessage} = this.props;

                    setNotificationMessage(Labels.you_exceed_number_of_tries_for_constant_rooms);

                    this.setState({
                        loading: false
                    });

                    console.error(error);
                });
        } else {
            this.handleCreateUpdateRoom();
        }
    };

    deleteRoomFunc = () => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_room);

        if (confirm) {
            this.setState({
                loading: true
            });

            const {socket, closeModal, activeRoom, user} = this.props;
            const chatUser = user.chatuser || {};

            deleteRoom(activeRoom.id)
                .then(() => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.remove_room,
                        room: null
                    }).then(() => {
                        socket.emit(Events.DELETE_ROOM, activeRoom.id);

                        closeModal();

                        this.setState({
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    handleCreateUpdateRoom() {
        const {socket, closeModal, task, activeRoom, user} = this.props;
        const chatUser = user.chatuser || {};

        const {
            roomName, roomDescription, roomWelcomeMessage,
            roomPassword, roomSize, roomActivation
        } = this.state;

        if (task === Constants.tasks.edit) {
            updateRoom(activeRoom.id, roomName, roomDescription, roomWelcomeMessage, roomPassword, roomSize)
                .then((response) => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.update_room,
                        room: null
                    }).then(() => {
                        const room = response.data;

                        socket.emit(Events.UPDATE_ROOM, room);

                        this.setState({
                            loading: false
                        });

                        closeModal();
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        } else {
            const data = {
                roomName,
                roomDescription,
                roomWelcomeMessage,
                roomPassword,
                roomSize,
                roomActivation: !!roomActivation
            };

            createRoom(data)
                .then((response) => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: !!roomActivation ? Labels.create_const_room : Labels.create_unconst_room,
                        room: null
                    }).then(() => {
                        const {changeActiveRoom} = this.props;
                        const room = prepareRooms(response.data);

                        socket.emit(Events.ADD_ROOM, room, () => {
                            changeActiveRoom(room.id);
                        });

                        this.setState({
                            loading: false
                        });

                        closeModal();
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    }

    isAllRequiredFieldsCompleted = () => {
        const {roomName, roomDescription, roomWelcomeMessage, roomSize} = this.state;

        return roomName && roomDescription && roomWelcomeMessage && roomSize;
    };

    render() {
        const {
            roomName, roomDescription, roomWelcomeMessage,
            roomPassword, roomSize, roomActivation, loading
        } = this.state;

        const {task, buttonsColor, user} = this.props;

        return (
            <form
                onSubmit={this.handleSubmit}
                className={'create-room-form'}
            >
                {
                    loading && (
                        <Spinner/>
                    )
                }

                <div className="form-group">
                    <label
                        htmlFor="roomName"
                        className={'fill-label'}
                        style={{
                            background: buttonsColor
                        }}
                    >
                        {Labels.room_name}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={Labels.room_name}
                        id="roomName"
                        name="roomName"
                        value={roomName}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="roomDescription"
                        className={'fill-label'}
                        style={{
                            background: buttonsColor
                        }}
                    >
                        {Labels.room_description}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={Labels.room_description}
                        id="roomDescription"
                        name="roomDescription"
                        value={roomDescription}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="roomWelcomeMessage"
                        className={'fill-label'}
                        style={{
                            background: buttonsColor
                        }}
                    >
                        {Labels.room_welcome_message}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={Labels.room_welcome_message}
                        id="roomWelcomeMessage"
                        name="roomWelcomeMessage"
                        value={roomWelcomeMessage}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="roomPassword"
                        className={'fill-label'}
                        style={{
                            background: buttonsColor
                        }}
                    >
                        {Labels.room_password}
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder={Labels.room_password}
                        id="roomPassword"
                        name="roomPassword"
                        value={roomPassword}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="roomSize"
                        className={'fill-label'}
                        style={{
                            background: buttonsColor
                        }}
                    >
                        {Labels.room_size_1}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder={Labels.room_size}
                        id="roomSize"
                        name="roomSize"
                        value={roomSize}
                        onChange={this.handleChange}
                        min="2"
                        max="40"
                    />
                </div>
                {
                    !task && userPermissions(Constants.permissions.max_room_limit, user.id) && (
                        <div className="form-group">
                            <label htmlFor="roomActivation">
                                <input type="checkbox"
                                       id="roomActivation"
                                       name="roomActivation"
                                       onChange={this.handleChange}
                                       checked={roomActivation}
                                />&nbsp;
                                {Labels.room_activation}
                            </label>
                        </div>
                    )
                }

                <div className="actions">
                    <button
                        type="submit"
                        className={`btn btn-sm btn-success${!task && !this.isAllRequiredFieldsCompleted() ? ' disabled': ''}`}
                    >
                        {
                            task && task === Constants.edit && (
                                <span>
                                    {Labels.save_changes}
                                    <FaCheck/>
                                </span>
                            )
                        }
                        {
                            !task && (
                                Labels.create
                            )
                        }
                    </button>
                    {
                        task && task === Constants.edit && (
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={this.deleteRoomFunc}

                            >
                                {Labels.remove}
                                <FaTimes/>
                            </button>
                        )
                    }
                </div>
            </form>
        );
    }
}

export default CreateRoom;
