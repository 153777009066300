import React, {Component} from 'react';
import {FaCheck, FaExclamationTriangle, FaTimes} from "react-icons/fa";
import axios from 'axios';
import Labels from '../../labels';
import {getFilterWords, addFilterWord, deleteFilterWord, getFilterWordsMessages} from '../../apis/admin/filter'
import _ from 'lodash';
import '../../assets/sass/Filter.scss';
import Constants from "../../constants";
import WordsFilter from "../WordsFilter";
import Spinner from "../functional/Spinner";
import {addStatus} from "../../apis/admin/statuses";

class Filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            words: [],
            successMessage: '',
            error: '',
            word: '',
            allowedMessages: [],
            blockedMessages: [],
            trackedMessages: [],
            loading: false
        };
    }

    componentDidMount() {
        getFilterWords()
            .then((response) => {
                const words = response.data;

                if (words.length) {
                    this.setState({
                        words
                    });
                }
            }, (error) => {
                console.error(error);
            });

        axios.all([
            getFilterWordsMessages(Constants.allowed),
            getFilterWordsMessages(Constants.blocked),
            getFilterWordsMessages(Constants.tracked)
        ]).then(axios.spread((allowedResponse, blockedResponse, trackedResponse) => {
            this.setState({
                allowedMessages: allowedResponse.data.results,
                blockedMessages: blockedResponse.data.results,
                trackedMessages: trackedResponse.data.results
            })
        }, (errors) => {
            console.error(errors);
        }));
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    };

    addToWords = (category) => {
        this.setState({
            error: '',
            successMessage: ''
        });

        const {word} = this.state;

        if (!word.trim()) {
            this.setError(Labels.all_fields_required);

            return;
        }

        this.setState({
            loading: true
        });

        addFilterWord(word.trim(), category)
            .then((response) => {
                const {user} = this.props;
                const chatUser = user.chatuser || {};

                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: null,
                    action: word.trim() + Labels.adding_filter_word[category] + Labels.adding_filter_word1,
                    room: null
                }).then(() => {
                    const word = response.data;

                    let newWords = _.cloneDeep(this.state.words);

                    newWords.push(word);

                    this.setState({
                        words: newWords
                    });

                    this.setState({
                        successMessage: Labels.word_added_successfully,
                        word: '',
                        loading: false
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    removeWord = (id) => {
        this.setState({
            error: '',
            successMessage: ''
        });

        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_word);

        if (confirm) {
            this.setState({
                loading: true
            });

            deleteFilterWord(id)
                .then(() => {
                    const {user} = this.props;
                    const chatUser = user.chatuser || {};

                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: null,
                        action: Labels.remove_word,
                        room: null
                    }).then(() => {
                        const {words} = this.state;
                        const newWords = words.filter((word) => {
                            return parseInt(word.id) !== parseInt(id);
                        });

                        this.setState({
                            words: newWords,
                            successMessage: Labels.word_removed_successfully,
                            loading: false
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            loading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
        }
    };

    setError = (error) => {
        this.setState({
            error
        });
    };

    render() {
        const {
            successMessage,
            error,
            words,
            word,
            allowedMessages,
            blockedMessages,
            trackedMessages,
            loading
        } = this.state;

        return (
            <div className="filter">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="filter-content">
                    {
                        successMessage && (
                            <div className="alert alert-success">
                                {successMessage}
                            </div>
                        )
                    }
                    {
                        error && (
                            <div className="alert alert-danger">
                                {error}
                            </div>
                        )
                    }
                    <div className="form-group">
                        <input
                            type="text"
                            name="word"
                            className="form-control"
                            placeholder={Labels.add_word_site}
                            onChange={this.handleChange}
                            value={word}
                        />
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                                this.addToWords(Constants.allowed)
                            }}
                        >
                            <FaCheck/>
                            {Labels.add_to_allowed_words}
                        </button>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                this.addToWords(Constants.blocked)
                            }}
                        >
                            <FaTimes/>
                            {Labels.add_to_denied_words}
                        </button>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => {
                                this.addToWords(Constants.tracked)
                            }}
                        >
                            <FaExclamationTriangle/>
                            {Labels.add_to_monitored_words}
                        </button>
                    </div>

                    <WordsFilter messages={allowedMessages} title={Labels.allowed_words}/>
                    <WordsFilter messages={blockedMessages} title={Labels.blocked_words}/>
                    <WordsFilter messages={trackedMessages} title={Labels.tracked_words}/>

                    <div className="form-group">
                        <strong>{Labels.words}</strong>
                    </div>
                    <div className="form-group">
                        <table>
                            <thead>
                            <tr>
                                <th>{Labels.category}</th>
                                <th>{Labels.word}</th>
                                <th>{Labels.remove}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                words.length > 0 && (
                                    words.map((word) => {
                                        return (
                                            <tr key={word.id}>
                                                <td>{Labels[word.category]}</td>
                                                <td>{word.word}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => {
                                                            this.removeWord(word.id)
                                                        }}
                                                    >
                                                        <FaTimes/>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Filter;
