import {axiosInstance} from '../../axios';
import moment from "moment";

export const getBlockedIps = () => {
    return axiosInstance
        .get('admin/ip_addresses/blocked?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getBlockedCountries = () => {
    return axiosInstance
        .get('admin/countries/blocked?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const blockIp = (ip, id) => {
    const payload = {
        ip_address: ip,
        chat_user: id
    };

    return axiosInstance
        .post('admin/ip_addresses/block', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const blockCountry = (country) => {
    return axiosInstance
        .post('admin/countries/block', {country})
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const removeBlock = (block) => {
    let url, data;

    if (block.type === 'ip') {
        url = 'admin/ip_addresses/allow';
        data = {
            ip_address: block.block
        }
    } else {
        url = 'admin/countries/allow';
        data = {
            country: block.block
        }
    }

    return axiosInstance
        .post(url, data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getBlockedDevices = (device) => {
    let url = 'devices/blocked?limit=1000';

    if (device) {
        url = 'devices/blocked?device_code=' + device + '&limit=1000';
    }

    return axiosInstance
        .get(url)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const blockDevice = (device, userAgent, username) => {
    const data = {
        device_code: device,
        device_name: userAgent,
        until_date: moment().format('YYYY-MM-DD'),
        username
    };

    return axiosInstance
        .post('admin/devices/block', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const allowDevice = (device) => {
    const data = {
        device_code: device,
    };

    return axiosInstance
        .post('admin/devices/allow', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};