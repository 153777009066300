import {axiosInstance} from '../../axios';

export const getSiteSettings = () => {
    return axiosInstance
        .get('site/settings')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const setSiteSettings = (data) => {
    return axiosInstance
        .post('site/settings', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
