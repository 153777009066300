import {axiosInstance} from '../../axios';

export const getSubscriptions = () => {
    return axiosInstance
        .get('admin/users/groups/deadlines?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteSubscription = (groupId, userId) => {
    const payload = {
        groups: {
            remove: [groupId]
        }
    };

    return axiosInstance
        .post('admin/users/' + userId + '/groups', payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};