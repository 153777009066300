const Labels = {
    username: 'اسم العضو',
    password: 'كلمة المرور',
    entrance: 'دخول',
    username_required: 'اسم العضو حقل مطلوب.',
    username_guest_required: 'اسم الزائر حقل مطلوب.',
    password_required: 'كلمة المرور حقل مطلوب.',
    user_already_connected: 'هذا المستخدم متصل مسبقا.',
    connected: 'المتواجدون',
    private_conversations: 'المحادثات الخاصة',
    rooms: 'غرف الدردشة',
    rooms_1: 'الغرف',
    wall: 'الحائط',
    settings: 'الاعدادات',
    entrance_message: 'هذا المستخدم قد دخل',
    leave_message: 'هذا المستخدم انتقل الى',
    leave_room: 'خروج من الغرفة',
    user_logged_out: '(تسجيل خروج)',
    write_your_message_here: 'أكتب رسالتك هنا',
    welcome_message: 'رسالة ترحيبية',
    app_name: 'My Chat App',
    register: 'تسجيل عضوية',
    registration: 'تسجيل',
    members_login: 'دخول الأعضاء',
    guests_login: 'دخول الزوار',
    guest_name: 'اسم الزائر',
    guest: 'زائر',
    connected_now: 'المتواجدون الآن',
    no_connected_users: 'لا يوجد أعضاء!',
    connected_in_room: 'المتواجدون في الغرفة',
    connected_in_chat: 'المتواجدون في الدردشة',
    'Unable to log in with provided credentials.': 'يوجد خطأ في المعلومات المدخلة.',
    'This user already exist': 'هذا الاسم مستخدم مسبقا.',
    'Room password is wrong': 'خطأ في كلمو المرور.',
    create_new_room: 'إنشاء غرفة جديدة',
    new_room: 'غرفة جديدة',
    room_name: 'إسم الغرفة',
    room_description: 'الوصف',
    room_welcome_message: 'الرسالة الترحيبية',
    room_password: 'كلمة المرور',
    room_size: 'حجم الغرفة من ٢ الى ٤٠',
    room_activation: 'تثبيت الغرفة',
    create: 'إنشاء',
    enter_room_password: 'أدخل كلمة المرور للدخول',
    nick_name: 'الزخرفة',
    save: 'حفظ',
    bio: 'الحالة',
    new_member: 'عضو جديد',
    new_guest: 'زائر',
    name_color: 'لون الإسم',
    font_color: 'لون الخط',
    bg_color: 'لون الخلفية',
    font_size: 'حجم الخط',
    font_size_120: 'حجم الخط %120',
    font_size_110: 'حجم الخط %110',
    font_size_105: 'حجم الخط %105',
    font_size_100: 'حجم الخط %100',
    font_size_95: 'حجم الخط %95',
    font_size_90: 'حجم الخط %90',
    change_user_image: 'تغير الصورة',
    deactivate_private_messages: 'تعطيل المحادثات الخاصة',
    logout: 'تسجيل الخروج',
    personal_profile: 'الملف الشخصي',
    remove_image: 'حذف الصورة',
    send_adv: 'ارسال إعلان',
    deactivate_alerts: 'تعطيل التنبيهات',
    admin_panel: 'لوحة التحكم',
    warning: 'تنبيه',
    your_request_ignored: 'تم تجاهل طلبك.',
    no_private_conversations: 'لا يوجد محادثات خاصة!',
    no_active_room: 'لا يوجد غرفة نشطة!',
    remove: 'حذف',
    now: 'الآن',
    minute: 'د',
    hours: 'س',
    private_conversation: 'محادثة خاصة',
    ignore: 'تجاهل',
    share: 'مشاركة',
    room_size_1: 'حجم الغرفة',
    welcome_1: 'أهلا بك في',
    search: 'البحث...',
    record: 'السجل',
    statuses: 'الحالات',
    members: 'الأعضاء',
    block: 'الحظر',
    permissions: 'الصلاحيات',
    filter: 'فلتر',
    shortcuts: 'الاختصارات',
    subscriptions: 'الاشتراكات',
    messages: 'الرسائل',
    site_admin: 'ادارة الموقع',
    search_record: 'ابحث في السجل',
    search_status: 'ابحث في الحالات',
    status: 'الحالة',
    member: 'عضو',
    the_member: 'العضو',
    ip: 'الآي بي',
    country: 'الدولة',
    device: 'الجهاز',
    next_member: 'العضو الثاني',
    time: 'الوقت',
    lastLoginDate: 'آخر تواجد',
    registerDate: 'التسجيل',
    search_members: 'البحث اسم العضو/ الآي بي/ الجهاز',
    permission: 'الصلاحية',
    period: 'المدة',
    likes: 'اللايكات',
    change_password: 'تغيير كلمة السر',
    new_password: 'كلمة السر الجديدة',
    remove_member_1: 'حذف العضوية',
    addison: 'اضافة',
    add_block_placeholder: 'اسم الجهاز/ الدولة/ الآي بي',
    block_date: 'تاريخ الحظر',
    add_new_permission: 'ترفقة [10]',
    sorting: 'الترتيب',
    set_name: 'اسم المجموعة',
    icon: 'الأيقونة',
    block_user: 'الطرد',
    change_nickname: 'تغيير النك',
    activate: 'تفعيل',
    notification: 'التنبيهات',
    manage_nicknames: 'تغيير النكات',
    advertisement: 'الاعلانات',
    open_private: 'فتح الخاص',
    manage_rooms: 'ادارة الغرف',
    create_room: 'انشاء الغرف',
    max_room_limit: 'أقصى حد للغرف الثابتة',
    manage_member: 'أدارة العضويات',
    manage_permission: 'تعديل الصلاحيات',
    gifts: 'الهدايا',
    open_nicknames: 'كشف النكات',
    control_panel: 'لوحة التحكم',
    hide: 'مخفي',
    manage_website: 'ادارة الموقع',
    room_owner: 'صاحب الغرفة',
    shortcut_name: 'الاختصار / س١',
    shortcut_name_1: 'الاختصار',
    shortcut_nickname: 'الزخرفة / السلام عليكم',
    shortcut_nickname_1: 'الزخرفة',
    addition: 'اضافة',
    delete: 'حذف',
    all_fields_required: 'كل الحقول مطلوبة',
    are_you_sure_you_want_to_delete_shortcut: 'هل انت متأكد من حذف الاختصار؟',
    site_settings: 'اعدادات الموقع',
    site_name: 'اسم الموقع',
    page_title: 'عنوان الصفحة',
    site_description: 'وصف الموقع',
    site_key_words: 'الكلمات الدلالية',
    javascript: 'السكربت',
    templatesColor: 'لون القوالب',
    contentColor: 'لون المحتوى',
    buttonsColor: 'لون الازرار',
    periodForDailyMessages: 'المده بالدقائق للرسائل اليومية',
    likesNumber: 'عدد اللايكات',
    periodBetweenWallMessages: 'المده بين رسائل الحائط بالدقيقة',
    guests: 'الزوار',
    allow_guests_to_login: 'السماح بدخول الزوار',
    allowMemberingToSignup: 'السماح بتسجيل العضويات',
    likesNumberForPrivateMessages: 'عدد اللايكات المسوحه للمحادثات الخاصة',
    likesNumberForAlerts: 'عدد اللايكات المسوحه للتنبيهات',
    likesNumberForSendingImagesVideos: 'عدد اللايكات المسوحه لارسال صور / فيديو',
    saved_successfully: 'تم الحفظ بنجاح',
    messageTitle: 'عنوان الرسالة',
    messageContent: 'الرسالة',
    add_to_welcome_messages: 'اضف الى رسائل الترحيب',
    add_to_daily_messages: 'اضف الى رسائل اليومية',
    category: 'التصنيف',
    title: 'العنوان',
    message: 'الرسالة',
    are_you_sure_you_want_to_delete_message: 'هل انت متأكد من حذف الرسالة؟',
    are_you_sure_you_want_to_delete_subscription: 'هل انت متأكد من حذف الاشتراك؟',
    are_you_sure_you_want_to_delete_block: 'هل انت متأكد من حذف الحضر؟',
    are_you_sure_you_want_to_delete_room: 'هل انت متأكد من حذف الغرفة؟',
    are_you_sure_you_want_to_delete_word: 'هل انت متأكد من حذف الكلمة؟',
    subscription: 'الاشتراك',
    remaining_days: 'الايام المتبقية',
    room_deletedSuccessfully: 'تم حذف الغرفة بنجاح',
    add_word_site: 'اضافة كلمة / موقع',
    word_added_successfully: 'تم اضافة الكلمة بنجاح',
    add_to_allowed_words: 'اضف الى الكلمات المسموحة',
    add_to_denied_words: 'اضف الى الكلمات الممنوعة',
    add_to_monitored_words: 'اضف الى الكلمات المراقبة',
    words: 'الكلمات',
    word: 'الكلمة',
    word_removed_successfully: 'تم حذف الكلمة بنجاح',
    ALLOWED: 'مسموحة',
    BLOCKED: 'ممنوعة',
    TRACKED: 'مراقبة',
    bands: 'الباند',
    band_1: 'باند',
    allowed_words: 'الكلمات المسموحة',
    blocked_words: 'الكلمات الممنوعة',
    tracked_words: 'الكلمات المراقبة',
    user: 'user: ',
    welcome: 'رسائل الترحيب',
    daily: 'الرسائل اليومية',
    daily_message: 'رسالة يومية',
    sawaber: 'ايقونات السوابر',
    gifts_icons: 'ايقونات الهدايا',
    smiles: 'الابتسامات',
    set_name_is_required: 'اسم المجموعة حقل مطلوب',
    F: 'ف',
    view_nicks: 'كشف النكات',
    send_gift: 'أرسل هدية',
    expulsion: 'طرد',
    expulsion_from_room: 'طرد من الغرفة',
    change: 'تغيير',
    period_in_days: 'المده بالأيام',
    room_administration: 'ادارة الغرفة',
    save_changes: 'حفظ التغيرات',
    remove_gift: 'حذف الهدية',
    are_you_sure_you_want_to_delete_permissions_group: 'هل انت متأكد من حدف المجموعة؟',
    set_name_already_exist: 'اسم المجموعه موجود مسبقا',
    this_field_required: 'هذا الحقل مطلوب',
    invalid_country_code_or_ip: 'خطا في العنوان او الدولة',
    'IP Address is blocked': 'انت محظور من الدردشه، حاول الدخول لاحقا',
    enter_warning_message: 'اكتب رسالتك',
    app: 'التطبيق',
    rules: 'القوانين',
    member_connected: 'متصل',
    member_name_here: 'اسم العضو هنا',
    delete_wall: 'حذف الحائط',
    adv: 'إعلان',
    select_permissions_group: 'اختر مجموعة الصلاحيات',
    hidden_login: 'دخول مخفي',
    you_dont_have_enough_likes: 'لا تملك عدد كافي من اللايكات.',
    you_exceed_number_of_tries_for_block_user: 'لقد تجاوزت الحد الاقصى لعمليات الطرد.',
    you_exceed_number_of_tries_for_send_adds: 'لقد تجاوزت الحد الاقصى لارسال الاعلانات.',
    you_exceed_number_of_tries_for_send_gifts: 'لقد تجاوزت الحد الاقصى لارسال الهدايا.',
    you_exceed_number_of_tries_for_constant_rooms: 'لقد تجاوزت الحد الاقصى للغرف الثابته.',
    group_days: 'عدد الأيام',
    check_selected_group_or_group_days_value: 'الرجاء التاكد من المجموعه وعدد الايام',
    password_changed: 'تم تغير كلمة المرور بنجاح',
    no_subscriptions: 'لا يوجد اشتراكات حاليا',
    something_went_wrong: 'حدث خطا, الرجاء اعادة المحاوله لاحقا!',
    gift_message: 'هدية',
    notification_title: 'تنبيه',
    you_got_like: 'لقد حصلت على اعجاب',
    you_can_make_an_only_like_within_one_minute: 'يمكنك ارسال اعجاب مره واحده بالدقيقه',
    remove_gift_message: 'تم حذف الهدية',
    nickname_changed_message: 'تم تغيير اسمك',
    changing_group_message: 'تم ترقية العضو',
    deactivate_hidden_login: 'تعطيل الدخول المخفي',
    activate_hidden_login: 'تفعيل الدخول المخفي',
    user_leave_room: '(هذا المستخدم غادر الغرفة)',
    you_Expelled_from_this_room: 'انت مطرود من هذه الغرفه',
    send_notification_member_to_member: 'ارسال تنبيه عضو لعضو',
    remove_image_member_to_member: 'حذف صورة من عضو لعضو',
    open_nicknames_member_to_member: 'كشف النكات من عضو لعضو',
    change_permission_member_to_member: 'تغيير صلاحية من عضو لعضو',
    remove_wall_message_member_to_member: 'حذف رساله عضو معين بالحائط',
    create_const_room: 'انشاء غرفه ثابته',
    create_unconst_room: 'انشاء غرفه مؤقته',
    remove_room: 'حذف غرفه',
    update_room: 'تعديل غرفه',
    change_likes: 'تغيير الللايكات',
    grant_permission: 'تغيير صلاحيات',
    remove_user: 'حذف عضوية',
    adding_band_ip: 'اضافة باند - IP',
    adding_band_country: 'اضافة باند - دولة',
    adding_band_device: 'اضافة باند - جهاز',
    remove_band: 'حذف باند',
    create_group: 'اضافة صلاحية',
    update_group: 'تعديل صلاحية ',
    remove_group: 'حذف صلاحية',
    adding_filter_word1: 'اضافة كلمة ',
    adding_filter_word: {
        ALLOWED: 'مسموحة ',
        BLOCKED: 'ممنوعة ',
        TRACKED: 'مراقبة ',
    },
    remove_word: 'حذف كلمة',
    change_room_image: 'تغيير صورة الغرفة',
    create_shortcut: 'انشاء اختصار',
    remove_shortcut: 'حذف اختصار',
    adding_messages: 'اضافة رسائل ',
    removing_messages: 'حذف رسائل ',
    adding_removing_messages: {
        welcome: 'ترحيبية',
        daily: 'يومية'
    },
    site_admin_updated: 'تعديل في ادارة الموقع',
    icons_types: {
        SUPER_ICON: 'سوابر',
        GIFT_ICON: 'هدايا',
        SMILE_ICON: 'ابتسامات'
    },
    adding: 'اضافة ',
    removing: 'حذف ',
    logo: 'شعار الموقع',
    banner: 'بانر الموقع',
    default_group: 'المجموعه الافتراضية',
    this_user_already_connected: 'هذا المستخدم متصل مسبقا!!',
    loadMore: 'المزيد',
    numberUsersFromSameIp: 'عدد الدخول من نفس ال IP',
    usernameMaxLength: 'عدد احرف دخول المستخدم',
    generalRoomMaxLines: 'عدد الاسطر بالعام',
    kickPeriod: 'مدة الطرد من الغرفه',
    giftMaxNumbers: 'عدد الهدايا المسموح رفعها',
    smileMaxNumbers: 'عدد الابتسامات المسموح رفعها',
    no_members: 'لا يوجد اعضاء',
    do_search: 'ابحث',
    are_you_sure_you_want_to_delete_a_member: 'هل انت متاكد من حذف العضو؟',
    unknown: 'Unknown',
    welcome_bar: 'شريط الترحيب',
    defaultUserImage: 'صورة العضو الافتراضية',
    defaultRoomImage: 'صورة الغرفة الافتراضية',
    wall_banner: 'بانر الحائط',
    someone_liked_your_wall_message: 'قام بالاعجاب بمنشورك',
    like: 'اعجاب',
    the_room: 'الغرفة',
    choose_the_room: 'اختر الغرفة',
    transfer: 'نقل',
    user_transfer_room: 'تم نقلك الى غرفة ',
    hide_star: "اخفاء النجمة",
    change_guest_room: "تغير الغرفة للزائر",
    delete_public_messages: "حذف الرسائل بالعام",
    skip_closed_rooms: "تخطي الغرف المغلقة",
    passwordConfirm: "تاكيد كلمة المرور",
    passwordAndPasswordConfirmAreNotEqual: "كلمة المرور غير متطايقة",
    error_occurred: "حدث خظا, الرجاء اعادة المحاولة لاحقاّ"
};

export default Labels;
