import React, {Component} from 'react';
import axios from 'axios';
import Modal from "react-modal";
import Labels from '../../labels';
import {FaTimes} from "react-icons/fa";
import Spinner from '../../components/functional/Spinner';
import {getMembers, deleteMember, updateUserLikes, searchUsers} from '../../apis/admin/members';
import {getPermissionsGroups, addGroupToUser, updateGroupDays} from "../../apis/admin/permissions";
import {displayUsers, filterPermissionsGroups, getOffsetValue, updateAdminUserGroups} from "../../functions";
import {changePassword} from "../../apis/auth";
import _ from 'lodash';
import Events from "../../events";
import Constants from "../../constants";
import {addStatus} from "../../apis/admin/statuses";
import '../../assets/sass/Members.scss';

class Members extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            users: [],
            searchedUsers: [],
            modelUser: null,
            loading: false,
            permissionsGroups: [],
            modalLoading: false,
            userLikes: 0,
            groupDays: '0',
            selectedGroup: null,
            password: '',
            passwordChanged: false,
            originalGroups: null,
            limit: 20,
            offset: 0,
            showLoadMore: true,
            searchTerm: '',
            isSearch: false
        }
    }

    componentDidMount() {
        const {limit, offset} = this.state;

        this.setState({
            loading: true
        });

        axios.all([
            getMembers(limit, offset),
            getPermissionsGroups()
        ]).then(axios.spread((membersResponse, permissionsGroupsResponse) => {
            const membersData = membersResponse.data || {};
            const permissionsGroupsData = permissionsGroupsResponse.data || {};
            const users = membersData.results || [];
            const next = membersData.next;
            const offset = getOffsetValue(next);

            this.setState({
                users,
                loading: false,
                originalGroups: permissionsGroupsData.results || [],
                offset: offset ? offset : this.state.offset,
                showLoadMore: !!next
            });
        }), (errors) => {
            console.error(errors);

            this.setState({
                loading: false
            });
        });
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    handlePermissionsGroupChange = (e) => {
        const value = e.target.value;
        const {permissionsGroups} = this.state;
        const selectedGroup = _.find(permissionsGroups, (group) => {
            return group.id === parseInt(value);
        });

        this.setState({
            selectedGroup
        });
    };

    updateUserGroupsFunc = (modalUser, group) => {
        let {users} = this.state;

        this.setState({
            users: updateAdminUserGroups(modalUser.id, users, [group])
        });
    };

    searchForUsers = (e) => {
        e.preventDefault();

        const {searchTerm, isSearch} = this.state;

        if (searchTerm.trim()) {
            this.setState({
                loading: true
            });

            searchUsers(searchTerm)
                .then((response) => {
                    const data = response.data;
                    const results = data.results;

                    this.setState({
                        loading: false,
                        searchedUsers: results,
                        isSearch: true
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                })
        } else {
            if (isSearch) {
                this.closeSearch();
            }
        }
    };

    openModal = (user) => {
        const loggedInUser = this.props.user;
        const {originalGroups} = this.state;
        const permissionsGroups = filterPermissionsGroups(originalGroups, loggedInUser);

        this.setState({
            modalIsOpen: true,
            modelUser: user,
            userLikes: user.likes_count,
            permissionsGroups,
            selectedGroup: user['user']['groups'][0]
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            modalUser: null
        });
    };

    deleteMemberFunc = (modelUser) => {
        const confirm = window.confirm(Labels.are_you_sure_you_want_to_delete_a_member);

        if (!confirm) {
            return;
        }

        this.setState({
            loading: true
        });

        const {user} = this.props;
        const toChatUser = modelUser['user'].chatuser || {};
        const chatUser = user.chatuser || {};

        deleteMember(modelUser.id)
            .then(() => {
                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.remove_user,
                    room: null
                }).then(() => {
                    const {users} = this.state;
                    const {socket} = this.props;

                    const newUsers = users.filter((user) => {
                        return user.id !== modelUser.id;
                    });

                    this.closeModal();

                    this.setState({
                        loading: false,
                        users: newUsers
                    });

                    socket.emit(Events.REMOVE_USER, modelUser['user'].username);
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    updateUserLikesFunc = (toUser) => {
        this.setState({
            modalLoading: true
        });

        const {userLikes} = this.state;
        const {user} = this.props;
        const chatUser = user.chatuser || {};
        const toChatUser = toUser['user'].chatuser || {};

        updateUserLikes(toUser.id, userLikes)
            .then(() => {
                addStatus({
                    chatUserId: chatUser.id,
                    toChatUserId: toChatUser.id,
                    action: Labels.change_likes,
                    room: null
                }).then(() => {
                    this.setState({
                        modalLoading: false
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        modalLoading: false
                    });
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    modalLoading: false
                });
            })
    };

    updateGroupDaysFunc = () => {
        const {modelUser, selectedGroup, groupDays} = this.state;
        const {user} = this.props;
        const toChatUser = modelUser['user'].chatuser || {};
        const chatUser = user.chatuser || {};

        if (selectedGroup.name === Constants.defaultGroup || (!groupDays && groupDays !== '0')) {
            alert(Labels.check_selected_group_or_group_days_value);

            return;
        }

        this.setState({
            modalLoading: true
        });

        addGroupToUser(modelUser.id, selectedGroup.id)
            .then(() => {
                const {socket} = this.props;

                socket.emit(
                    Events.UPDATE_THE_USER,
                    modelUser['user']['username'],
                    Constants.tasks.update_user_group,
                    selectedGroup
                );

                this.updateUserGroupsFunc(modelUser, selectedGroup);

                updateGroupDays(modelUser.id, selectedGroup.id, groupDays)
                    .then(() => {
                        addStatus({
                            chatUserId: chatUser.id,
                            toChatUserId: toChatUser.id,
                            action: Labels.change_permission_member_to_member,
                            room: null
                        }).then(() => {
                            this.setState({
                                modalLoading: false
                            });
                        }, (error) => {
                            console.error(error);

                            this.setState({
                                modalLoading: false
                            });
                        });
                    }, (error) => {
                        this.setState({
                            modalLoading: false
                        });

                        console.error(error);
                    });
            }, (error) => {
                console.error(error);

                this.setState({
                    modalLoading: false
                });
            });
    };

    changePasswordFunc = () => {
        const {password, modelUser} = this.state;

        if (password.trim()) {
            this.setState({
                modalLoading: true
            });

            const {user} = this.props;
            const toChatUser = modelUser['user'].chatuser || {};
            const chatUser = user.chatuser || {};

            changePassword(modelUser.id, password)
                .then(() => {
                    addStatus({
                        chatUserId: chatUser.id,
                        toChatUserId: toChatUser.id,
                        action: Labels.change_password,
                        room: null
                    }).then(() => {
                        this.setState({
                            modalLoading: false,
                            password: '',
                            passwordChanged: true
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    passwordChanged: false
                                });
                            }, 5000);
                        });
                    }, (error) => {
                        console.error(error);

                        this.setState({
                            modalLoading: false
                        });
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        modalLoading: false
                    });
                })
        }
    };

    loadMore = () => {
        const {limit, offset} = this.state;

        this.setState({
            loading: true
        });

        getMembers(limit, offset)
            .then((response) => {
                const responseData = response.data || {};
                const users = responseData.results;
                const next = responseData.next;
                const offset = getOffsetValue(next);

                this.setState({
                    loading: false,
                    users: this.state.users.concat(users),
                    offset: offset ? offset : this.state.offset,
                    showLoadMore: !!next
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    closeSearch = () => {
        this.setState({
            isSearch: false,
            searchTerm: ''
        })
    };

    render() {
        let {
            users,
            modelUser,
            loading,
            permissionsGroups,
            modalLoading,
            userLikes,
            groupDays,
            selectedGroup,
            password,
            passwordChanged,
            showLoadMore,
            searchedUsers,
            isSearch,
            searchTerm
        } = this.state;

        const loggedInUser = this.props.user;

        modelUser = modelUser || {};

        const modelUserInfo = modelUser.user || {};
        const modelUserPermissionsGroups = modelUserInfo.groups || [];
        const modelUserPermissionsGroup = modelUserPermissionsGroups[0] || {};

        return (
            <div className="members">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="search-box">
                    <form onSubmit={this.searchForUsers} className="flex-row">
                        <input type="text"
                               className="form-control"
                               placeholder={Labels.search_members}
                               name="searchTerm"
                               value={searchTerm}
                               onChange={this.handleChange}
                        />
                        {
                            isSearch && (
                                <button type="button" className="btn btn-sm btn-danger" onClick={this.closeSearch}>
                                    X
                                </button>
                            )
                        }
                        <button type="submit" className="btn btn-trikwaz">{Labels.do_search}</button>
                    </form>
                </div>
                <div className="members-content">
                    <table>
                        <thead>
                        <tr>
                            <th>{Labels.username}</th>
                            <th>{Labels.status}</th>
                            <th>{Labels.nick_name}</th>
                            <th width="130">{Labels.ip}</th>
                            <th className="width-400">{Labels.device}</th>
                            <th>{Labels.permissions}</th>
                            <th className="width-200">{Labels.lastLoginDate}</th>
                            <th className="width-200">{Labels.registerDate}</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isSearch && searchedUsers.length === 0 && (
                                <tr>
                                    <td colSpan="9">
                                        <div className="alert alert-danger">{Labels.no_members}</div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            isSearch && searchedUsers.length > 0 && (
                                displayUsers(searchedUsers, loggedInUser, (user) => {
                                    this.openModal(user)
                                })
                            )
                        }
                        {
                            !isSearch && users.length > 0 && (
                                displayUsers(users, loggedInUser, (user) => {
                                    this.openModal(user)
                                })
                            )
                        }
                        </tbody>
                    </table>
                    {
                        !loading && showLoadMore && !isSearch && (
                            <div className="text-center load-more">
                                <button type="button" className="btn btn-trikwaz" onClick={this.loadMore}>
                                    {Labels.loadMore}
                                </button>
                            </div>
                        )
                    }
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    overlayClassName={'modal-overlay'}
                    className="modal-content admin-members-modal"
                >
                    {
                        modalLoading && (
                            <Spinner/>
                        )
                    }
                    <div className="head">
                        <button
                            className="btn btn-sm btn-danger"
                            onClick={this.closeModal}
                        >
                            <FaTimes/>
                        </button>
                        <span className="member-name">{modelUserInfo.username}</span>
                    </div>
                    <div className="body">
                        {
                            passwordChanged && (
                                <div className="alert alert-success">{Labels.password_changed}</div>
                            )
                        }
                        <div className="form-group">
                            <label htmlFor="permissionsGroups">
                                {Labels.permission}
                            </label>
                            <select
                                name="permissionsGroups"
                                id="permissionsGroups"
                                className="form-control"
                                onChange={this.handlePermissionsGroupChange}
                                defaultValue={modelUserPermissionsGroup.id || ''}
                            >
                                <option value="">{Labels.select_permissions_group}</option>
                                {
                                    permissionsGroups.map((permissionsGroup) => {
                                        const permissionsGroupInfo = permissionsGroup.group_info;

                                        return (
                                            <option
                                                key={permissionsGroup.id}
                                                value={permissionsGroup.id}
                                            >
                                                {permissionsGroup.name} - {permissionsGroupInfo.order}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="groupDays">
                                {Labels.period}
                            </label>
                            <input type="number"
                                   className="form-control"
                                   name="groupDays"
                                   placeholder={Labels.group_days}
                                   value={groupDays}
                                   disabled={!selectedGroup}
                                   min={0}
                                   id="groupDays"
                                   onChange={this.handleChange}
                            />
                            <button className="btn btn-sm btn-success save-btn"
                                    onClick={this.updateGroupDaysFunc}
                            >
                                {Labels.save}
                            </button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="userLikes">
                                {Labels.likes}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                name="userLikes"
                                placeholder={Labels.likes}
                                id="userLikes"
                                value={userLikes}
                                onChange={this.handleChange}
                            />
                            <button className="btn btn-sm btn-success save-btn" onClick={() => {
                                this.updateUserLikesFunc(modelUser)
                            }}>
                                {Labels.save}
                            </button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{Labels.change_password}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                value={password}
                                placeholder={Labels.new_password}
                                onChange={this.handleChange}
                            />
                            <button className="btn btn-sm btn-success save-btn" onClick={this.changePasswordFunc}>
                                {Labels.save}
                            </button>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    this.deleteMemberFunc(modelUser)
                                }}
                            >
                                {Labels.remove_member_1}
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Members;
