import React, {Component} from 'react';
import Labels from '../../labels';
import {getRecords, searchRecords} from "../../apis/admin/record";
import Spinner from "../functional/Spinner";
import {displayRecords, getOffsetValue} from "../../functions";
import '../../assets/sass/Record.scss';

class Record extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            searchedRecords: [],
            loading: false,
            limit: 20,
            offset: 0,
            showLoadMore: true,
            searchTerm: '',
            isSearch: false
        }
    }

    searchForRecords = (e) => {
        e.preventDefault();

        const {searchTerm, isSearch} = this.state;

        if (searchTerm.trim()) {
            this.setState({
                loading: true
            });

            searchRecords(searchTerm)
                .then((response) => {
                    const data = response.data;
                    const results = data.results;

                    this.setState({
                        loading: false,
                        searchedRecords: results,
                        isSearch: true
                    });
                }, (error) => {
                    console.error(error);

                    this.setState({
                        loading: false
                    });
                })
        } else {
            if (isSearch) {
                this.closeSearch();
            }
        }
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
        this.loadMore();
    }

    loadMore = () => {
        const {limit, offset} = this.state;

        this.setState({
            loading: true
        });

        getRecords(limit, offset)
            .then((response) => {
                const responseData = response.data || {};
                const records = responseData.results;
                const next = responseData.next;
                const offset = getOffsetValue(next);

                this.setState({
                    loading: false,
                    records: this.state.records.concat(records),
                    offset: offset ? offset : this.state.offset,
                    showLoadMore: !!next
                });
            }, (error) => {
                console.error(error);

                this.setState({
                    loading: false
                });
            });
    };

    closeSearch = () => {
        this.setState({
            isSearch: false,
            searchTerm: ''
        })
    };

    render() {
        const {
            loading,
            records,
            showLoadMore,
            searchedRecords,
            isSearch,
            searchTerm
        } = this.state;

        return (
            <div className="record">
                {
                    loading && (
                        <Spinner/>
                    )
                }
                <div className="search-box">
                    <form onSubmit={this.searchForRecords} className="flex-row">
                        <input type="text"
                               className="form-control"
                               placeholder={Labels.search_record}
                               name="searchTerm"
                               value={searchTerm}
                               onChange={this.handleChange}
                        />
                        {
                            isSearch && (
                                <button type="button" className="btn btn-sm btn-danger" onClick={this.closeSearch}>
                                    X
                                </button>
                            )
                        }
                        <button type="submit" className="btn btn-trikwaz">{Labels.do_search}</button>
                    </form>
                </div>
                <div className="record-content">
                    <table>
                        <thead>
                        <tr>
                            <th>{Labels.status}</th>
                            <th>{Labels.member}</th>
                            <th>{Labels.nick_name}</th>
                            <th>{Labels.ip}</th>
                            <th>{Labels.country}</th>
                            <th>{Labels.device}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isSearch && searchedRecords.length === 0 && (
                                <tr>
                                    <td colSpan="6">
                                        <div className="alert alert-danger">{Labels.no_members}</div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            isSearch && searchedRecords.length > 0 && (
                                displayRecords(searchedRecords)
                            )
                        }
                        {
                            !isSearch && records.length > 0 && (
                                displayRecords(records)
                            )
                        }
                        </tbody>
                    </table>
                    {
                        !loading && showLoadMore && !isSearch && (
                            <div className="text-center load-more">
                                <button type="button" className="btn btn-trikwaz" onClick={this.loadMore}>
                                    {Labels.loadMore}
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Record;
