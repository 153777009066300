import {axiosInstance, axiosIpInstance} from '../axios';
import {getUserPermissionsGroup} from "../functions";
import axios from 'axios';
import Config from "../config";
import Constants from "../constants";

const {fileType} = require('../server/functions');

export const getRooms = () => {
    return axiosInstance
        .get('/rooms/available')
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        })
};

export const createRoom = (data) => {
    let payload = {
        seats: data.roomSize,
        nick_name: data.roomName,
        welcome_message: data.roomWelcomeMessage,
        description: data.roomDescription
    };

    if (data.roomPassword) {
        payload.room_type = Constants.room_types.private_room;
        payload.password = data.roomPassword;
    } else {
        payload.room_type = Constants.room_types.public_room;
    }

    if (data.roomActivation) {
        payload.type = Constants.room_types.system;
    }

    return axiosInstance
        .post('/rooms', payload)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        })
};

export const updateRoom = (roomId, roomName, roomDescription, roomWelcomeMessage, roomPassword, roomSize) => {
    const data = {
        seats: roomSize,
        nick_name: roomName,
        welcome_message: roomWelcomeMessage,
        description: roomDescription
    };

    if (roomPassword) {
        data.room_type = Constants.room_types.private_room;
        data.password = roomPassword;
    } else {
        data.room_type = Constants.room_types.public_room;
    }

    return axiosInstance
        .put('/rooms/' + roomId, data)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        })
};

export const deleteRoom = (roomId) => {
    return axiosInstance
        .delete('admin/rooms/' + roomId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const checkRoomPassword = (roomId, password) => {
    const data = {
        password
    };

    return axiosInstance
        .post('/rooms/' + roomId + '/check_room', data)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        })
};

export const setChatSettings = (userId, data, to) => {
    let url = '/chat_users/' + userId + '/settings';

    if (to) {
        url = 'admin/users/' + userId + '/settings';
    }

    return axiosInstance
        .put(url, data)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error);
        })
};

export const getFilterWords = () => {
    return axiosInstance
        .get('words')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getShortcuts = () => {
    return axiosInstance
        .get('/shortcuts?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getMessages = () => {
    return axiosInstance
        .get('/public_messages?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getSiteSettings = () => {
    return axiosInstance
        .get('site/settings')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const like = (chatUserId, toChatUserId) => {
    return axiosInstance
        .post('chat_users/' + chatUserId + '/to_users/' + toChatUserId + '/like', [])
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};

export const getLoggedInUserLikes = (id) => {
    return axiosInstance
        .get('chat_users/' + id + '/to_users/likes?limit=1000')
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};

export const getLikes = (id, toUserId) => {
    return axiosInstance
        .get('chat_users/' + id + '/to_users/' + toUserId + '/all_likes?limit=1000')
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};

export const blockIp = (ip) => {
    return axiosInstance
        .post('chat_users/ip_addresses/block', {ip_address: ip})
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getPermissions = () => {
    return axiosInstance
        .get('permissions?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const decreasePermissionCount = (permissionId, userId) => {
    const userPermissionsGroup = getUserPermissionsGroup(userId);

    if (!userPermissionsGroup) {
        return Promise.reject("Invalid user permissions group id.");
    }

    return axiosInstance
        .put('user/groups/' + userPermissionsGroup.id + '/permissions/' + permissionId + '/decrease')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const uploadFile = (data, onUploadProgress) => {
    const uploadFile = data.get('file');
    const file = uploadFile.name;
    const arr = file.split('.');
    const ext = arr.pop();
    const uploadFileType = fileType(ext);

    if (!uploadFileType) {
        return Promise.reject('Invalid!');
    }

    let config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    };

    if (onUploadProgress) {
        config['onUploadProgress'] = onUploadProgress;
    }

    return axios
        .post(Config[process.env.NODE_ENV].upload_url, data, config)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

export const getIpCountry = () => {
    return axiosIpInstance
        .get('getRealIp')
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
};