import React, {Component} from 'react';
import {FaTimes} from "react-icons/fa";
import Labels from '../../labels';
import Config from "../../config";
import Constants from "../../constants";
import Events from '../../events';
import '../../assets/sass/PrivateConversations.scss';

class PrivateConversations extends Component {

    handleClick = (conversation, task) => {
        const {setActiveConversation, closeSidebar} = this.props;

        closeSidebar();

        if (task === Constants.remove) {
            const {socket} = this.props;

            socket.emit(Events.REMOVE_PRIVATE_CONVERSATION, conversation);
        } else {
            setActiveConversation(conversation);
        }
    };

    render() {
        const {privateConversations, user} = this.props;

        return (
            <div className="private-conversations">
                {
                    privateConversations.length > 0 && (
                        privateConversations.map(conversation => {
                            if (conversation.messages && !conversation.messages.length) {
                                return;
                            }

                            const chatUser = conversation.createdBy.chatuser || {};
                            const chatUserSettings = chatUser.chat_user_settings || {};

                            let imageUrl = window.defaultUserImage;

                            if (chatUserSettings.picture_name) {
                                imageUrl = Config.image_path + chatUserSettings.picture_name;
                            }

                            return (
                                <div
                                    key={conversation.id}
                                    className={`private-conversation-wrapper ${!conversation.read && conversation.createdBy.username !== user.username ? 'unread' : ''}`}
                                >
                                    <div
                                        className={`private-conversation`}
                                        onClick={() => this.handleClick(conversation)}
                                    >
                                        <div className="image-content-wrapper">
                                            {
                                                <img src={imageUrl}
                                                     className="img-responsive"
                                                     alt={conversation.name}
                                                />
                                            }
                                        </div>
                                        <div className="conversation-name">
                                            {conversation.name}
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={() => this.handleClick(conversation, Constants.remove)}
                                    >
                                        <span>
                                            {Labels.remove}
                                        </span>
                                        <FaTimes/>
                                    </button>
                                </div>
                            )
                        })
                    )
                }
            </div>
        );
    }
}

export default PrivateConversations;
