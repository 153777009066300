import React, {Component} from 'react';
import {createMarkup} from "../functions";
import '../assets/sass/Notification.scss';
import Labels from "../labels";

class Notification extends Component {
    render() {
        const {notificationMessages, buttonsColor, contentColor} = this.props;

        return (
            <div className="notifications-container">
                {
                    notificationMessages.map((notificationMessage, index) => {
                        return (
                            <div
                                key={notificationMessage.id}
                                className={`notification notification-${index + 1}`}
                                onClick={() => {
                                    this.props.removeNotificationMessage(notificationMessage.id)
                                }}
                                style={{
                                    background: contentColor
                                }}
                            >
                                <div className="notification-header" style={{
                                    background: buttonsColor
                                }}>
                                    {Labels.notification_title}
                                </div>
                                <div dangerouslySetInnerHTML={createMarkup(notificationMessage.message)}/>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default Notification;
