import React, {Component} from 'react';
import Labels from '../../labels';
import {checkRoomPassword} from '../../apis/chat';

class JoinPrivateRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            error: ''
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const {password} = this.state;
        const {room, changeActiveRoom, closeModal} = this.props;

        this.setError('');

        checkRoomPassword(room.id, password)
            .then(() => {
                closeModal();
                changeActiveRoom(room.id);
            }, (error) => {
                const {data} = error;
                this.setError(Labels[data.detail]);
            });
    };

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        })
    };

    setError = error => {
        this.setState({error});
    };

    render() {
        const {password, error} = this.state;

        let errorAlert = null;

        if (error) {
            errorAlert = <div className="alert alert-danger">{error}</div>
        }

        return (
            <form onSubmit={this.handleSubmit}>
                {errorAlert}
                <div className="form-group">
                    <label htmlFor="password">
                        {Labels.password}
                    </label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <button
                        type="submit"
                        className="btn btn-dark"
                    >
                        {Labels.entrance}
                    </button>
                </div>
            </form>
        );
    }
}

export default JoinPrivateRoom;
