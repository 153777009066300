import {axiosInstance} from '../../axios';

export const getMessages = () => {
    return axiosInstance
        .get('admin/public_messages?limit=1000')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addMessage = (title, category, message) => {
    const data = {
        title,
        category,
        message
    };

    return axiosInstance
        .post('admin/public_messages', data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteMessage = (id) => {
    return axiosInstance
        .delete('admin/public_messages/' + id)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
