import {axiosInstance} from '../../axios';

export const getRooms = () => {
    return axiosInstance
        .get('admin/rooms')
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const updateRoom = (roomId, roomImage) => {
    const payload = {
        image: roomImage
    };

    return axiosInstance
        .put('admin/rooms/' + roomId, payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const deleteRoom = (roomId) => {
    return axiosInstance
        .delete('admin/rooms/' + roomId)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
