import React, {Component} from 'react';
import {FaImage, FaPlus} from "react-icons/all";
import Labels from "../labels";
import {uploadFile} from "../apis/chat";
import IconsListing from "./IconsListing";
import '../assets/sass/IconsListingWithUpload.scss';

/**
 * This component for display, upload and remove icons with/without type.
 */
class IconsListingWithUpload extends Component {
    handleChange = (e) => {
        const {onLoadIcon, uploadFolder} = this.props;

        if (onLoadIcon) {
            onLoadIcon();
        }

        const data = new FormData();
        const file = e.target.files[0];

        data.append('file', file, file.name);

        if (uploadFolder) {
            data.append('uploadFolder', uploadFolder);
        }

        uploadFile(data)
            .then((response) => {
                const {handleLoadIconSuccess, type} = this.props;

                if (handleLoadIconSuccess) {
                    handleLoadIconSuccess(response, type);
                }
            }, (error) => {
                const {handleLoadIconFailed} = this.props;

                if (handleLoadIconFailed) {
                    handleLoadIconFailed(error);
                }
            });
    };

    render() {
        const {
            icons,
            type,
            name,
            onRemoveIcon,
            handleRemoveIconSuccess,
            handleRemoveIconFailed,
            typeIndex,
            uploadFolder,
            user
        } = this.props;

        return (
            <div className="icons-listing-with-upload">
                <label htmlFor={name} className="name">
                    <FaImage/>
                    {Labels[name]}
                </label>
                <label htmlFor={name}>
                    <FaPlus/>
                    <input type="file"
                           id={name}
                           className="form-control"
                           name={name}
                           onChange={(e) => {
                               this.handleChange(e)
                           }}
                    />
                </label>
                <IconsListing icons={icons}
                              type={type}
                              typeIndex={typeIndex}
                              onRemoveIcon={onRemoveIcon}
                              handleRemoveIconSuccess={handleRemoveIconSuccess}
                              handleRemoveIconFailed={handleRemoveIconFailed}
                              uploadFolder={uploadFolder}
                              user={user}
                />
            </div>
        );
    }
}

export default IconsListingWithUpload;