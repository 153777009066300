const Constants = {
    message_types: {
        daily: 'DAILY_MESSAGE',
        adv: 'ADV_MESSAGE',
        logout: 'LOGOUT_MESSAGE',
        leave: 'LEAVE_MESSAGE',
        private_messages: 'PRIVATE_MESSAGES',
        entrance: 'ENTRANCE',
        welcome: 'WELCOME',
        user_leave_room: 'USER_LEAVE_ROOM',
        private_message: 'PRIVATE_MESSAGE'
    },
    message_categories: {
        welcome: 'welcome',
        daily: 'daily'
    },
    permissions: {
        delete_wall: 'delete_wall',
        change_nickname: 'change_nickname',
        manage_nicknames: 'manage_nicknames',
        open_nicknames: 'open_nicknames',
        bands: 'bands',
        advertisement: 'advertisement',
        block_user: 'block_user',
        gifts: 'gifts',
        manage_rooms: 'manage_rooms',
        notification: 'notification',
        manage_permission: 'manage_permission',
        control_panel: 'control_panel',
        create_room: 'create_room',
        max_room_limit: 'max_room_limit',
        open_private: 'open_private',
        hide: 'hide',
        change_guest_room: 'change_guest_room',
        hide_star: 'hide_star',
        skip_closed_rooms: 'skip_closed_rooms',
        delete_public_messages: 'delete_public_messages',
        change_likes: 'change_likes',
        grant_permission: 'grant_permission',
    },
    media: {
        image: 'MEDIA_IMAGE',
        video: 'MEDIA_VIDEO',
        record: 'MEDIA_RECORD',
        youtube: 'YOUTUBE_LINK'
    },
    room_types: {
        private_room: 'PRIVATE_ROOM',
        public_room: 'PUBLIC_ROOM',
        system: 'SYSTEM'
    },
    shortcut_types: {
        super_icon: 'SUPER_ICON',
        gift_icon: 'GIFT_ICON',
        smile_icon: 'SMILE_ICON',
        string: 'STRING'
    },
    user_activity: {
        active: 'ACTIVE',
        active_disable_private_chat: 'ACTIVE_DISABLE_PRIVATE_CHAT',
        inactive: 'INACTIVE',
        inactive_and_band: 'INACTIVE_AND_BAND',
        hidden_login: 'HIDDEN_LOGIN'
    },
    login_types: {
        register: 'REGISTER',
        members_login: 'MEMBERS_LOGIN',
        guests_login: 'GUESTS_LOGIN',
    },
    attachments_ids: {
        wall: 'wallAttachmentId',
        private_conversation: 'privateConversationId'
    },
    sidebar_tasks: {
        connected: 'connected',
        private_conversations: 'private_conversations',
        rooms: 'rooms',
        settings: 'settings',
        wall: 'wall'
    },
    //names of tasks with camel case since they would be added to the user object
    tasks: {
        add_user: 'ADD_USER',
        remove_user: 'REMOVE_USER',
        edit: 'EDIT',
        create_room: 'CREATE_ROOM',
        update_chat_user_settings: 'UPDATE_CHAT_USER_SETTINGS',
        set_user_country_ip: 'SET_USER_COUNTRY_IP',
        gift: 'gift',
        update_user_group: 'UPDATE_USER_GROUP',
        hiddenLogin: 'hiddenLogin',
        check_user_activity: 'CHECK_USER_ACTIVITY',
        create: 'CREATE',
        update: 'UPDATE',
        join_room: 'JOIN_ROOM',
        hideStar: "hideStar",
        change_password: "change_password",
        room_administration: "room_administration",
    },
    record: 'record',
    statuses: 'statuses',
    members: 'members',
    block: 'block',
    permissions_1: 'permissions',
    filter: 'filter',
    admin_rooms: 'adminRooms',
    shortcuts: 'shortcuts',
    subscriptions: 'subscriptions',
    admin_messages: 'adminMessages',
    site_admin: 'siteAdmin',
    add: 'ADD',
    gift: 'gift',
    member: 'MEMBER',
    guest: 'GUST',
    invalidToken: 'Invalid token.',
    edit: 'EDIT',
    like: 'LIKE',
    expulsion_from_room: 'EXPULSION_FROM_ROOM',
    warning: 'WARNING',
    private_conversation: 'PRIVATE_CONVERSATION',
    remove_image: 'REMOVE_IMAGE',
    ignore: 'IGNORE',
    allowed: 'ALLOWED',
    blocked: 'BLOCKED',
    tracked: 'TRACKED',
    icon: 'ICON',
    admin: 'ADMIN',
    shortcut_replacement: 'f1',
    dev: 'development',
    prod: 'production',
    defaultGroup: 'default_group',
    gift_notification: 'GIFT_NOTIFICATION',
    like_notification: 'LIKE_NOTIFICATION',
    remove: 'REMOVE',
    wall_private_media_folder: 'private_wall/',
    avatars: 'avatars/',
    super_images: 'super_images/',
    gifts_images: 'gifts_images/',
    smiles_images: 'smiles_images/',
    rooms_images: 'rooms_images/',
    register_login_from_out: 'تسجيل او دخول من خارج الدردشة',
    public: 'public'
};

export default Constants;
