import Constants from "./constants";
import Labels from "./labels";
import {logoutAPI} from "./apis/auth";
import {setAuthorizationHeaders} from "./axios";
import {setChatSettings} from "./apis/chat";
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import {IoIosSettings} from "react-icons/io";
import React from "react";
import Fingerprint2 from "fingerprintjs2";
import moment from "moment";

export const convertDecimalToHex = (number) => {
    if (number || number === 0) {
        let hex = number.toString(16);

        if (hex.length < 6) {
            let color = '';

            for (let i = 0; i < 6 - hex.length; i++) {
                color += '0';
            }

            return '#' + color + hex;
        }

        return '#' + hex;
    }
};

export const getUrlParam = (param) => {
    const results = new RegExp('[\?&]' + param + '=([^&#]*)').exec(window.location.href);

    if (!results) {
        return null;
    } else {
        return decodeURI(results[1]) || 0;
    }
};

export const userPermissions = (permissionType, userId, permissions) => {
    let userPermissions = permissions;

    if (!userPermissions) {
        let jsonString = localStorage.getItem('userPermissions_' + userId) || '[]';

        userPermissions = JSON.parse(jsonString);
    }

    return !!_.find(userPermissions, (userPermission) => {
        return userPermission.codename === permissionType;
    });
};

export const prepareShortcuts = (shortcuts, type) => {
    switch (type) {
        case Constants.shortcut_types.string:
            const stringShortcuts = shortcuts.filter((shortcut) => {
                if (shortcut.shortcut_type === Constants.shortcut_types.string) {
                    return shortcut;
                }
            });

            return {
                shortcuts: stringShortcuts
            };
        case Constants.shortcut_types.smile_icon:
            let counter = 0;
            let mapping = {};

            const smileIcons = shortcuts.filter((shortcut) => {
                if (shortcut.shortcut_type === Constants.shortcut_types.smile_icon) {
                    let newShortCut = shortcut;

                    counter++;

                    const replacement = Labels.F + counter;

                    newShortCut.replacement = replacement;

                    mapping[replacement] = shortcut.word;

                    return newShortCut;
                }
            });

            return {
                shortcuts: smileIcons,
                mapping
            };

        case Constants.shortcut_types.gift_icon:
            const gifts = shortcuts.filter((shortcut) => {
                if (shortcut.shortcut_type === Constants.shortcut_types.gift_icon) {
                    return shortcut;
                }
            });

            return {
                shortcuts: gifts
            };
        default:
    }
};

export const getPermissionIdByCode = (code, userId) => {
    const permissions = JSON.parse(localStorage.getItem('permissions_' + userId));

    let permissionId = null;

    for (let i in permissions) {
        if (!permissions.hasOwnProperty(i)) {
            continue;
        }

        const permission = permissions[i];

        if (permission['codename'] === code) {
            permissionId = permission['id'];

            break;
        }
    }

    return permissionId;
};

export const getUserPermissionsGroup = (userId) => {
    const user = JSON.parse(localStorage.getItem('user_' + userId));
    const groups = user.groups || [];

    return groups[0] || {};
};

export const filterPermissionsGroups = (groups, user) => {
    const userGroups = user.groups;
    const userGroup = userGroups[0] || {};
    const userGroupInfo = userGroup.group_info || {};

    return groups.filter((group) => {
        return group.name !== Constants.defaultGroup && userGroupInfo.order >= group.group_info.order
    });
};

export const getPathFromUrl = (url) => {
    return url.split(/[?#]/)[0];
};

export const handleError = (error) => {
    if (error) {
        const errorResponse = error.response || {};
        const errorData = errorResponse.data || {};

        if (errorData.detail && errorData.detail === Constants.invalidToken) {
            window.location.href = getPathFromUrl(window.location.href);
        }

        return true;
    }

    const idParam = getUrlParam('id');

    if (idParam && !localStorage.getItem('token_' + idParam)) {
        window.location.href = getPathFromUrl(window.location.href);

        return true;
    }

    return false;
};

export const clearLocalStorageById = (id) => {
    const userId = id || localStorage.getItem('last');
    const TabUserId = sessionStorage.getItem('userId');

    if (userId && userId === TabUserId) {
        if (!id) {
            const token = localStorage.getItem('token_' + userId);

            if (token) {
                setAuthorizationHeaders(token);

                const lastChatUser = localStorage.getItem('last_chat_user');

                if (lastChatUser) {
                    const data = {
                        enable_private_chat: true,
                        enable_notification: true
                    };

                    (async () => {
                        await setChatSettings(lastChatUser, data)
                            .then(() => {
                            }, (error) => {
                                console.error(error)
                            });

                        logoutAPI();
                    })();
                } else {
                    logoutAPI();
                }
            }
        }

        localStorage.removeItem('token_' + userId);
        localStorage.removeItem('user_' + userId);
        localStorage.removeItem('permissions_' + userId);
        localStorage.removeItem('userPermissions_' + userId);
        localStorage.removeItem('_country');
        localStorage.removeItem('_ip');
        localStorage.removeItem('last');
        localStorage.removeItem('last_chat_user');
    }

    localStorage.removeItem('usersWallMessagesLiked');
};

export const updateAdminUserGroups = (userId, users, groups) => {
    for (const [i, user] of users.entries()) {
        if (user.id === userId) {
            users[i]['user']['groups'] = groups;
        }
    }

    return users;
};

export const getMessageTime = (message) => {
    const now = Date.now();
    const diff = new Date(now - message.time);
    const diff_hours = diff.getHours();
    const diff_mins = diff.getMinutes();

    return {
        diff_hours,
        diff_mins
    };
};

export const getDate = (date1) => {
    const dateTime = new Date(Date.parse(date1));
    const date = dateTime.getDate();
    const month = dateTime.getMonth();
    const year = dateTime.getFullYear();
    const timestamp = dateTime.toLocaleTimeString();

    return date + "-" + (month + 1) + "-" + year + ' ' + timestamp;
};

export const createMarkup = (string, keepSpaces) => {
    string = string || ' ';

    if (keepSpaces) {
        string = string.replace(/\s/g, '&nbsp;');
    }

    return {__html: string};
};

export const setStyleAttribute = (id, attribute, value) => {
    const element = document.getElementById(id);

    if (element) {
        element.style[attribute] = value + 'px';
    }
};

export const isUserHidden = (user) => {
    const userGroups = user.groups || [];
    const userGroup = userGroups[0] || {};
    const userGroupPermissions = userGroup.permissions || [];

    return user.hiddenLogin && userPermissions(Constants.permissions.hide, user.id, userGroupPermissions)
};

export const isHiddenLogin = (user, loggedInUser) => {
    let loggedInUserGroupInfo = {};

    const userGroups = user.groups;
    const userGroup = userGroups[0] || {};
    const userGroupInfo = userGroup.group_info || {};

    if (loggedInUser) {
        const loggedInUserGroups = loggedInUser.groups;
        const loggedInUserGroup = loggedInUserGroups[0] || {};

        loggedInUserGroupInfo = loggedInUserGroup.group_info || {};
    }

    if (!loggedInUser && isUserHidden(user)) {
        return true;
    }

    if (loggedInUser && isUserHidden(user)) {
        if (!loggedInUserGroupInfo.order || (loggedInUserGroupInfo.order < userGroupInfo.order)) {
            return true;
        }
    }

    return false;
};

export const getConnectedUsersCount = (users, loggedInUser) => {
    let count = 0;

    for (let user of users) {
        if (!isHiddenLogin(user, loggedInUser)) {
            count++;
        }
    }

    return count;
};

export const prepareRooms = (rooms) => {
    if (_.isArray(rooms)) {
        for (let room of rooms) {
            room.users = [];
            room.usersExpelledFromTheRoom = {};
        }

        return rooms;
    }

    rooms.users = [];
    rooms.usersExpelledFromTheRoom = {};

    return rooms;
};

export const createMessage = (message, user, room, type, sender, mediaType, image) => {
    const time = Date.now();

    room = room || {};

    return {
        id: uuidv4(),
        time: time,
        roomName: room.nick_name,
        roomImage: room.image,
        roomId: room.id,
        message: message,
        sender: sender || user.nick_name || user.username,
        type: type,
        user: user,
        mediaType: mediaType,
        image: image
    }
};

export const removeUserFromRoom = (userId, room) => {
    for (let [index, user] of room.users.entries()) {
        if (user.id === userId) {
            room.users.splice(index, 1);

            break;
        }
    }
};

export const getOffsetValue = (nextString) => {
    if (nextString) {
        const nextArray = nextString.split('&');
        const offset = nextArray[1];
        const offsetArray = offset.split('=');

        return parseInt(offsetArray[1]);
    }
};

export const displayUsers = (users, loginUser, callback) => {
    const userGroups = loginUser.groups;
    const userGroup = userGroups[0] || {};
    const userGroupInfo = userGroup.group_info || {};

    return users.map((user) => {
        const userLoginInfo = user.userlogininfo || {};
        const lastLoginDate = getDate(userLoginInfo.last_login);
        const registerDate = getDate(user.created);
        const actualUser = user.user;
        const groups = actualUser.groups;
        const group = groups[0] || {};
        const actualUserGroupInfo = group.group_info || {};
        const isGroupInfoDefined = actualUserGroupInfo.order !== undefined;
        const showButtonCondition = !isGroupInfoDefined || (
            isGroupInfoDefined &&
            userGroupInfo.order >= actualUserGroupInfo.order
        );

        return (
            <tr key={user.id}>
                <td>{actualUser.username}</td>
                <td>{user.bio || (user.user_type === Constants.guest ? Labels.new_guest : Labels.new_member)}</td>
                <td>{user.nick_name}</td>
                <td>{userLoginInfo.ip || '--'}</td>
                <td className="width-400">{userLoginInfo.user_agent || '--'}</td>
                <td>{group.name ? group.name === Constants.defaultGroup ? Labels[group.name] : group.name : '--'}</td>
                <td className="width-200">{!lastLoginDate || lastLoginDate.indexOf('NaN') > -1 ? '--' : lastLoginDate}</td>
                <td>{!registerDate || registerDate.indexOf('NaN') > -1 ? '--' : registerDate}</td>
                <td>
                    {
                        showButtonCondition && (
                            <button
                                className="btn btn-sm btn-trikwaz"
                                onClick={() => {
                                    callback(user)
                                }}
                            >
                                <IoIosSettings/>
                            </button>
                        )
                    }
                    {
                        !showButtonCondition && (
                            '--'
                        )
                    }
                </td>
            </tr>
        )
    })
};

export const displayRecords = (records) => {
    return records.map((record) => {
        return (
            <tr key={record.id}>
                <td>{record.status}</td>
                <td>{record.nick_name}</td>
                <td>{record.nick_name}</td>
                <td>{record.ip_address}</td>
                <td>{record.country}</td>
                <td>{record.device}</td>
            </tr>
        )
    })
};

export const displayStatues = (statues) => {
    return statues.map((status) => {
        const admin = status.admin || {};
        const user = status.chat_user || {};
        const room = status.room || {};
        const date = getDate(status.action_datetime);

        return (
            <tr key={status.id}>
                <td>{status.action}</td>
                <td>{admin.nick_name}</td>
                <td>{user.nick_name || '--'}</td>
                <td>{room.nick_name || '--'}</td>
                <td>{date || '--'}</td>
            </tr>
        )
    })
};

export const getFingerPrint = (components) => {
    const userAgent = (components.filter((component) => {
        return component['key'] === 'userAgent'
    }) || [])[0];

    const values = components.map(function (component) {
        return component.value
    });

    return {
        fingerCode: Fingerprint2.x64hash128(values.join(''), 31),
        userAgent: userAgent.value || Labels['unknown']
    };
};

export const mapArabicNumbersToEnglish = (number) => {
    const numbers= {
        "٠": "0",
        "١": "1",
        "٢": "2",
        "٣": "3",
        "٤": "4",
        "٥": "5",
        "٦": "6",
        "٧": "7",
        "٨": "8",
        "٩": "9",
    };

    return numbers[number] || number;
};

export const calculateMinutes = (date) => {
    const duration = moment.duration(moment().diff(date));

    return duration.asMinutes();
}